:global(.ant-modal-content) {
	.modal & {
		padding: 40px 22px;
		overflow: hidden;
		border-radius: 24px;
		transition: 150ms ease;
	}

	.hidden & {
		transform-origin: top center;
		transform: translateY(25vh) scale(0.93);
		visibility: hidden;
		opacity: 0;
	}
}

.fields {
	position: relative;
	margin-bottom: 22px;

	&-footer {
		position: absolute;
		right: 16px;
		bottom: 2px;
		left: 16px;
		z-index: 1;
		display: flex;
		padding-top: 10px;
		padding-bottom: 11px;
		justify-content: space-between;
		border-radius: 0 100px 100px 0;
		background-color: #4d4d4d;
	}

	&-include {
		display: grid;
		grid-template-columns: 80px 80px;
		grid-gap: 6px 10px;

		& > :first-child {
			grid-column: 1/3;
		}
	}
}

.btn-submit {
	padding: 0 16px 0 12px !important;
	margin-left: auto;
}

.view-0 {
	textarea {
		height: 85px;
		pointer-events: none;
		font-size: 0;
	}
}

.view-1 {
	textarea {
		height: 180px;
		padding-bottom: 80px;
	}
}

.select-style {
	margin-bottom: -22px;

	.modal & :global(div.select__control) {
		min-height: 1em;
		margin-left: 12px;
		box-shadow: none;
	}

	.modal & :global(div.select__single-value) {
		padding-right: 9px;
		font-weight: 400;
		color: var(--ra-color-brand-light);
	}
}

.prompt-history {
	--gap-x: 14px;

	&-list {
		display: flex;
		padding-top: 14px;
		flex-flow: row wrap;
		gap: 10px var(--gap-x);
	}

	&-item {
		--size: 28px;

		height: var(--size);
		max-width: calc(50% - var(--gap-x));
		padding: 0 12px;
		line-height: var(--size);
		background-color: var(--ra-color-white-soft);
		border-radius: var(--size) var(--size) var(--size) 0;
		transition: 150ms ease;

		&:hover {
			background-color: var(--ra-color-white-light);
		}
	}
}
