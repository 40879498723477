@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.input-param {
	$params-add-btn-size: 46px;

	display: grid;
	padding-top: 24px;
	padding-bottom: 36px;
	grid-template-columns: 1fr #{$params-add-btn-size};
	grid-gap: 20px;
}
