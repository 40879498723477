@value transitionInTime: 5000ms;
@value transitionOutTime: 5000ms;

.card[data-bb] {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	background-size: cover;
	background-position: center center;
	object-fit: cover;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.zoom {
	&-base {
		opacity: 0;
		visibility: hidden;
		transform: scale(1.3);
	}

	&-in {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
		transition:
			opacity 0.5s,
			visibility 0.5s,
			transform 0.5s;
	}

	&-out {
		opacity: 0;
		visibility: hidden;
		transform: scale(1.3);
		transition:
			opacity 0.5s,
			visibility 0.5s,
			transform 0.5s;
	}
}

.tint,
.media {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.video-bg {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 100%;
	height: 100%;
}

.tint {
	z-index: 2;
}

.media {
	z-index: 1;
	background-size: cover;
	background-position: center;
}
