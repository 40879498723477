@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/functions';

.switch {
	--size: 32px;

	position: relative;
	display: flex;
	width: 100%;
	background-color: theme.$ra-black-20;
	border-radius: var(--size);

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 50%;
		height: 100%;
		border-radius: inherit;
		background-color: theme.$ra-black-dark;
		transition: transform 200ms ease-in-out;
	}

	&.right::after {
		transform: translateX(100%);
	}
}

.btn {
	position: relative;
	z-index: 1;
	width: 50%;
	height: var(--size);
	color: theme.$ra-white;
	font-size: functions.map-deep-get(theme.$ra-font-size, label, base, font-size);
}
