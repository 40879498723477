@use '/src/routes/client/styles/theme';
@use '/src/routes/client/styles/mixins';

.select {
	display: grid;
	width: 30vw;
	height: 4vw;
	overflow: visible;
	color: theme.$ra-black;
	font-size: 2vw;
}

// reset antd styles
.select .field:global(.ant-select .ant-select-selector),
.select .field:global(.ant-select .ant-select-selection-search),
.select .field:global(.ant-select .ant-select-selector) input {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	font: inherit;
	color: inherit;
	background: none;
	line-height: inherit;
	border-radius: unset;
	list-style: none;
	border: none;
	inset: 0;
	outline: none;
	box-shadow: none;
}

// hide antd placeholder in favor to input::placeholder
.select .field :global(.ant-select-selection-placeholder) {
	display: none;
}

.select :global(.ant-select .ant-select-clear),
.select :global(.ant-select .ant-select-arrow),
.select .field:global(.ant-select-open .ant-select-selection-item) {
	background: none;
	color: inherit;
}

.select .is-mobile:global(.ant-select .ant-select-clear) {
	display: grid;
	width: 2em;
	height: 2em;
	margin-top: -1em;
	place-content: center;
	inset-inline-end: 0.3em;
}

.select .is-mobile.has-value {
	:global(.ant-select-clear) {
		opacity: 1;
	}

	:global(.ant-select-arrow) {
		opacity: 0;
	}
}

.is-mobile input[type='search'] {
	pointer-events: none;
}

// multiple select options
.select :global(.ant-select.ant-select-multiple) {
	&.has-value :global(.ant-select-selection-overflow) {
		--mask-width: 10px;

		flex-wrap: nowrap;
		overflow: auto;

		[dir='rtl'] & {
			mask-image: linear-gradient(90deg, rgba(black, 0) 0, black var(--mask-width));
		}

		[dir='ltr'] & {
			mask-image: linear-gradient(90deg, black calc(100% - #{var(--mask-width)}), rgba(black, 0) 100%);
		}
	}

	:global(.ant-select-selection-item) {
		background: currentColor;
	}

	:global(.ant-select-selection-item-content) {
		font-size: 0.6em;
	}

	:global(.ant-select-selection-item-remove) {
		color: inherit;
	}

	:global(.ant-select-selection-item-content),
	:global(.ant-select-selection-item-remove) {
		filter: invert(1);
	}

	input {
		opacity: 1 !important;
	}

	&:not(.has-value) :global(.ant-select-selection-search) {
		width: 100% !important;
	}
}

.dropdown {
	--font-size: 14px;

	padding: 0;
	font-family: inherit;

	:global(.ant-select-item.ant-select-item-option) {
		padding: 10px 16px;
		font-size: var(--font-size);
		border-bottom: 1px solid theme.$ra-black-soft;
		background: none;
		border-radius: 0;
		font-weight: inherit;
	}

	:global(.ant-select-item-option:hover) {
		background: theme.$ra-black-light;
	}

	:global(.ant-select-item-option-selected:not(.ant-select-item-option-disabled)) {
		background: theme.$ra-black-soft;
	}

	:global(.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state) {
		color: theme.$ra-black;
	}

	&.is-mobile {
		--font-size: 16px;

		padding: 0;
		border-radius: 0;
		box-shadow: none;
	}
}

.dropdown-actions {
	display: grid;
	padding: 0 12px;
	align-items: center;
	grid-template-columns: 1fr auto;
	gap: 16px;
	border-bottom: 1px solid theme.$ra-black-soft;

	button,
	input {
		font-weight: inherit;
		font-family: inherit;
		font-size: var(--font-size);
		line-height: 1;
	}

	button {
		min-width: 70px;
		padding: 10px;
		color: inherit;
		border: 1px solid theme.$ra-black;

		.is-multi & {
			background: theme.$ra-black;
			color: theme.$ra-white;
		}
	}
}

.dropdown-search {
	display: flex;
	gap: 8px;

	input {
		width: 100%;
		padding: 0;
		margin: 0;
		color: inherit;
		background: #fff;
		border: none;
		box-shadow: none;
		outline: none;
	}
}

.select .field {
	width: 100%;
	height: 100%;
	padding: 0 1.5vw;
	border: none;
	box-shadow: none;
	outline: none;
	color: inherit;
	font: inherit;
	text-align: inherit;
	background: rgba(0, 0, 0, 0.04);

	&.readonly {
		cursor: default;
		user-select: none;
	}
}
