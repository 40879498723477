//stylelint-disable
@use 'sass:map';
@use '/src/routes/admin/styles/theme.scss';
@use '/src/routes/admin/styles/mixins.scss';
@use '/src/routes/admin/components/pages/Story/Flow/vars';

.flow {
	position: absolute;
	top: vars.$story-flow-offset-top;
	right: vars.$story-flow-offset-right; // Force overflow by X axis, required to avoid html vertical scroll. Can be any negative number.
	bottom: 0;
	left: 0;

	// parent of root
	z-index: map.get(theme.$z-index, flow);
	font-family: 'PT Sans', sans-serif;
}

:global {
	.card-menu-open {
		:local {
			.flow {
				z-index: map.get(theme.$z-index, card-menu);
			}
		}
	}
}

.root {
	// parent of list and three
	// should have size
	position: relative;
	height: 100%;
	transform-origin: top left;
}
