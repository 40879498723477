@use '/src/routes/client/styles/theme';
@use '/src/routes/client/styles/mixins';

$progress-track-fill: theme.$ra-black-soft;
$progress-fill: theme.$ra-brand;

.progress {
	display: flex;
	overflow: visible;
	white-space: nowrap;
	font-size: 1.3vw;
	line-height: 1 !important;
	font-weight: normal;
	text-align: center;
	color: $progress-fill;
}

.progress-bar {
	width: 50vw;
	height: 1.25vw;
	border-radius: 1.25vw;
}

.num-text {
	display: grid;
	grid-template-columns: repeat(3, auto);
	direction: ltr;
}

.num-text-divider {
	display: grid;
	place-content: center;
}

.bar {
	display: flex;
	width: 100%;
	height: 100%;
	flex-flow: row nowrap;

	&,
	&-track {
		border-radius: inherit;
	}

	&-track {
		flex: 1;
		overflow: hidden;
		background-color: $progress-track-fill;
	}

	&-progress {
		width: 100%;
		height: 100%;
		background-color: currentColor;
		transform-origin: left;
		transition: transform 150ms theme.$ra-slow-in-fast-out;
	}
}
