@use '/src/routes/client/styles/theme';
@use '/src/routes/client/styles/mixins';
@use 'variables';

.text {
	@include mixins.darkModeInvertion(theme.$ra-darkMode-text);

	display: block;
	width: 20vw;
	overflow: visible;
	word-break: break-word;
	white-space: pre-wrap;
	font-size: variables.$bb-text-default-font-size;
	line-height: 2.8vw;
	font-weight: normal;

	span[style*='color:'] a {
		color: inherit;
	}

	&:not(.editable) span[style*='color:'] a {
		transition: opacity 200ms ease;

		&:hover {
			opacity: 0.8;
		}
	}

	u * {
		text-decoration: inherit;
	}

	&:empty {
		min-height: 1em;
		background: theme.$ra-black-soft;
	}
}

.editable {
	a {
		pointer-events: none;
	}
}

.truncate-container {
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	//-webkit-line-clamp: 2; /* number of lines to show */

	* {
		display: inline; // multi "line-clamp" not working in safari, with not inline elements
	}
}

.truncate-btn {
	position: relative;
	top: -0.175em;
	text-decoration: none;
	font-weight: 400;
	font-style: normal;
	white-space: nowrap;
	font-size: 80%;
	line-height: 1.3;

	.text:not(.has-max-rows-color) & {
		opacity: 0.6;
	}

	html:not([data-editor='true']) &:hover {
		cursor: pointer;
		opacity: 1;
	}
}
