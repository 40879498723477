@use 'sass:map';

$grid-width: 1040px;
$grid-gap: (
	small: 8px,
	medium: 16px,
	large: 32px,
);
$grid-column-template: 2, 6, 12, 14, auto;
// prettier-ignore
$grid-column-position: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, -1;
// prettier-ignore
$grid-row-position: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, -1;
$prefix: '-';
$container-padding: map.get($grid-gap, large);
$container-width: $grid-width + $container-padding * 2;

// Align horizontal any item inside the flexible container
$justify-content-props: flex-start, flex-end, center, space-between, space-around, stretch;

// Align vertically any item inside the flexible container
$align-items-props: flex-start, flex-end, center, space-between, space-around, stretch, baseline;

// Align self any item inside the flexible container
$align-self-props: stretch, center, flex-start, flex-end, baseline;

// Flex direction for any item inside the flexible container
$flex-direction-props: column, column-reverse, row, row-reverse;

// Flex direction for any item inside the flexible container
$flex-wrap-props: wrap, nowrap;

// Export to js
// -------------------------------------------------------------------------------------------------
@value gridWidth: #{$grid-width};
@value gridColumnTemplate: #{$grid-column-template};
@value gridColumnPosition: #{$grid-column-position};
@value gridRowPosition: #{$grid-row-position};
@value prefix: #{$prefix};
@value justifyContentProps: #{$justify-content-props};
@value alignItemsProps: #{$align-items-props};
@value alignSelfProps: #{$align-self-props};
@value flexDirectionProps: #{$flex-direction-props};
@value flexWrapProps: #{$flex-wrap-props};
@value containerPadding: #{$container-padding};
@value containerWidth: #{$container-width};
