@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

.google-font-list-item {
	position: relative;
	width: 100%;
	padding: 13px 16px 13px 28px;
	margin: 0 0 13px 0;
	box-shadow: inset 0 0 0 1px theme.$ra-black-soft;
	border-radius: 6px;

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		right: 32px;
		width: 10px;
		height: 10px;
		border-right: 2px solid theme.$ra-black-mid;
		border-bottom: 2px solid theme.$ra-black-mid;
		transform: translate(0, -50%) rotate(-45deg);
	}

	&.is-active {
		background-color: rgba(39, 196, 157, 0.3);
		box-shadow: inset 0 0 0 1px theme.$ra-brand;
	}

	&.default-cursor {
		cursor: default;

		&::after {
			display: none;
		}
	}
}

.head {
	width: 100%;
	text-align: left;
}

.name {
	display: flex;
	padding-bottom: 5px;
	align-items: center;

	span {
		padding: 2px 0 0 10px;
	}
}

.variant {
	margin-bottom: 10px;
}

.styles {
	display: grid;
	height: 0;
	overflow: hidden;
	grid-template-columns: 1fr 1fr 1fr;

	&.opened {
		height: auto;
		padding-top: 20px;
	}
}
