@use '/src/routes/admin/styles/theme';

.body {
	display: flex;
	justify-content: center;
	align-items: center;
}

.body-grid {
	width: 100%;
}

.title {
	margin-bottom: 30px;
	background-color: transparent;
}

.field-description {
	color: theme.$ra-white-mid;
}

.url-params,
.chars,
.redirect-url {
	display: grid;
	margin-bottom: 16px;
	grid-gap: 8px;
}

.url-params-wrap {
	position: relative;
	z-index: 3;
}

.url-params-values {
	padding-top: 14px;
}

.event-wrap {
	position: relative;
}

.values-label {
	margin-bottom: 8px;
}

.add-btn {
	position: relative;
	margin: 20px auto 0;

	&-label {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;

		&-icon {
			width: 24px;
			height: 24px;

			path {
				fill: theme.$ra-brand;
			}
		}
	}
}

.remove-btn {
	position: absolute;
	top: 16px;
	right: 12px;
	z-index: 2;

	&-icon {
		width: 24px;
		height: 24px;

		path {
			fill: theme.$ra-error-ds;
		}
	}

	&:hover {
		opacity: 0.8;
	}
}
