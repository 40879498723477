@use 'sass:math';

@use 'sass:map';
@use 'sass:string';
// Colors
// -------------------------------------------------------------------------------------------------

//$ra-primary-color                   : #2962ff;
$ra-primary-100: #cae9fd;
$ra-primary-200: #96cffb;
$ra-primary-300: #60adf3;
$ra-primary-400: #398de7;
$ra-primary-500: #005ed8;
$ra-primary-600: #0048b9;
$ra-primary-700: #00359b;
$ra-primary-800: #01247d;
$ra-primary-900: #001967;
$ra-primary-A100: #005ed8;
$ra-primary-A200: #0048b9;
$ra-primary-A400: #00359b;
$ra-primary-A700: #01247d;
$ra-primary-color: $ra-primary-500;

$ra-secondary-100: #e4fdfd;
$ra-secondary-200: #caf9fc;
$ra-secondary-300: #aeeef8;
$ra-secondary-400: #98dff1;
$ra-secondary-500: #74cae9;
$ra-secondary-600: #55a1c8;
$ra-secondary-700: #3a7ba7;
$ra-secondary-800: #255887;
$ra-secondary-900: #153f6f;
$ra-secondary-A100: #74cae9;
$ra-secondary-A200: #55a1c8;
$ra-secondary-A400: #3a7ba7;
$ra-secondary-A700: #255887;
$ra-secondary-color: $ra-secondary-500;

//$ra-success-color                   : #00ab80;
$ra-success-100: #dcfee9;
$ra-success-200: #bafcda;
$ra-success-300: #96f6ce;
$ra-success-400: #7aeec8;
$ra-success-500: #5ae0be;
$ra-success-600: #3ac3b1;
$ra-success-700: #27a39f;
$ra-success-800: #187c83;
$ra-success-900: #0f5e6c;
$ra-success-A100: #50e3c2;
$ra-success-A200: #3ac3b1;
$ra-success-A400: #27a39f;
$ra-success-A700: #187c83;
$ra-success-color: $ra-success-500;

//$ra-danger-color                    : #f56923;
$ra-danger-100: #fff6e4;
$ra-danger-200: #ffecc9;
$ra-danger-300: #ffdcae;
$ra-danger-400: #ffcf9a;
$ra-danger-500: #e5ac73;
$ra-danger-600: #db8f58;
$ra-danger-700: #e69a39;
$ra-danger-800: #934825;
$ra-danger-900: #7a3017;
$ra-danger-A100: #ffb879;
$ra-danger-A200: #db8f58;
$ra-danger-A400: #b7693c;
$ra-danger-A700: #934825;
$ra-danger-color: $ra-danger-500;

$ra-error-100: #fee6d9;
$ra-error-200: #fdc8b4;
$ra-error-300: #f9a18e;
$ra-error-400: #f37e70;
$ra-error-500: #ea4543;
$ra-error-600: #ca303c;
$ra-error-700: #a92137;
$ra-error-800: #881431;
$ra-error-900: #700b2e;
$ra-error-A100: #ea4543;
$ra-error-A200: #ca303c;
$ra-error-A400: #a92137;
$ra-error-A700: #881431;
$ra-error-color: $ra-error-500;
$ra-error-ds: #ed6d5a; // ds - 'dark style', suitable for dark theme interfaces

$ra-black: rgba(0, 0, 0, 0.87);
$ra-black-dark: rgba(0, 0, 0, 0.6);
$ra-black-mid: rgba(0, 0, 0, 0.38);
$ra-black-20: rgba(0, 0, 0, 0.2);
$ra-black-soft: rgba(0, 0, 0, 0.12);
$ra-black-light: rgba(0, 0, 0, 0.04);

$ra-gray: rgba(38, 38, 38, 0.85);
$ra-gray15: rgb(38, 38, 38);
$ra-gray50: rgb(128, 128, 128);
$ra-gray70: rgb(77, 77, 77);
$ra-gray80: rgb(51, 51, 51);

$ra-white: rgba(255, 255, 255, 1);
$ra-white-dark: rgba(255, 255, 255, 0.7);
$ra-white-mid: rgba(255, 255, 255, 0.45);
$ra-white-soft: rgba(255, 255, 255, 0.18);
$ra-white-light: rgba(255, 255, 255, 0.1);
$ra-white-extra-light: rgba(255, 255, 255, 0.05);

$ra-semantic-default-color: rgb(0, 168, 126);
$ra-semantic-global-color: rgb(255, 0, 63);
$ra-semantic-smart-color: rgb(197, 33, 230);
$ra-semantic-symbol-color: rgb(0, 114, 255);

$ra-shadow-color: rgba(189, 204, 212, 0.15);
$ra-brand: rgb(0, 168, 126);
$ra-brand-light: rgb(39, 196, 157);
$ra-brand-dark: rgb(0, 121, 93);
$ra-brand-white: rgb(255, 255, 255);
$ra-bg-body: $ra-brand-white;

// Grid
// -------------------------------------------------------------------------------------------------
$ra-grid-unit: px; // not support %
$ra-grid-col-max: 12; // {number}
$ra-grid-gutter: 15; // in $ra-grid-unit
$ra-grid-width: 1270; // in $ra-grid-unit
$ra-grid-dir: 'rtl'; // {string}

// Typography
// -------------------------------------------------------------------------------------------------
$ra-body-font-size: 1.6rem;

$ra-font-size: (
	hero: (
		base: (
			font-size: 6.4rem,
			line-height: normal,
			letter-spacing: -0.05rem,
		),
		compact: (),
		bold: (),
	),
	title: (
		base: (
			font-size: 4.2rem,
			line-height: math.div(48, 42),
		),
		compact: (),
		bold: (),
	),
	heading: (
		base: (
			font-size: 3.2rem,
			line-height: math.div(40, 32),
		),
		compact: (
			line-height: normal,
		),
		bold: (),
	),
	subtitle: (
		base: (
			font-size: 2.8rem,
			line-height: math.div(36, 28),
		),
		compact: (),
		bold: (),
	),
	subheading: (
		base: (
			font-size: 2.1rem,
			line-height: math.div(32, 21),
		),
		compact: (
			line-height: normal,
		),
		bold: (),
	),
	article: (
		base: (
			font-size: 1.8rem,
			line-height: math.div(24, 18),
		),
		compact: (
			line-height: normal,
		),
		bold: (),
	),
	paragraph: (
		base: (
			font-size: 1.6rem,
			line-height: math.div(24, 16),
		),
		compact: (
			line-height: normal,
		),
		bold: (),
	),
	body: (
		base: (
			font-size: 1.4rem,
			line-height: math.div(20, 14),
		),
		compact: (
			line-height: normal,
		),
		bold: (),
	),
	label: (
		base: (
			font-size: 1.3rem,
			line-height: math.div(20, 13),
		),
		compact: (
			line-height: normal,
		),
		bold: (),
	),
	input: (
		base: (
			font-size: 1.4rem,
			line-height: math.div(20, 14),
		),
		compact: (
			line-height: normal,
		),
		bold: (),
	),
	description: (
		base: (
			font-size: 1.2rem,
			line-height: math.div(16, 12),
		),
		compact: (
			line-height: normal,
		),
		bold: (),
	),
	footnote: (
		base: (
			font-size: 1.1rem,
			line-height: math.div(16, 11),
		),
		compact: (
			line-height: normal,
		),
		bold: (),
	),
);

$ra-font-family: (
	'mono': string.unquote('monospace'),
	'sans': string.unquote('Inter, sans-serif'),
	'serif': string.unquote('serif'),
	'sans-title': string.unquote('Inter, sans-serif'),
);

// Media queries
// -------------------------------------------------------------------------------------------------
$ra-media-mobile-max: 1024;
$ra-media-tablet-min: 768;
$ra-media-tablet-max: 1024;
$ra-media-desktop-min: $ra-media-tablet-max + 1;
$ra-media-desktopFHD-min: 1920;

$ra-media-mobile: '(max-width: #{$ra-media-mobile-max}px)';
$ra-media-mobile-landscape: '(max-width: #{$ra-media-mobile-max}px) and (orientation: landscape)';
$ra-media-mobile-portrait: '(max-width: #{$ra-media-mobile-max}px) and (orientation: portrait)';

$ra-media-tablet: '(min-width: #{$ra-media-tablet-min}px) and (max-width: #{$ra-media-tablet-max}px)';
$ra-media-tablet-landscape: '(min-width: #{$ra-media-tablet-min}px) and (max-width: #{$ra-media-tablet-max}px) and (orientation: landscape)';
$ra-media-tablet-portrait: '(min-width: #{$ra-media-tablet-min}px) and (max-width: #{$ra-media-tablet-max}px) and (orientation: portrait)';

$ra-media-desktop: '(min-width: #{$ra-media-desktop-min}px)';
$ra-media-desktopFHD: '(min-width: #{$ra-media-desktopFHD-min}px)';

$ra-media-non-mobile: '(min-width: #{$ra-media-tablet-min}px)';
$ra-media-non-desktop: '(max-width: #{$ra-media-tablet-max}px)';

// Easing
// -------------------------------------------------------------------------------------------------
$ra-ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
$ra-ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ra-ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$ra-ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46);
$ra-ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6);
$ra-ease-in-out-back: cubic-bezier(0.71, -0.46, 0.29, 1.46);
$ra-ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
$ra-ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34);
$ra-ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
$ra-ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ra-ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ra-ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$ra-ease-in-sine: cubic-bezier(0.47, 0, 0.75, 0.72);

// Link
// -------------------------------------------------------------------------------------------------
$ra-link-color: $ra-brand;
$ra-link-hover-color: $ra-brand-light;
$ra-link-active-color: $ra-brand-dark;
$ra-link-disabled-color: silver;
$ra-link-decoration: none;
$ra-link-hover-decoration: none;

// Forms
// -------------------------------------------------------------------------------------------------
$ra-input-component-transition: 200ms $ra-ease-in;
$ra-input-padding-top: 1.2rem;
$ra-input-padding-bottom: 0.9rem;
$ra-input-border-color: $ra-black-soft;
$ra-input-border-color-active: $ra-black;
$ra-input-border-color-disabled: rgba(0, 0, 0, 0);
$ra-input-border-color-error: $ra-danger-color;
$ra-label-base-color: $ra-black-dark;

// Base
// -------------------------------------------------------------------------------------------------
$ra-base-line-height: 1.4;
$ra-base-font-family: map.get($ra-font-family, 'sans');
$ra-base-font-size: 62.5%; // => 10px => 1rem
$ra-base-font-weight: 400;
$ra-base-color: $ra-black;
$ra-base-border-radius: 4px;

// Other
// -------------------------------------------------------------------------------------------------
$z-index: (
	'header': 100,
	'actions-panel': 110,
	'antd-tooltip': 1000,
	'ant-popover': 1010,
	'flow': 1,
	'connector-svg': 0,
	'connector-svg--active': 1,
	'list-header': 20,
	'step-header': 30,
	'step-controls': 1,
	'editor-canvas': 1,
	'editor-aside': 2,
	'editor-panel': 3,
	'editor-buttons': 3,
	'card-menu': 1000,
	'notification': 2000,
);
