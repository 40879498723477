@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

$texFieldMarginBottom: 24px;

.integrations {
	position: relative;
}

.title {
	padding-bottom: 24px;
	color: theme.$ra-white;
}

.buttons {
	display: flex;
	padding-top: 22px;
	flex-flow: row nowrap;
}

.back-btn {
	position: relative;
	margin-bottom: 18px;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: -16px;
		width: 6px;
		height: 6px;
		transform: translate(0, -50%) rotate(135deg);
		border-bottom: 1px solid theme.$ra-white-dark;
		border-right: 1px solid theme.$ra-white-dark;
	}

	&-label {
		color: theme.$ra-white-dark;
	}
}

.content {
	&.disabled {
		pointer-events: none;
		opacity: 0.6;
	}
}

.section-field {
	margin-bottom: 24px;
}

.disabled .section-field *,
.disabled .title {
	color: theme.$ra-white-mid !important;
	border-color: theme.$ra-white-mid !important;
}

.upgrade-btn {
	position: absolute;
	top: -10px;
	right: 0;
	display: block;
}

.lock-icon {
	padding-bottom: 2px;
	padding-left: 7px;
}
