@use 'sass:math';

@use '/src/routes/client/styles/theme';
@use '/src/routes/client/styles/functions';
@use '/src/routes/client/styles/mixins';

@function em($value) {
	@return math.div(functions.strip-unit($value), 16) + em;
}

$stroke-default: rgba(204, 204, 204, 1);
$stroke-hover: theme.$ra-semantic-fill;
$stroke-checked: theme.$ra-semantic-fill;

$fill-default: theme.$ra-white;
$fill-checked: theme.$ra-semantic-fill;

$icon-default: theme.$ra-white;

.checkbox-w {
	height: auto;
	overflow: visible;
	font-size: 1.56vw;
	line-height: 2.34vw;

	span[style*='color:'] a {
		color: inherit;
	}

	&:not(.editable) span[style*='color:'] a {
		transition: opacity 200ms ease;

		&:hover {
			opacity: 0.8;
		}
	}

	u * {
		text-decoration: inherit;
	}

	// production/preview mode
	label {
		cursor: pointer;
		user-select: none;
	}
}

.checkbox {
	display: inline-flex;
	align-items: baseline;
}

.box {
	position: relative;
	top: 0.16em;
}

.box,
.label {
	margin: 0 0.25em;
}

.label {
	flex: 1;

	.no-label.editing & {
		min-width: 2em;
	}
}

.shape {
	top: 0;
	left: 0;
	display: block;
	width: em(16px);
	height: em(16px);
	border: em(1px) solid $stroke-default;
	border-radius: em(2px);
	border-collapse: separate;

	&-main {
		position: relative;
		background-color: $fill-default;
		transition:
			border-color 0.3s,
			background-color 0.3s;

		&::after {
			content: ' ';
			position: absolute;
			top: 50%;
			left: 22%;
			display: table;
			width: em(5.71428571px);
			height: em(9.14285714px);
			border: em(2px) solid $icon-default;
			border-top: 0;
			border-left: 0;
			transform: rotate(45deg) scale(0) translate(-50%, -50%);
			opacity: 0;
			transition:
				all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),
				opacity 0.1s;
		}

		[data-hover-state] & {
			border-color: $stroke-hover;
		}
	}

	&-motion {
		position: absolute;
		display: none;
		border-color: $stroke-checked;
		background: none !important; // override shape background-color from json
		animation: checkedAnim 0.36s ease-in-out;
		animation-fill-mode: backwards;
	}

	.checked &,
	[data-selected-state] & {
		&.shape-main {
			background-color: $fill-checked;
			border-color: $stroke-checked;

			&::after {
				transform: rotate(45deg) scale(1) translate(-50%, -50%);
				opacity: 1;
				transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
			}
		}

		&.shape-motion {
			display: block;
		}
	}
}

.input {
	position: absolute;
	width: 0;
	height: 0;
	visibility: hidden;
}

.editable {
	a {
		pointer-events: none;
	}
}

@keyframes checkedAnim {
	from {
		transform: scale(1);
		opacity: 0.5;
	}

	to {
		transform: scale(1.6);
		opacity: 0;
	}
}
