@use '/src/routes/admin/styles/theme';

.errors {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.error {
	color: theme.$ra-error-color;

	.solid & {
		padding: 6px 11px;
		color: theme.$ra-white;
		background-color: theme.$ra-error-color;
		border-radius: 10px;
	}
}
