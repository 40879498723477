@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.general-settings-form {
	position: relative;
	margin: 0 0 40px 0;
}

.action-buttons {
	display: flex;
	margin-top: 34px;
	align-items: center;
}

.input {
	margin-top: 20px;
}
