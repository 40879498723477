@use '/src/routes/admin/styles/theme';

.usage {
	position: absolute;
	top: 50%;
	left: 56px;
	z-index: 2;
	width: 145px;
	transform: translateY(-50%);
	cursor: default;
}

.usage-bar-wrapper {
	display: flex;
	align-items: center;
	gap: 11px;
}

.name {
	display: -webkit-box !important;
	overflow: hidden;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	white-space: normal;
}

.progress {
	display: block;
	width: 50px;
	font-size: 12px;

	:global {
		.ant-progress-inner {
			background-color: theme.$ra-black-soft;
		}
	}
}

.views {
	color: theme.$ra-black-dark;
	line-height: 1.3;
	white-space: nowrap;
}

.details {
	top: -15px;
	left: 0;
	z-index: 2;
	min-width: 150px;
	padding: 12px 16px;
	transform: translate(0, -100%);

	&-section:not(:last-child) {
		margin-bottom: 8px;
	}

	.progress {
		width: auto;
	}
}
