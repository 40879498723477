@use '/src/routes/client/styles/theme';

.swipe {
	width: 20vw;
	height: 20vw;
	cursor: pointer;

	&:not(.editable) {
		overflow: visible;

		.inner {
			overflow: hidden;
		}
	}

	[data-editor='true'] & {
		cursor: default;

		&:empty {
			background: theme.$ra-black-soft;
		}
	}
}

.inner {
	width: 100%;
	height: 100%;
}

.underlay {
	position: absolute;
	opacity: 0;
	pointer-events: none;
	transition: opacity 200ms ease;

	.swiped & {
		opacity: 1;
	}

	[data-bb] {
		animation: none !important;
	}
}
