@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/functions';

.pf-label {
	@include mixins.mapToProps(functions.map-deep-get(theme.$ra-font-size, label, base));

	display: flex;
	width: 100%;
	align-items: center;
	align-self: stretch;
	user-select: none;
	color: theme.$ra-white-dark;
	cursor: pointer;
}
