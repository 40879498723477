@use '/src/routes/admin/styles/theme';

.fields {
	display: grid;
	margin-bottom: 30px;
	grid-template-columns: 1fr;
	grid-row-gap: 16px;
}

.item {
	display: grid;
	padding: 8px 16px 12px 12px;
	margin-bottom: 14px;
	align-items: center;
	grid-template: repeat(2, auto) / 1fr auto auto auto;
	grid-gap: 0 16px;
	border: 1px solid rgba(255, 255, 255, 0.45);
	border-radius: 7px;

	button {
		grid-row: 1/4;
		font-weight: 400;

		&:nth-of-type(1) {
			grid-column: 2/3;
		}

		&:nth-of-type(2) {
			grid-column: 3/4;
		}

		&:nth-of-type(3) {
			grid-column: 4/5;
		}
	}
}

.items-title {
	padding-bottom: 6px;
	margin-bottom: 24px;
}

.item-url {
	color: theme.$ra-white;
}

.item-cards {
	color: theme.$ra-white;
	opacity: 0.45;
}

.item-test-btn-disabled {
	cursor: default;
	opacity: 0.45;

	&:hover {
		opacity: 0.45;
	}
}

.item-remove-icon {
	path {
		fill: theme.$ra-error-ds;
	}
}

.btn-add {
	margin-bottom: 30px;
}

.history-btn {
	display: flex;
	align-items: center;

	i {
		display: block;
		height: 20px;
	}
}

.databridge-toggle {
	margin-bottom: 14px;
}

.databridge-hint {
	margin-bottom: 24px;
	opacity: 0.45;
}
