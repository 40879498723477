@use 'sass:map';
@use '/src/routes/client/styles/theme';

@value transitionInTime: 2000ms;
@value transitionOutTime: 2000ms;

.content {
	position: relative;
	// is in between float above and below. see also FloatChildren/index.js
	z-index: map.get(theme.$z-index, 'content');
	min-height: 300px; // default <iframe /> height to avoid content with no size
	overflow: hidden;

	[data-editor='true'] & {
		outline: 1px dashed theme.$ra-brand-light;
	}
}

.content :where(*:not(:global(.video-js), :global(.video-js *))) {
	position: relative; // all children BuildingBlock on case of "pin" should be relative to parent node
}

.content-i {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.content-w {
	display: flex;
	min-height: 100vh;

	&.align-top .content-i {
		align-self: flex-start;
	}

	&.align-center .content-i {
		align-self: center;
	}

	&.align-bottom .content-i {
		align-self: flex-end;
	}
}
