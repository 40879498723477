@use '/src/routes/admin/styles/theme';

.rate-events {
	position: relative;
}

.events {
	display: flex;
	width: 100%;
	margin-bottom: 40px;
	flex-direction: column;
	gap: 20px;
}

.event {
	display: flex;
	width: 100%;
	height: 48px;
	padding: 0 18px;
	justify-content: space-between;
	align-items: center;
	border: 1px solid theme.$ra-white-light;
	border-radius: 6px;

	&-name {
		max-width: 99px;
	}

	&-controls {
		display: flex;
		align-items: center;
		gap: 15px;

		.slider {
			width: 200px;
		}

		.event-rate {
			display: flex;
			width: 60px;
			height: 33px;
			padding: 6px 10px 7px;
			align-items: center;
			box-shadow: inset 0 0 0 1px theme.$ra-white-light;
			border-radius: 3px;
			transition: box-shadow 200ms ease;
			font-size: 16px;

			.measure-span {
				position: absolute;
				visibility: hidden;
				white-space: nowrap;
			}

			.input[type='number']::-webkit-inner-spin-button,
			.input[type='number']::-webkit-outer-spin-button {
				margin: 0;
				-webkit-appearance: none;
			}

			.input[type='number'] {
				-moz-appearance: textfield;
			}

			.input {
				width: 30px;
				min-width: none;
				padding: 0;
				background-color: transparent;
				outline: none;
				border: none;
				color: inherit;
			}

			.percent {
				opacity: 0.5;
			}

			&:focus-within {
				box-shadow: inset 0 0 0 2px theme.$ra-white-light;
			}
		}
	}

	&-remove {
		&-btn {
			display: flex;
			width: 24px;
			height: 24px;
			justify-content: center;
			align-items: center;
			opacity: 0;
			background-color: transparent;
			transition: opacity 200ms ease;
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		}

		&-icon {
			path {
				fill: theme.$ra-error-ds;
			}
		}
	}

	&.disabled {
		pointer-events: none;

		.event-remove-btn {
			visibility: hidden;
		}

		.event-rate {
			box-shadow: none;

			input {
				opacity: 0.5;
			}
		}

		:global {
			.ant-slider-handle {
				display: none;
			}
		}
	}

	:where(&:hover) {
		.event-remove-btn {
			opacity: 1;
		}
	}
}

.add-btn {
	position: relative;
	margin: 0 auto;

	&-label {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;

		&-icon {
			width: 24px;
			height: 24px;

			path {
				fill: theme.$ra-brand;
			}
		}
	}
}
