@use '/src/routes/admin/styles/theme';

.platform-select {
	display: flex;
	width: 100%;
	justify-content: center;

	.group {
		position: relative;
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
	}

	.button {
		position: relative;
		display: flex;
		width: 40px;
		height: 40px;
		justify-content: center;
		align-items: center;
		flex: 0 0 40px;
		overflow: hidden;
		text-transform: uppercase;
		line-height: 40px;
		border-width: 0;
		box-shadow: none;
		outline: none;
		user-select: none;
		text-align: center;
		color: white;
		border-radius: 0;
		background: none;
		opacity: 0.7;
		transition: opacity 200ms ease;

		&::before,
		&::after {
			display: none !important;
		}

		&:hover {
			color: white;
			box-shadow: none;
			opacity: 1;
		}

		&:first-child {
			border-left: 0;
		}

		&[class*='ant-radio-button-wrapper'] {
			height: auto;
			color: theme.$ra-black !important;

			&:hover {
				color: theme.$ra-black !important;
			}
		}

		&[class*='ant-radio-button'] {
			svg {
				opacity: 1;
				transition: opacity !important;
				transition-duration: 0.35s !important;
			}

			&:hover {
				svg {
					opacity: 0.9;
				}
			}
		}

		&[class*='ant-radio-button-wrapper-checked'] {
			svg {
				opacity: 1;
			}

			.icon path,
			:global(.path) {
				fill: theme.$ra-brand;
			}

			.icon {
				:global(.fill) {
					fill: none;
				}
			}

			&:hover {
				svg {
					opacity: 1;
				}
			}
		}

		&[class*='ant-radio-button-wrapper-checked'],
		&[class*='ant-radio-button-checked'],
		input {
			background-color: white;
			border-color: transparent !important;
			border: none !important;
			outline: none !important;
			box-shadow: none !important;
		}

		&[class*='.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)'] {
			border-color: transparent !important;
		}

		.icon {
			svg {
				width: 24px;
				height: 24px;
			}

			:global(.fill) {
				fill: none;
			}
		}

		.icon path,
		:global(.path) {
			fill: theme.$ra-black-mid;
		}
	}
}
