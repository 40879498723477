@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

.description {
	margin-bottom: 20px;
	color: theme.$ra-black-dark;
}

.fields {
	display: flex;
	width: 100%;
	flex-direction: column;
}
