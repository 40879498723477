@use '/src/routes/admin/styles/theme';

.story {
	position: static;

	&-content {
		position: static;
	}
}

.bg {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	background-color: hsl(0, 0%, 20%);
}
