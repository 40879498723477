@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.section {
	margin-bottom: 40px;

	&-title {
		margin-bottom: 16px;
	}

	&-field {
		margin-bottom: 24px;
	}
}

.title {
	padding-bottom: 24px;
	color: theme.$ra-white;
}
