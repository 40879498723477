@use '/src/routes/admin/styles/theme';

.content {
	--popover-padding-y: 16px;

	position: fixed;
	display: flex;
	padding: var(--popover-padding-y) 0;
	overflow: hidden;
	background-color: theme.$ra-gray80;
	border-radius: 12px;
	color: theme.$ra-white;
}

.content-inner {
	flex: 1;
}

.scrollable {
	max-height: 100%;
}

.overlay {
	position: fixed;
	background-color: theme.$ra-black-dark;
	backdrop-filter: saturate(200%) blur(1px);
}

.overlay,
.content {
	z-index: 100;
}

.popover-container {
	position: fixed;

	&:empty {
		pointer-events: none;
		visibility: hidden;
	}
}
