@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

.upload-custom-font-btn {
	label {
		cursor: pointer;

		&:hover {
			button {
				background-color: rgba(0, 168, 126, 0.1);
			}
		}
	}

	button {
		display: flex;
		width: 100%;
		height: 84px;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		border-radius: 6px;
		border: 1px dashed theme.$ra-brand;
		color: theme.$ra-brand;
		transition: background-color 0.2s ease-in;
	}
}
