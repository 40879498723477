@use 'sass:map';
@use '/src/routes/admin/styles/theme';

.notification {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: map.get(theme.$z-index, notification);
	padding: 17px 30px;
	background-color: theme.$ra-gray50;
	color: theme.$ra-white;

	&.danger {
		background-color: theme.$ra-danger-700;
	}
}

.container {
	width: 600px;
	max-width: 100%;
	margin: 0 auto;

	.wide & {
		width: 800px;
	}
}
