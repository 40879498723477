@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

.action-btn {
	margin: 0 20px 0 0;
}

.actions {
	margin-top: 26px;
}
