@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.textarea {
	position: relative;
	margin-bottom: 5px;

	&.error textarea {
		box-shadow: inset 0 0 0 2px theme.$ra-error-color;
	}
}

.textarea-field {
	--textarea-border-color: #ccc;

	:where(textarea) {
		display: block;
		width: 100%;
		padding: 13px 55px 15px 16px;
		margin: 6px 0 0 0;
		outline: none;
		background-color: transparent;
		border: none;
		transition: box-shadow 0.25s ease-in-out;
		border-radius: 6px;
		box-shadow: inset 0 0 0 1px var(--textarea-border-color);
		resize: none;

		&:focus {
			box-shadow: inset 0 0 0 2px theme.$ra-brand;
		}
	}

	::placeholder {
		color: theme.$ra-black-mid;
	}

	&.read-only {
		pointer-events: none;
		opacity: 0.6;
	}

	&.dark {
		--textarea-border-color: #{theme.$ra-gray50};

		:where(textarea) {
			color: theme.$ra-white;

			&:focus {
				box-shadow: inset 0 0 0 2px theme.$ra-gray50;
			}

			&:disabled {
				color: theme.$ra-white-mid;
				cursor: not-allowed;
				background-color: var(--ra-color-white-light);
			}
		}

		::placeholder {
			color: theme.$ra-gray50;
		}
	}
}

.label-text {
	padding: 0 0 2px 0;
}

.resize-vertical {
	.field-wrapper {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			right: 3px;
			bottom: 3px;
			width: 12px;
			height: 12px;
			cursor: ns-resize;
			pointer-events: none;
			background-image: linear-gradient(
				-45deg,
				transparent 14%,
				var(--textarea-border-color) 14%,
				var(--textarea-border-color) 21%,
				transparent 21%,
				transparent 28%,
				var(--textarea-border-color) 28%,
				var(--textarea-border-color) 35%,
				transparent 35%
			);
		}
	}

	textarea {
		min-height: 5em;
		resize: vertical;

		&::-webkit-resizer {
			border: 16px solid transparent;
		}

		&::-webkit-scrollbar-corner {
			background-color: transparent;
		}
	}
}
