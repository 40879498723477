@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.select {
	position: absolute;
	right: 0;
	bottom: 0;
	left: -10px;
	z-index: 300;
	padding: 3px;
	border-radius: 4px;
	background-color: rgb(71, 71, 69);
}

.option {
	position: relative;
	padding: 0 4px 0 20px;
	font-size: 11px;
	line-height: 1.6;
	color: rgb(159, 159, 159);
	border-radius: 2px;

	&:hover {
		background-color: rgb(92, 92, 92);
	}

	.icon {
		$decorElementH: 2px;

		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 100%;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			height: $decorElementH;
			background-color: rgb(159, 159, 159);
		}

		&::before {
			left: 5px;
			width: 4px;
			transform: rotate(45deg);
		}

		&::after {
			right: 6px;
			width: 7px;
			transform: rotate(-45deg);
		}
	}
}
