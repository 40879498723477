@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/functions';
@use '../vars';

.pf {
	&-text {
		position: relative;
		display: flex;
		align-items: center;
		flex: 1;
	}

	&-input {
		@include mixins.mapToProps(functions.map-deep-get(theme.$ra-font-size, input, base));
		@include mixins.placeholder-color(theme.$ra-white-mid);

		display: block;
		width: 100%;
		height: vars.$pf-height;
		padding: 0;
		margin: 0;
		align-self: center;
		vertical-align: middle;
		border: 0 none #000;
		background-color: transparent;
		background-image: none;
		color: theme.$ra-white;
		text-align: right;

		&:focus,
		&:active {
			outline: none;
		}

		&:disabled {
			outline: none;
			color: theme.$ra-white-soft;
			pointer-events: none;
		}
	}
}

.clear-btn {
	display: flex;
	width: 16px;
	height: 16px;
	opacity: 0;
	visibility: hidden;
	transition:
		opacity 200ms,
		visibility 200ms;

	.pf-text:hover & {
		opacity: 1;
		visibility: visible;
	}
}
