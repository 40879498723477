@use '/src/routes/admin/styles/theme';

.hint {
	display: inline-block;
	color: theme.$ra-black-dark;

	&.dark {
		color: theme.$ra-white-mid;
	}
}
