@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.color-picker {
	width: 100%;
	overflow: hidden;
	user-select: none;
}

.controls.with-pipette {
	position: relative;
	padding-left: 36px;
}

.pipette {
	position: absolute;
	top: 14px;
	left: 0;
	color: theme.$ra-white;
}

.sb-values-area {
	position: relative;
	height: 150px;
	margin: 0 0 16px 0;
	overflow: hidden;
	background-image: linear-gradient(180deg, transparent, #000),
		linear-gradient(90deg, #fff, #fff 0%, #fff 0, hsla(0, 0%, 100%, 0));
	cursor: crosshair;
}

.sb-values-handler {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 1px;
	height: 1px;

	&-inner {
		position: absolute;
		top: 50%;
		left: 50%;
		box-sizing: border-box;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		border: 2px solid white;
		transform: translate(-50%, -50%);
		cursor: default;
	}
}

.hue-value-slider,
.opacity-slider {
	margin: 0 0 8px;
}

.hue-value-slider {
	background-image: linear-gradient(
		90deg,
		hsl(0, 100%, 50%),
		hsl(60, 100%, 50%),
		hsl(120, 100%, 50%),
		hsl(180, 100%, 50%),
		hsl(240, 100%, 50%),
		hsl(300, 100%, 50%),
		hsl(360, 100%, 50%)
	);
}

.opacity-slider {
	background-image: linear-gradient(180deg, rgba(39, 39, 39, 0.9), rgba(39, 39, 39, 0.9)),
		url('/src/common/assets/images/transparency-texture.jpg');
}

.bottom {
	padding: 0 16px 21px;
}

.labels {
	display: flex;
	padding: 14px 0 0 0;
	flex-flow: row nowrap;
}

.color-model {
	position: relative;
	cursor: pointer;

	.icon {
		position: absolute;
		top: 0;
		bottom: 0;
		left: -10px;
		z-index: 2;
		width: 18px;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		background-color: rgb(71, 71, 69);
		opacity: 0;
		visibility: hidden;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 5px;
			height: 5px;
			border-left: 1px solid rgb(159, 159, 159);
			border-bottom: 1px solid rgb(159, 159, 159);
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}

	&-inner {
		display: flex;
		flex-flow: row nowrap;
		border-radius: 8px;

		&:hover {
			background-color: rgb(71, 71, 69);

			.icon {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

.inputs {
	display: flex;
	padding: 14px 0 0 0;
	flex-flow: row nowrap;
}

.input-wrap {
	width: 32px;
	margin: 0 0 0 5px;

	&:first-child {
		margin: 0;
		flex-grow: 0.8;
	}

	input {
		width: 100%;
		color: white;
		background-color: transparent;
		border: none;
		outline: none;
		text-align: center;

		&[type='number'] {
			-moz-appearance: textfield;
		}

		&[type='number']::-webkit-inner-spin-button,
		&[type='number']::-webkit-outer-spin-button {
			margin: 0;
			-webkit-appearance: none;
		}
	}
}

.color-picker.default {
	.controls {
		display: block;
	}
}

.color-picker.simple {
	.sb-values-area {
		margin-bottom: 0;
	}

	.bottom {
		padding-top: 12px;
		padding-bottom: 8px;

		.hue-value-slider {
			margin-bottom: 4px;
		}

		.opacity-slider {
			margin-bottom: 12px;
		}
	}

	.controls {
		display: flex;
		flex-direction: column-reverse;

		.color-model {
			display: none;
		}

		.labels {
			padding-top: 0;
			justify-content: space-between;

			label:first-child {
				flex-grow: 1;
			}

			label:last-child {
				width: 45px;
			}
		}

		.inputs {
			padding-top: 0;
			justify-content: space-between;

			input {
				text-align: left;
			}

			.input-wrap:last-child {
				width: 45px;
			}
		}
	}
}

.overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.btn-reset {
	margin-top: 1px;
}

.swatch {
	display: grid;
	padding: 4px 16px 12px;
	grid-template-columns: repeat(8, 1fr);
	grid-gap: 4px;
}

.swatch-color {
	cursor: pointer;
	justify-self: center;
}
