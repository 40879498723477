@use '/src/routes/client/styles/theme';

.text {
	width: 4vw;
	overflow: visible;
	word-break: break-word;
	color: white;
	font-size: 1.02vw;
	line-height: 1.02vw;

	&.hidden {
		display: none !important;
	}

	&[data-bb] {
		align-self: end;
	}

	html[dir='ltr'] & {
		justify-self: start;
	}

	html[dir='rtl'] & {
		justify-self: end;
		text-align: left;
	}

	&:empty {
		min-height: 1em;
		background: theme.$ra-black-soft;
	}
}

.counter {
	overflow: visible;

	&.hidden {
		visibility: hidden;
	}
}
