@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.upload-video-input {
	overflow: hidden;

	&.dark {
		.content {
			border: 1px solid gray;
			background-color: theme.$ra-white-extra-light;

			&.hovered,
			&:hover {
				border: 1px solid theme.$ra-brand;

				svg {
					path {
						fill: theme.$ra-brand;
					}
				}
			}
		}

		.label {
			color: theme.$ra-white;
		}

		.file-name {
			color: theme.$ra-white-dark;
		}

		.placeholder {
			color: theme.$ra-white-dark;
		}
	}
}

.label {
	padding: 0 0 10px 0;
}

.no-file-view {
	display: flex;
	height: 100%;
	padding: 0 50px;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.video-preview {
	position: relative;
	z-index: 2;
	height: 100%;
	overflow: hidden;
	border-radius: inherit;
	background-color: theme.$ra-black-light;

	.file-name {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 80%;
		transform: translate(-50%, -50%);
		text-align: center;
	}

	video {
		position: relative;
		z-index: 1;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.delete-btn {
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 2;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background-color: theme.$ra-brand;
		transition: opacity 0.2s ease-in;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 20px;
			height: 1px;
			background-color: #fff;
		}

		&::before {
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		&::after {
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&:hover {
			opacity: 0.8;
		}
	}
}

.content {
	position: relative;
	display: block;
	box-sizing: border-box;
	width: 100%;
	border: 1px solid #ccc;
	border-radius: 6px;
	background-color: theme.$ra-black-light;
	cursor: pointer;
	transition: border-color 0.2s ease;

	&:hover,
	&.hovered {
		border: 1px solid theme.$ra-brand;

		svg {
			path {
				fill: theme.$ra-brand;
			}
		}
	}
}

.placeholder {
	padding-top: 8px;
	color: theme.$ra-black-dark;
}

.file-name {
	display: block;
	color: theme.$ra-black-dark;
}

.input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	opacity: 0;
	cursor: pointer;
}
