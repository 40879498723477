@use 'vars';

// Container
// -------------------------------------------------------------------------------------------------
[data#{vars.$prefix}container] {
	padding: 0 vars.$container-padding;
}

[data#{vars.$prefix}fix-width] {
	width: vars.$container-width;
	margin: 0 auto;
}

// Grid
// -------------------------------------------------------------------------------------------------
[data#{vars.$prefix}grid] {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(0, 1fr)); // infinity columns support
	grid-auto-rows: minmax(0, auto); // infinity rows support
	//word-break: break-all;
}

@each $key, $value in vars.$grid-gap {
	[data#{vars.$prefix}gap='#{$key}'] {
		grid-gap: $value;
	}

	[data#{vars.$prefix}column-gap='#{$key}'] {
		grid-column-gap: $value;
	}

	[data#{vars.$prefix}row-gap='#{$key}'] {
		grid-row-gap: $value;
	}
}

@each $prop in vars.$grid-column-template {
	[data#{vars.$prefix}columns='#{$prop}'] {
		grid-template-columns: repeat($prop, 1fr);
	}
}

// Column
// -------------------------------------------------------------------------------------------------
@each $prop in vars.$grid-column-position {
	[data#{vars.$prefix}column-start='#{$prop}'] {
		grid-column-start: $prop;
	}

	[data#{vars.$prefix}column-end='#{$prop}'] {
		grid-column-end: $prop;
	}

	[data#{vars.$prefix}column-span='#{$prop}'] {
		grid-column-end: span $prop;
	}
}

@each $prop in vars.$grid-row-position {
	[data#{vars.$prefix}row-start='#{$prop}'] {
		grid-row-start: $prop;
	}

	[data#{vars.$prefix}row-end='#{$prop}'] {
		grid-row-end: $prop;
	}

	[data#{vars.$prefix}row-span='#{$prop}'] {
		grid-row-end: span $prop;
	}
}

// Column flex properties
// -------------------------------------------------------------------------------------------------

[data#{vars.$prefix}flex] {
	display: flex;
}

@each $prop in vars.$justify-content-props {
	[data#{vars.$prefix}justify-content='#{$prop}'] {
		justify-content: $prop;
	}
}

@each $prop in vars.$align-items-props {
	[data#{vars.$prefix}align-items='#{$prop}'] {
		align-items: $prop;
	}
}

@each $prop in vars.$align-self-props {
	[data#{vars.$prefix}align-self='#{$prop}'] {
		align-self: $prop;
	}
}

@each $prop in vars.$flex-direction-props {
	[data#{vars.$prefix}flex-direction='#{$prop}'] {
		flex-direction: $prop;
	}
}

@each $prop in vars.$flex-wrap-props {
	[data#{vars.$prefix}flex-wrap='#{$prop}'] {
		flex-wrap: $prop;
	}
}
