@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.icon {
	&.default {
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			display: block;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			transform: translate(50%, -50%);
			background-color: white;
		}
	}

	&.no-fill {
		position: inherit;

		:global {
			.fill {
				fill: none;
			}
		}
	}

	&.landscape {
		transform-origin: center;
		transform: rotate(90deg);
	}
}
