@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

$modal-width: 994px;
$texFieldMarginBottom: 24px;

/* stylelint-disable */
:export {
	modalWidth: #{$modal-width};
}
/* stylelint-enable */

.modal {
	height: 95vh;

	:global {
		.ant-modal-content {
			padding: 50px 35px 35px 65px;
			border-radius: 24px;
		}

		.ant-modal-content,
		.ant-modal-body,
		form {
			height: 100%;
		}

		form {
			display: grid;
			align-items: flex-start;
			grid-template-rows: auto 1fr auto;
		}
	}
}

.title {
	display: flex;
	align-items: center;

	span {
		padding-left: 24px;
	}
}

.current-view {
	max-width: 620px;
}

.head {
	padding: 0 0 40px 0;
}

.action-btn {
	margin: 0 20px 0 0;
}

.body {
	height: calc(100% - 40px);
	padding: 0;
	overflow: auto;

	&-content {
		width: 620px;
	}
}

.footer {
	button {
		margin-right: 16px;
	}
}
