@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

.body {
	position: relative;

	&-text {
		padding-bottom: 20px;
	}
}

.confirm-input {
	width: 100%;
	height: 48px;
	padding: 13px 45px 15px 16px;
	margin: 6px 0 8px 0;
	outline: none;
	background-color: transparent;
	border: none;
	border-radius: 6px;
	box-shadow: inset 0 0 0 1px #ccc;
	transition: box-shadow 0.25s ease;

	&:focus {
		box-shadow: inset 0 0 0 2px theme.$ra-brand;
	}
}

.body-grid {
	width: 100%;
}
