@use "../vars";

.pf-textarea-autosize {
	width: 100%;
	min-height: vars.$pf-height;
	background: none;
	border: none;
	outline: none;
	color: inherit;
}
