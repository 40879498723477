@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.round-progress {
	position: relative;
	text-align: center;

	&.light {
		circle {
			stroke: #ccc;
		}
	}

	.loader {
		transform: rotate(-90deg);
	}

	circle {
		fill: none;
		stroke: theme.$ra-white-dark;
		transition: stroke-dashoffset 0.4s ease;
	}
}
