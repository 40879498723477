@use '/src/routes/admin/styles/theme';

.slider-w {
	position: relative;
	margin: 0 0 30px;

	&:hover {
		.buttons {
			opacity: 1;
			visibility: visible;
		}
	}
}

.slider {
	margin: 0;
	flex: 1;
	user-select: none;
}

.buttons {
	display: flex;
	padding-left: 15px;
	flex-flow: row nowrap;
	align-items: center;
	flex: 0;
	opacity: 0;
	visibility: hidden;
	transition: opacity, visibility;
	transition-duration: 0.35s;
}

button.btn {
	//display: flex;
	//width: 20px;
	//height: 20px;
	//padding: 2px;
	//justify-content: center;

	//path {
	//	fill: currentColor;
	//}
	//
	//svg {
	//	display: block;
	//}

	&:not(:first-child) {
		margin-left: 10px;
	}

	&-delete {
		//border: none;
		//
		//&,
		//&:hover {
		//	background-color: currentColor;
		//}
		//
		//svg path {
		//	fill: $ra-black-dark;
		//}
	}

	&-add {
		//svg path {
		//	fill: $ra-black-dark;
		//}
	}
}

.select-field {
	margin-bottom: 30px;
	color: theme.$ra-black;
}

.header {
	display: flex;
	margin-bottom: 20px;
	align-items: center;
}
