@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.radio {
	cursor: pointer;

	&-inner {
		position: relative;
		display: flex;
		padding: 5px 0 0 0;
		align-items: center;
	}

	&-box {
		position: relative;
		width: 20px;
		height: 20px;
	}

	input {
		display: none;
	}
}

.field-radio {
	&.disabled {
		.radio,
		.label {
			opacity: 0.45;
			cursor: not-allowed;
		}
	}
}
