@use "vars";

.link {
	text-decoration: none;
	color: vars.$nav-link-color;
	transition: 0.25s ease;

	&:hover,
	&.active {
		color: vars.$nav-link-color-active;
	}
}
