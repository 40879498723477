@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.list-content {
	position: relative;
	height: 100%;
	overflow-y: auto;
}

.list {
	position: relative;
}

.item-wrapper {
	width: 100%;
}
