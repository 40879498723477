@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.field-slider {
	position: relative;
}

.disabled {
	.slider,
	.label {
		opacity: 0.45;
		cursor: not-allowed;
	}

	:global(.ant-slider-disabled) {
		opacity: 1;
	}
}

.inner {
	position: relative;
}

.slider {
	display: block;
	width: 100%;
}
