@use 'sass:map';
@use '/src/routes/client/styles/theme';

.outbrain-widget {
	position: relative;
	z-index: map.get(theme.$z-index, content);
	padding: 16px 12px;
	background-color: white;

	&-top-offset {
		position: absolute;
		top: -330px;
		right: 0;
		left: 0;
		height: 100%;
		pointer-events: none;
	}

	&.desktop-platform {
		padding: 1.5vw 10vw 0;
	}
}
