@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.create-form {
	position: relative;
	width: 400px;
	margin: 0 0 40px 0;
}

.action-buttons {
	display: flex;
	margin-top: 34px;
	align-items: center;
}

.input {
	margin-bottom: 20px;
}

.prev-upload-info {
	margin-bottom: 24px;
}

.progress-info {
	width: 600px;
}

.progress-bar-wrap {
	width: 100%;
	padding: 30px 0 0 0;
	margin: 0 0 30px 0;

	.progress-value {
		width: 100%;
		text-align: center;
	}

	.progress-bar {
		position: relative;
		height: 6px;
		border: 1px solid theme.$ra-black-soft;
		border-radius: 3px;

		.progress {
			height: 100%;
			transform-origin: 0 0;
			transform: scaleX(0);
			background-color: theme.$ra-black-soft;
			transition: transform 0.1s ease-in-out;
		}
	}
}
