@use '/src/routes/admin/styles/theme';

.hint {
	width: 100%;
	text-transform: lowercase;
	color: theme.$ra-black-mid;

	&-error {
		color: theme.$ra-error-A100;
	}
}
