@use '/src/routes/client/styles/helpers';
@use '/src/routes/client/styles/mixins';
@use '/src/routes/client/styles/theme';

.video {
	@include mixins.darkModeInvertion(theme.$ra-darkMode-image);

	position: relative;
	width: 20vw;
	height: 20vw;
	object-fit: contain;

	video {
		width: 100%;
		height: 100%;

		/* Hide the download button for Firefox */
		&::-moz-media-controls-download-button {
			display: none;
		}
	}

	iframe {
		border: none;
	}

	// disable native|youtube|vimeo|video-js player events in editor
	[data-editor='true'] & :is(video, iframe, :global(.video-js)),
	// disable player any events within an answer for do not interfere with the answer events
	html:not([data-editor='true']) [data-bb='ANSWER'] & {
		pointer-events: none;
	}
}

.empty {
	@extend %empty-placeholder;
}
