@use '/src/routes/client/styles/theme';

.error {
	position: absolute;
	top: 100%;
	right: 0;
	left: 0;
	padding: 5px 2px;
	color: theme.$ra-error-A400;
	font-size: 0.75em;
	line-height: 1.4;
}

.editable {
	display: block;
	outline: none;

	&:focus {
		outline: 1px dashed theme.$ra-error-A400;
	}

	[data-editor='true'] &:empty {
		min-height: 1.5em;
		outline: 1px dashed theme.$ra-error-A400;
		cursor: text;
	}
}

.hidden {
	top: 0;
	display: none;
	width: 0;
	height: 0;
}
