@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/functions';

.pf-button {
	@include mixins.mapToProps(functions.map-deep-get(theme.$ra-font-size, label, base));

	display: flex;
	padding: 4px 20px;
	justify-content: center;
	align-items: center;
	background-color: theme.$ra-white-light;
	text-align: center;
	color: theme.$ra-white;
	transition: background-color 200ms ease;
	border-radius: 40px;

	&:hover:not(:disabled) {
		background-color: theme.$ra-white-mid;
	}

	&.stretch,
	&.reset {
		width: 100%;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.6;
	}
}

.reset {
	padding: 13px 0 10px;
	background-color: theme.$ra-black-mid;
	border-radius: 0;
	text-transform: uppercase;
	font-size: functions.map-deep-get(theme.$ra-font-size, footnote, base, font-size);
	font-weight: 500;
	box-shadow: 0 -1px 0 0 rgba(255, 255, 255, 0.1);

	i {
		margin: -1px 2px 0 0;
	}

	&:hover:not(:disabled) {
		background-color: theme.$ra-black-dark;
	}
}
