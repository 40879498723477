@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.label {
	display: flex;
	align-items: center;
	line-height: 1.1;
	color: currentColor;
}

.loading {
	:not(.circle) > & {
		margin-right: 8px;
		margin-left: -8px;
	}
}

.button {
	$btn-size-huge: 74px;
	$btn-size-large: 48px;
	$btn-size-medium: 40px;
	$btn-size-small: 32px;
	$btn-size-tiny: 20px;

	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 20px;
	line-height: 1.1;
	opacity: 1;
	transition:
		transform 0.35s ease,
		opacity 0.35s ease;

	// Style preset
	&.primary {
		background-color: theme.$ra-brand;
		color: theme.$ra-white;
	}

	&.danger {
		background-color: theme.$ra-error-color;
		color: theme.$ra-white;
	}

	&.secondary-danger {
		color: theme.$ra-error-color;
	}

	&.danger-ds {
		background-color: theme.$ra-error-ds;
	}

	&.grey {
		background-color: theme.$ra-gray70;
	}

	&.white {
		background-color: theme.$ra-white;
	}

	&.secondary,
	&.secondary-white,
	&.secondary-gray,
	&.secondary-danger {
		background-color: transparent;
	}

	&.secondary {
		border: 1px solid theme.$ra-brand;
	}

	&.secondary-gray,
	&.secondary-danger {
		border: 1px solid theme.$ra-gray50;
	}

	&.secondary-white {
		border: 1px solid theme.$ra-white;
	}

	// Content color
	&.dark {
		color: theme.$ra-white;

		&.empty {
			color: theme.$ra-white-dark;
		}
	}

	&.color-primary {
		color: theme.$ra-brand;
	}

	&.color-danger {
		color: theme.$ra-error-color;
	}

	&.color-danger-ds {
		color: theme.$ra-error-ds;
	}

	&.color-white {
		color: theme.$ra-white;
	}

	&.color-black-mid {
		color: theme.$ra-black-mid;
	}

	// Shape
	&.round {
		border-radius: 100px;
	}

	&.circle {
		border-radius: 50%;
	}

	// Size
	&.label-only {
		padding: 0;
	}

	&.huge {
		height: $btn-size-huge;

		&.circle {
			width: $btn-size-huge;
		}

		&:not(.label-only):not(.circle) {
			padding: 0 44px;
		}
	}

	&.large {
		height: $btn-size-large;

		&.circle {
			width: $btn-size-large;
		}

		&:not(.label-only):not(.circle) {
			padding: 0 32px;
		}
	}

	&.medium {
		height: $btn-size-medium;

		&.circle {
			width: $btn-size-medium;
		}

		&:not(.label-only):not(.circle) {
			padding: 0 28px;
		}
	}

	&.small {
		height: $btn-size-small;

		&.circle {
			width: $btn-size-small;
		}

		&:not(.label-only):not(.circle) {
			padding: 0 24px;
		}
	}

	&.tiny {
		height: $btn-size-tiny;

		&.circle {
			width: $btn-size-tiny;
		}

		&:not(.label-only):not(.circle) {
			padding: 0 16px;
		}
	}

	&.auto {
		height: auto;
	}

	// State
	&[disabled] {
		opacity: 0.4;
		pointer-events: none;
		cursor: default;
	}

	&:hover {
		opacity: 0.8;
	}
}
