@use '/src/routes/admin/styles/theme';

.with-menu {
	position: relative;
}

.label {
	width: max-content;
	user-select: none;
	cursor: pointer;

	&.active:not(.label-below) {
		position: relative;
		z-index: 10000;
	}
}

.bg-menu {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	background-color: rgba(255, 255, 255, 0.95);
}

.menu {
	position: absolute;
	z-index: 2000;
	display: flex;
	width: max-content;
	flex-direction: column;
	overflow-y: auto;
	overscroll-behavior: contain;
}

.menu-item {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.menu-item.disabled {
	pointer-events: none;
}

.menu-item.disabled:not(.busy) {
	opacity: 0.6;
}

[data-orientation='left'] .menu {
	text-align: right;

	button {
		text-align: inherit;
	}
}

[data-orientation='right'] .menu {
	text-align: left;

	button {
		text-align: inherit;
	}
}

.spacing {
	&-base {
		[data-orientation='right'] & {
			padding-right: 40px;
			padding-left: 8px;
		}

		[data-orientation='left'] & {
			padding-right: 8px;
			padding-left: 40px;
		}
	}
}

.default-label {
	position: relative;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: theme.$ra-white;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
	transition: 0.2s ease;

	&-icon {
		display: grid;
		place-content: center;
	}

	.active &,
	&:hover {
		background-color: theme.$ra-brand;

		path {
			fill: theme.$ra-white;
		}
	}
}
