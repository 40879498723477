@use 'sass:map';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

.modal {
	z-index: map.get(theme.$z-index, antd-modal);

	:global {
		.ant-modal-content {
			padding: 40px;
			border-radius: 24px;
			box-shadow:
				0 10px 40px 0 theme.$ra-black-light,
				0 10px 20px -10px theme.$ra-black-soft,
				0 2px 4px -2px theme.$ra-black-soft;
		}

		.ant-modal-header {
			padding: 0;
			margin-bottom: 24px;
			border: none;
		}

		.ant-modal-title {
			font-size: 1.6rem;
			font-weight: 700;
			color: initial;
		}

		.ant-modal-body {
			padding: 0;
		}
	}

	&-body {
		padding: 0;
		white-space: pre-line;
	}

	&-footer {
		padding: 0;
		margin-top: 40px;

		button + button {
			margin-left: 15px;
		}
	}
}

.dark {
	:global {
		.ant-modal-header {
			background-color: transparent;
		}

		.ant-modal-title {
			color: white;
		}

		.ant-modal-content {
			padding: 27px 37px;
			background-color: #4d4d4d;
			color: white;
		}
	}
}

.full-screen {
	:global {
		.ant-modal {
			top: 0;
			height: 100%;
			max-width: none;
			padding: 0;
			margin: 0;
		}

		.ant-modal-content {
			display: flex;
			height: 100%;
			padding: 0;
			flex-direction: column;
			overflow: hidden;
			border-radius: 0;
			box-shadow: none;
		}

		.ant-modal-body {
			max-height: 100%;
			flex: 1;
		}
	}
}
