@use '/src/routes/client/styles/theme';

.embed {
	display: block;
	width: 20vw;
	height: 20vw;

	[data-editor='true'] &.empty {
		display: grid;
		background: theme.$ra-black-soft;
		place-content: center;
	}

	[data-editor='true'] & * {
		pointer-events: none;
	}
}
