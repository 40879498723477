@use '/src/routes/client/styles/theme';

.pagination {
	top: 0;
	right: auto;
	bottom: auto;
	left: auto;
	width: auto;
	height: 2vw;
	// place by default at the bottom center of the slider
	align-self: end;
	justify-self: center;
	color: theme.$ra-white;

	& > div {
		width: 100%;
		height: 100%;
	}

	:global(.swiper-pagination-bullets),
	:global(.swiper-pagination-fraction) {
		font: inherit;
		color: inherit;
		gap: inherit;
		direction: inherit;
		line-height: inherit;
	}

	&:has(:global(.swiper-pagination-bullets)),
	&:has(:global(.swiper-pagination-fraction)) {
		padding: 0.3vw;
		font-size: 0.6vw;
		gap: 0.3vw;

		& > div {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			align-content: center;
		}
	}

	&:has(:global(.swiper-pagination-fraction)) {
		direction: ltr;
		font-size: 1.1vw;
		gap: 0.3em !important;

		& > div {
			flex-wrap: nowrap;
		}
	}

	&:not(.custom-bg) :global(.swiper-pagination-progressbar) {
		background: theme.$ra-black-mid;
	}

	&:has(:global(.swiper-pagination-progressbar)) {
		width: 100%;
	}

	:global(.swiper-pagination-progressbar-fill) {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: currentColor;
		transform: scale(0);
		transform-origin: left top;

		html[dir='rtl'] & {
			transform-origin: right top;
		}
	}

	:global(.swiper-pagination-horizontal) {
		flex-direction: row;
	}

	:global(.swiper-pagination-vertical.swiper-pagination-bullets),
	:global(.swiper-pagination-vertical.swiper-pagination-progressbar) {
		flex-direction: column;
	}

	:global(.swiper-pagination-bullets) :global(.swiper-pagination-bullet) {
		width: 1em;
		height: 1em;
		max-width: 100%;
		max-height: 100%;
		background: currentColor;
		border-radius: 50%;
		opacity: 0.35;
		transition: opacity 150ms ease;

		:where(html:not([data-editor='true']) &) {
			cursor: pointer;
		}

		@supports (border-radius: 10cqw) {
			border-radius: 10cqw;
		}

		&:hover {
			opacity: 0.75;
		}

		&:global(.swiper-pagination-bullet-active) {
			opacity: 1;
		}
	}

	html [data-platform='mobile'] & {
		height: 5.33vw;
		font-size: 2.67vw;
		gap: 1.6vw;
	}
}
