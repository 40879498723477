@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

$modal-width: 564px;

@value modalWidth: #{$modal-width};

.modal {
	:global {
		.ant-modal-content {
			padding: 40px;
			border-radius: 24px;
		}
	}
}
