@use '/src/routes/admin/styles/theme';

.webhook-logs {
	position: relative;
}

.title {
	margin-bottom: 24px;
}

.list {
	max-height: 400px;
	margin: 0 -37px 24px;
	overflow: auto;
	transition: opacity 0.5s;

	&.loading {
		pointer-events: none;
		opacity: 0.3;
	}
}

.list-item {
	display: flex;
	padding: 7px 35px 11px 25px;
	align-items: center;
	border-bottom: 1px solid theme.$ra-white-light;

	&-info {
		position: relative;
		display: flex;
		padding-left: 32px;
		flex-direction: column;
		flex: 1;

		&-id {
			margin-bottom: 6px;
		}

		&-date {
			color: theme.$ra-white-mid;
		}
	}

	&-icon {
		position: absolute;
		top: 0;
		left: 8px;
		display: flex;
		width: 14px;
		height: 14px;
		justify-content: center;
		align-items: center;

		&.success {
			polygon {
				fill: theme.$ra-brand;
			}
		}

		&.fail {
			path,
			polygon {
				fill: theme.$ra-error-ds;
			}
		}
	}

	&-btn {
		display: flex;
		width: 24px;
		height: 24px;
		justify-content: center;
		align-items: center;
		gap: 2px;
		cursor: pointer;

		i {
			display: block;
			width: 3px;
			height: 3px;
			border-radius: 50%;
			background-color: theme.$ra-white;
		}
	}

	&.list-view {
		.list-item-info {
			cursor: pointer;
		}
	}

	&.details-view {
		.list-item-info {
			margin-left: 12px;
			pointer-events: none;
		}
	}

	&.with-blink {
		.list-item-info {
			animation: blinking 2.25s 1 linear;
		}
	}

	@keyframes blinking {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}
}

.back-btn {
	display: flex;
	margin-bottom: 24px;
	align-items: center;
	gap: 5px;
	opacity: 0.7;
	transition: opacity 0.2s;

	&:hover {
		opacity: 1;
	}
}

.details-header {
	margin: 0 -37px 24px;
}

.details-switch {
	margin-bottom: 24px;

	&::after {
		background-color: theme.$ra-white;
	}

	&-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;

		&-text {
			color: theme.$ra-white;
			transition: color 0.2s;
		}

		&-status {
			padding: 0 5px;
			border-radius: 10px;
			font-size: 11px;

			&.success {
				background-color: theme.$ra-brand;
			}

			&.fail {
				background-color: theme.$ra-error-ds;
			}
		}

		&-active {
			.details-switch-btn-text {
				color: theme.$ra-black;
			}
		}
	}
}

.details-content {
	max-height: 400px;
	padding: 0 37px;
	margin: 0 -37px 24px;
	overflow: auto;
}

.details-subheading {
	margin-bottom: 14px;
}

.details-headers,
.details-payload {
	padding: 14px 20px;
	border: 1px solid theme.$ra-white-light;
	border-radius: 6px;
}

.details-headers {
	margin-bottom: 24px;
}

.details-headr-item {
	margin-bottom: 0;
}

.dropdown-btn {
	display: flex;
	align-items: center;
	gap: 8px;

	.redeliver-icon {
		margin: 0;
	}
}

.redeliver-icon {
	margin-right: 8px;
}

.no-data {
	text-align: center;
	color: theme.$ra-white-mid;
}
