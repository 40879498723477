@use '/src/routes/admin/styles/theme';

.thumb {
	--thumb-radius: 6px;

	height: 60px;
	padding: 8px;
	margin-bottom: 10px;
	flex-shrink: 0;
	border-radius: var(--thumb-radius);
	transition: background-color 200ms ease;
	border: 1px solid theme.$ra-black-light;
	cursor: pointer;

	&:hover,
	&.active {
		background-color: theme.$ra-black-light;
	}

	&-cover {
		flex: 1;
		border-radius: var(--thumb-radius);
		background-size: cover;
		background-color: theme.$ra-black-light;
	}

	&-check {
		transition: 200ms ease opacity;

		.thumb:not(.selected) & path:nth-child(2) {
			fill: theme.$ra-black-mid;
		}

		&:hover {
			opacity: 0.8;
		}
	}
}
