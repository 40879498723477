@use '/src/routes/admin/styles/theme';

.params-table {
	.title {
		padding: 16px 0;
	}

	&-item {
		display: grid;
		min-height: 50px;
		padding: 8px 0;
		align-items: center;
		grid-template-columns: repeat(auto-fit, minmax(0, 1fr)); // infinity columns
		grid-gap: 10px;

		& > :last-child {
			width: 20px;
			grid-column: none;
		}

		&:not(:hover) > :last-child {
			visibility: hidden;
		}

		.dark & {
			border-bottom: 1px solid rgb(60, 60, 60);
		}

		.light & {
			border-bottom: 1px solid theme.$ra-black-soft;
		}

		.text {
			word-break: break-all;
		}
	}
}
