@use '/src/routes/client/styles/theme';

.btn,
a.btn {
	width: 20vw;
	height: 10vw;
	align-items: center;
	border-radius: 0;
	color: #fff;
	background-color: theme.$ra-brand;
	font-weight: 700;
	border: 0 solid rgba(0, 0, 0, 0);
	box-shadow: 0 0 0 0 transparent;
	outline: none;
	word-break: break-word;
	text-align: center;
	transition:
		color 300ms ease,
		background-color 300ms ease;

	[data-editor='true'] &:empty {
		background: theme.$ra-black-soft;
	}

	&:disabled {
		cursor: default;
	}

	&.inactive {
		opacity: 0.45;
	}
}

.editable {
	outline: none;

	&:focus {
		outline: none;
	}
}
