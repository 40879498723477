@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/components/pages/Story/CardEditor/Inspector/PropField/vars';

.group {
	position: relative;

	&.highlight .group-header {
		background-color: theme.$ra-semantic-smart-color;
	}

	&.highlight :global(.ant-affix) {
		z-index: 99;
	}
}

.group-header,
.group-body {
	padding-right: vars.$pf-padding-x;
	padding-left: vars.$pf-padding-x;
}

.group-header {
	position: relative;
	z-index: 1;
	display: flex;
	height: 32px;
	padding-right: 12px;
	align-items: center;
	background-color: theme.$ra-white-light;
	cursor: pointer;
	color: theme.$ra-white;
	text-transform: capitalize;
	user-select: none;

	&:hover {
		background-color: theme.$ra-white-soft;
	}
}

.group-icon {
	margin-right: 18px;

	&.scroll svg {
		transform: rotate(90deg);
	}
}

.group-name {
	flex: 1;
}

.group-body {
	position: relative;
	padding-top: 8px;
	padding-bottom: 8px;

	&:empty {
		display: none;
	}
}

.component-divider {
	&:first-of-type {
		display: none;
	}

	&:last-of-type {
		display: none;
	}
}

.label-tooltip {
	&-root {
		cursor: help;
	}

	:global(.ant-popover-arrow) {
		right: 32px;
	}

	:global(.ant-popover-content) {
		padding-right: 32px;
	}

	&-content {
		color: theme.$ra-white-dark;

		path {
			fill: white;
		}
	}

	&-info {
		display: flex;
		flex-flow: column nowrap;

		.row {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.inherited {
			color: theme.$ra-danger-500;
		}
	}

	&-title {
		margin: -8px -12px 6px;
	}
}

.reset {
	padding: 7px 0 5px;
	margin-bottom: 6px;
}

.indicators {
	display: flex;
	align-items: center;

	&-a,
	&-i {
		display: block;
		width: 6px;
		height: 6px;
		border-radius: 50%;
	}

	&-i + &-a {
		margin-left: -1px;
		box-shadow: 0 0 0 1px #484848;
	}

	&-a {
		background-color: theme.$ra-brand-light;
	}

	&-i {
		background-color: theme.$ra-danger-500;
	}
}
