@use '/src/routes/client/styles/theme';
@use '/src/routes/client/styles/mixins';

.text-field {
	display: grid;
	width: 30vw;
	height: 4vw;
	overflow: visible;
	color: theme.$ra-black;
	font-size: 2vw;

	html[dir='rtl'] &.email,
	html[dir='rtl'] &.tel {
		direction: ltr;
		text-align: right;
	}
}

.field,
.field.date-picker input,
.international-phone input.field {
	width: 100%;
	height: 100%;
	padding: 0 1.5vw;
	border: none;
	box-shadow: none;
	outline: none;
	color: inherit;
	font: inherit;
	text-align: inherit;
	background: theme.$ra-black-light;

	&[readonly] {
		cursor: default;
		user-select: none;
	}
}

.field.date-picker {
	display: unset;
	padding: unset;
	margin: unset;
	font-size: unset;
	line-height: unset;
	color: unset;
	border: unset;
	border-radius: unset;
	transition: unset;
	background: unset;
	box-shadow: unset;

	& :global(.ant-picker-input) {
		display: block;
		width: 100%;
		height: 100%;
	}
}

.date-picker-popup {
	// header
	:global(.ant-picker-header-view button:hover) {
		color: theme.$ra-black-dark;
	}

	// cell
	:global(.ant-picker-cell .ant-picker-cell-inner) {
		line-height: 1.6;
	}

	// cell today
	:global(.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner) {
		font-weight: bold;

		&::before {
			border: none;
		}
	}

	// cell selected
	:global(.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner) {
		background-color: transparent;
		border: 1px solid theme.$ra-black;
		color: theme.$ra-black;
	}

	// cell selected
	:global(.ant-picker-today-btn) {
		color: theme.$ra-black;
		text-decoration: underline;

		&:hover {
			color: theme.$ra-black-dark;
		}
	}

	// rtl
	html[dir='rtl'] & {
		:global {
			.ant-picker-prev-icon,
			.ant-picker-super-prev-icon {
				transform: rotate(45deg);
			}

			.ant-picker-next-icon,
			.ant-picker-super-next-icon {
				transform: rotate(-135deg);
			}

			.ant-picker-header-view button:not(:first-child) {
				margin: 0 8px 0 0;
			}
		}
	}
}
