@use '/src/routes/admin/styles/mixins';

:global {
	$scale: 'scale';

	$scale-in: (
		opacity: 1 !important,
		transform: scale(1) !important,
	);

	$scale-out: (
		opacity: 0.01 !important,
		transform: scale(0.1) !important,
	);

	$scale-center: 'scale-center';

	$scale-center-in: (
		opacity: 1 !important,
		transform: translate(-50%, -50%) scale(1) !important,
	);

	$scale-center-out: (
		opacity: 0.01 !important,
		transform: translate(-50%, -50%) scale(0.1) !important,
	);

	$scale-center-y: 'scale-center-y';

	$scale-center-y-in: (
		opacity: 1 !important,
		transform: translate(-50%, -50%) scale(1) !important,
	);

	$scale-center-y-out: (
		opacity: 0.01 !important,
		transform: translate(-50%, -50%) scale(0.1) !important,
	);

	.fade-enter {
		opacity: 0.01;
	}

	.fade-enter.fade-enter-active {
		opacity: 1;
		transition: opacity 350ms ease;
	}

	.fade-exit {
		opacity: 1;
	}

	.fade-exit.fade-exit-active {
		opacity: 0;
		transition: opacity 300ms ease-in;
	}

	.#{$scale} {
		&-enter,
		&-exit.#{$scale}-exit-active {
			@include mixins.mapToProps($scale-out);
		}

		&-enter.#{$scale}-enter-active,
		&-exit {
			@include mixins.mapToProps($scale-in);
		}

		&-enter.#{$scale}-enter-active {
			transition:
				opacity 350ms ease,
				transform 350ms ease !important;
		}

		&-exit.#{$scale}-exit-active {
			transition:
				opacity 300ms ease,
				transform 300ms ease !important;
		}
	}

	.#{$scale-center} {
		&-enter,
		&-exit.#{$scale-center}-exit-active {
			@include mixins.mapToProps($scale-center-out);
		}

		&-enter.#{$scale-center}-enter-active,
		&-exit {
			@include mixins.mapToProps($scale-center-in);
		}

		&-enter.#{$scale-center}-enter-active {
			transition:
				opacity 350ms ease,
				transform 350ms ease !important;
		}

		&-exit.#{$scale-center}-exit-active {
			transition:
				opacity 300ms ease,
				transform 300ms ease !important;
		}
	}

	.#{$scale-center-y} {
		&-enter,
		&-exit.#{$scale-center-y}-exit-active {
			@include mixins.mapToProps($scale-center-y-out);
		}

		&-enter.#{$scale-center-y}-enter-active,
		&-exit {
			@include mixins.mapToProps($scale-center-y-in);
		}

		&-enter.#{$scale-center-y}-enter-active {
			transition:
				opacity 350ms ease,
				transform 350ms ease !important;
		}

		&-exit.#{$scale-center-y}-exit-active {
			transition:
				opacity 300ms ease,
				transform 300ms ease !important;
		}
	}
}
