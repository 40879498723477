@use 'sass:map';
@use '/src/routes/client/styles/theme';
@use '../Login';

$mobile-width: 420px;

.btn {
	@extend %login-btn;

	background-color: #646464;
	color: white;

	.icon {
		display: block;
		margin: 0 8px 0 -8px;
	}

	html:not([data-editor='true']) &:hover {
		background-color: #585757;
	}

	html[dir='rtl'] & {
		.icon {
			margin: 0 -8px 0 8px;
		}
	}

	[data-editor='true'] & {
		cursor: default;
	}

	&:disabled {
		cursor: not-allowed;
	}
}

.editable {
	outline: none;

	&:focus {
		outline: none;
	}
}

.pop-up-wrap {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: map.get(theme.$z-index, modal);
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;

	.bg {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		background-color: rgba(0, 0, 0, 0.3);
	}

	.pop-up {
		position: relative;
		z-index: 2;
		width: 499px;
		min-height: 440px;
		padding: 92px 96px;
		border-radius: 24px;
		background-color: white;

		@media (max-width: $mobile-width) {
			display: flex;
			width: 100%;
			height: 100%;
			padding: 92px 34px;
			flex-direction: column;
			justify-content: center;
			border-radius: 0;
		}

		&-title {
			margin-bottom: 13px;
			font-weight: 600;
			font-size: 24px;
			line-height: 32px;
			letter-spacing: -0.29px;
			color: rgba(0, 0, 0, 0.87);

			&.success {
				margin-bottom: 50px;
				text-align: center;
			}
		}

		&-description {
			width: 227px;
			margin-bottom: 34px;
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
			letter-spacing: -0.05px;
			color: #232323;
		}

		.phone-input-label {
			margin-bottom: 14px;
			font-size: 13px;
			font-weight: 700;
			letter-spacing: -0.05px;
		}

		.submit-btn {
			position: relative;
			display: flex;
			width: 100%;
			height: 54px;
			justify-content: center;
			align-items: center;
			color: white;
			border-radius: 56px;
			background-color: rgba(0, 0, 0, 0.87);

			&[disabled] {
				opacity: 0.5;
				pointer-events: none;
			}
		}

		.phone-input {
			margin-bottom: 34px;
			font-size: 20px;
			border-bottom: 1px solid theme.$ra-black-soft;

			:global {
				.react-international-phone-country-selector-button {
					padding-top: 2px;
				}

				.react-international-phone-country-selector-button,
				.react-international-phone-input {
					--react-international-phone-height: 45px;
					--react-international-phone-flag-width: 20px;
					--react-international-phone-flag-height: 20px;

					border-width: 0 0 1px 0;
				}
			}
		}
	}

	.close-btn {
		position: absolute;
		top: 41px;
		right: 52px;
		width: 24px;
		height: 24px;
		opacity: 0.87;

		@media (max-width: $mobile-width) {
			top: 25px;
			right: 34px;
		}

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			width: 34px;
			height: 5px;
			background-color: black;
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&::after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}
}

.verification-code-form {
	display: flex;
	width: 100%;
	margin-bottom: 38px;
	justify-content: center;
	align-items: center;
	direction: ltr;

	input {
		display: block;
		width: 40px;
		height: 40px;
		margin: 0 7px;
		font-size: 24px;
		font-weight: 700;
		border-radius: 10px;
		background-color: #f7f7f7;
		text-align: center;
		line-height: 40px;
		border: 0;

		&::selection {
			background-color: transparent;
			color: rgba(0, 0, 0, 1);
		}
	}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}

	.error & {
		input {
			display: none;
		}
	}
}

.re-send-btn {
	display: block;
	margin: 13px auto 0;
	text-align: center;
	opacity: 0.5;
	transition: opacity 0.3s;

	&:hover {
		opacity: 0.75;
	}

	.error & {
		display: none;
	}
}

.success-icon {
	$icon-weight: 8px;

	position: relative;
	width: 40px;
	height: 25px;
	margin: 30px auto 57px;
	transform: rotate(-45deg);

	&::before,
	&::after {
		content: '';
		position: absolute;
		display: block;
		background-color: black;
	}

	&::before {
		top: 0;
		left: 0;
		width: $icon-weight;
		height: 100%;
	}

	&::after {
		bottom: 0;
		left: 0;
		width: 100%;
		height: $icon-weight;
	}
}

.error-wrap {
	position: relative;
	width: 100%;
	max-height: 200px;
	margin: 10px 0;
	overflow: scroll;
}

.error {
	color: #cc3b24;
}
