@use '/src/routes/admin/styles/theme';
@use 'vars';

.pf {
	position: relative;
	color: theme.$ra-white-dark; // $ra-white;

	label:first-child {
		// height: $pf-height;
	}
}

.pf-col {
	// height: $pf-height;
}

.textarea-autosize {
	.pf-col:last-child {
		align-items: flex-start;
	}
}

[class^='control--'] ::first-letter,
button {
	.pf & {
		text-transform: capitalize;
	}
}

.pf.disabled {
	pointer-events: none;
	opacity: 0.45;
}

input,
textarea,
[class^='control--'],
button,
[data-type='box-shadow'],
:global(.ant-slider-mark-text) {
	.pf[data-inheritance='default'] & {
		color: var(--ra-color-white-mid);

		&[type='radio']::after {
			background-color: var(--ra-color-white-mid);
		}

		&[data-selector='active-icon'] {
			color: var(--ra-color-white-mid);
		}
	}

	.pf[data-inheritance='inherited'] & {
		color: var(--ra-color-inherited);

		&[type='radio']::after {
			background-color: var(--ra-color-inherited);
		}

		&[data-selector='active-icon'] {
			color: var(--ra-color-inherited);
		}
	}

	.pf[data-inheritance='assigned'] & {
		color: var(--ra-color-assigned);

		&[type='radio']::after {
			background-color: var(--ra-color-assigned);
		}

		&[data-selector='active-icon'] {
			color: var(--ra-color-assigned);
		}
	}

	.pf &:hover,
	.pf &:focus {
		color: var(--ra-color-assigned);

		&[type='radio']::after {
			background-color: var(--ra-color-assigned);
		}
	}

	.pf[data-inheritance='inherited'] [data-error='true'] &,
	.pf[data-inheritance='assigned'] [data-error='true'] &,
	.pf[data-inheritance='default'] [data-error='true'] & {
		color: var(--ra-color-error);

		&[type='radio']::after {
			background-color: var(--ra-color-error);
		}
	}

	.pf.disabled & {
		outline: none;
		filter: saturate(0%);
	}
}

.pf-col-field {
	input[type='text'],
	input[type='number'] {
		text-align: right;
	}
}

.pf-cms-mark {
	position: absolute;
	top: 0.4em;
	left: -0.8em;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background: mediumspringgreen;
	box-shadow:
		0 0 0 2px mediumaquamarine,
		0 0 0 3px black,
		0 0 0 4px white;
}
