:global(.ant-modal-content) {
	.modal & {
		padding: 40px;
		overflow: hidden;
		border-radius: 24px;
		transition: 150ms ease;
	}

	.hidden & {
		transform-origin: top center;
		transform: translateY(25vh) scale(0.93);
		visibility: hidden;
		opacity: 0;
	}
}

.steps {
	:global(.ant-steps-item-finish .ant-steps-item-icon) {
		background-color: transparent;
	}

	:global(.ant-steps-small .ant-steps-item-description) {
		font-size: 1.2rem;
	}

	:global(.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon) {
		color: var(--ra-color-white-mid);
	}
}
