@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

.form {
	position: relative;

	.check-pass-wrap {
		position: relative;
		padding: 0 0 0 30px;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 1px;
			height: 100%;
			background-color: #e6e6e6;
		}
	}

	.check-pass-title {
		padding: 0 0 12px 0;
	}

	.field {
		margin-bottom: 20px;

		&:first-child {
			margin-bottom: 40px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}
