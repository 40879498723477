@use '/src/routes/client/styles/theme';

.slides {
	top: 0 !important;
	left: 0 !important;
	align-self: stretch;
	justify-self: stretch;
}

.slides :global(.swiper-wrapper:empty) {
	background: theme.$ra-black-soft;
}

.slides:not(:global(.swiper-fade)):not(:global(.swiper-flip)) .slide * {
	will-change: transform;
}

.slide > [data-bb] {
	position: static !important;
	width: 100% !important;
	height: 100% !important;
}

:global(.swiper-fade .swiper-slide:not(.swiper-slide-active)) {
	// keep it under the active slide to avoid selection of it in CardEditor
	z-index: -1;
	opacity: 0 !important;
}
