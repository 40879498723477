@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/functions';

.is-help {
	cursor: help;
}

.dark-1 {
	--antd-arrow-background-color: #{theme.$ra-gray15};

	:global {
		.ant-popover-inner {
			background-color: var(--antd-arrow-background-color);
		}

		.ant-popover-inner-content {
			@include mixins.mapToProps(functions.map-deep-get(theme.$ra-font-size, footnote, base));

			width: 200px;
			padding: 8px 16px;
			color: theme.$ra-white;
		}
	}
}

.dark-2 {
	--antd-arrow-background-color: #{theme.$ra-gray15};

	:global {
		.ant-popover-inner {
			overflow: hidden;
			background: var(--antd-arrow-background-color);
			box-shadow:
				0 3px 6px -4px rgba(white, 0.08),
				0 6px 16px 0 rgba(white, 0.06),
				0 9px 28px 8px rgba(white, 0.02),
				0 -4px 8px 0 rgba(white, 0.02);
		}

		.ant-popover-inner-content {
			padding: 8px 12px;
			color: theme.$ra-white;
		}
	}
}

.no-animate {
	animation-duration: 1ms !important;
}
