@use '/src/routes/admin/styles/theme';

.tag {
	display: flex;
	padding: 2px 10px;
	align-items: center;
	border-radius: 12px;
	font-size: 12px;
	color: theme.$ra-black;

	&.success {
		background-color: theme.$ra-success-200;
	}

	&.primary {
		background-color: theme.$ra-primary-200;
	}

	&.danger {
		background-color: theme.$ra-danger-200;
	}

	&.error {
		background-color: theme.$ra-error-200;
	}
}
