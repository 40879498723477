:global(.ant-popconfirm).light {
	:global {
		.ant-popover-inner-content {
			padding: 12px;
		}

		.ant-btn.ant-btn-sm {
			padding: 0 16px;
			border-radius: 26px;
			line-height: 1;
			box-shadow: none;
		}
	}
}

:global(.ant-popconfirm-buttons button:last-child:first-child) {
	margin-inline-start: 0;
}
