@use '/src/routes/client/styles/theme';

.animated-number {
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	grid-auto-rows: 100%;

	& > * {
		align-self: center;
		grid-row: 1/2;
		grid-column: 1/2;
		justify-self: center;
	}

	:global {
		.slide-enter {
			opacity: 0;
			transform: translateY(-0.5em);
		}

		.slide-enter-active {
			opacity: 1;
			transform: translateY(0);
		}

		.slide-exit {
			opacity: 1;
		}

		.slide-exit-active {
			opacity: 0;
			transform: translateY(0.5em);
		}

		.slide-enter-active,
		.slide-exit-active {
			transition:
				opacity 150ms,
				transform 150ms;
			transition-timing-function: theme.$ra-slow-in-fast-out;
		}
	}
}
