@use '/src/routes/client/styles/theme';

$frame: 'FRAME';
$stack: 'STACK';
$grid: 'GRID';

@value FRAME: #{$frame};
@value STACK: #{$stack};
@value GRID: #{$grid};

.box {
	display: grid;
	width: 20vw;
	height: 20vw;
	overflow: hidden;
	grid-template-columns: unset;
	grid-template-rows: unset;
	grid-auto-rows: unset;
	grid-auto-columns: unset;

	[data-editor='true'] &:not(.custom-bg):empty {
		background: theme.$ra-black-soft;
	}

	& > * {
		position: relative;
		margin: 0;
		align-self: start;
		justify-self: center;
	}

	&[data-box-type='#{$frame}'] {
		grid-template-columns: 100% !important;
		grid-template-rows: unset !important;
		grid-auto-rows: unset !important;

		& > [data-bb] {
			grid-row: 1/2 !important;
			grid-column: 1/2 !important;
		}
	}

	&[data-box-type='#{$grid}'] {
		grid-template-rows: unset !important;

		&:not(:empty) {
			height: auto !important;
		}

		/* editable */
		// grid-auto-rows: 10vw;

		/* editable */
		// grid-template-columns: 1fr 1fr;

		& > [data-bb] {
			top: 0 !important;
			left: 0 !important;
			width: auto !important;
			height: auto !important;
			align-self: stretch !important;
			justify-self: stretch !important;
			grid-row: unset !important;
			grid-column: unset !important;
		}
	}

	&[data-box-type='#{$stack}'] {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: start;
		align-items: stretch;

		// ========================= BOX =========================
		&:not(:empty) {
			&.vertical {
				height: auto !important;
			}

			&.horizontal:not(.nowrap.stretch-items) {
				height: auto;
			}

			&.vertical:not(.stretch-items) {
				width: auto;
			}

			&.horizontal {
				width: auto;
			}

			&.horizontal.nowrap {
				width: auto !important;
			}
		}

		// ========================= CHILDREN =========================
		& > [data-bb] {
			top: 0 !important;
			left: 0 !important;
			align-self: auto !important;
			justify-self: auto !important;
		}

		// stretch by box
		&.stretch-items.vertical > [data-bb],
		// stretch self
		&.vertical > [data-stretch='x'] {
			width: auto !important; // keep is auto instead of 100% to make item margins work properly
			align-self: stretch !important;
		}

		// stretch by box
		&.stretch-items.horizontal.nowrap > [data-bb],
		// stretch self
		&.horizontal.nowrap > [data-stretch='y'] {
			height: auto !important; // keep is auto instead of 100% to make item margins work properly
			align-self: stretch !important;
		}

		&.horizontal:not(.nowrap) > [data-stretch='x'] {
			width: 100% !important;
		}
	}
}

.force-flex {
	display: flex !important;
}

.force-grid {
	display: grid !important;
}
