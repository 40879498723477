// inject variables to both — external and local helpers
@use 'theme';

// inject mixins to both — external and local helpers
@use 'mixins';

:global(.clearfix) {
	@include mixins.clearfix();
}

:global(.pull-left) {
	float: left;
}

:global(.pull-right) {
	float: right;
}

%empty-placeholder {
	$g1: linear-gradient(
		to top left,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0) calc(50% - 1px),
		rgba(black, 0.07) 50%,
		rgba(0, 0, 0, 0) calc(50% + 1px),
		rgba(0, 0, 0, 0) 100%
	);

	$g2: linear-gradient(
		to top right,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0) calc(50% - 1px),
		rgba(black, 0.07) 50%,
		rgba(0, 0, 0, 0) calc(50% + 1px),
		rgba(0, 0, 0, 0) 100%
	);

	display: grid;
	width: 100%;
	height: 100%;
	font-size: 1.4rem;
	text-align: center;
	color: rgba(black, 0.25);
	line-height: 2;
	place-content: center;
	background-color: theme.$ra-brand-white;
	background-image: $g1, $g2;

	&::after {
		content: attr(data-text);
		background-color: theme.$ra-brand-white;
		font-variant: all-small-caps;
	}

	html:not([data-editor='true']) & {
		display: none;
	}
}

:global(.cms-mark::after) {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background: mediumspringgreen;
	box-shadow:
		0 0 0 2px mediumaquamarine,
		0 0 0 3px black,
		0 0 0 4px white;
}
