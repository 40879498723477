.scrollable {
	position: relative;
	max-height: 60vh;
	overflow: hidden;

	.scrollableContent {
		position: relative;
	}

	&.mobile {
		height: 100%;
		overflow-y: scroll; /* has to be scroll, not auto */
		-webkit-overflow-scrolling: touch;

		.scrollableContent {
			height: 100%;
		}
	}

	&.no-relative {
		position: static;

		.scrollableContent {
			position: static;
		}
	}

	&.no-overflow {
		overflow: visible;
	}
}
