@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/components/pages/Story/Flow/Panel/vars';

$modal-width: 340px;

@value modalWidth: #{$modal-width};

.modal {
	position: absolute;
	top: vars.$story-flow-panel-height + 16;
	right: 20px;
	width: $modal-width;
	padding-bottom: 0;

	&:global(.ant-modal) :global(.ant-modal-content) {
		padding: 0;
	}
}

.content {
	position: relative;
	z-index: 1;
	padding: 40px 40px 16px;
	text-align: left;
}
