@use 'sass:map';
@use '/src/routes/client/styles/theme';

.overlay-container {
	// ---- fixed wrapper copy
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	pointer-events: none !important;

	& > :not([data-bb]) {
		pointer-events: none !important;
	}

	> [data-bb] {
		pointer-events: auto;
	}
	// ---- fixed wrapper copy

	// make child text nodes unselectable
	[data-editor='true'] & {
		user-select: none;
	}

	&.fixed {
		position: fixed;
	}
}

.overlay {
	width: 40vw;
	height: 40vw;
	background: theme.$ra-white;

	:where([data-editor='true'] &:empty) {
		background: theme.$ra-black-soft;
	}
}

.overlay-backdrop {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.overlay-container,
.overlay-backdrop {
	z-index: map.get(theme.$z-index, modal) !important;
}
