@use '../../../pages/Story/Story';

.float-below {
	composes: stretch;
	height: 100%;

	:global(#story) & {
		min-height: unset;
	}
}
