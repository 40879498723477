@use "sass:color";
@use 'theme';
@import '~normalize.css';

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	background: rgba(whitesmoke, 0);
}

::-webkit-scrollbar-thumb {
	border-radius: 12px;
	background: rgba(0, 0, 0, 0.25);
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html,
body {
	width: 100%;
	min-height: 100vh;
	padding: 0;
}

html {
	font-size: theme.$ra-base-font-size;

	//  Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;

	// Font variant
	-webkit-font-variant-ligatures: none;
	font-variant-ligatures: none;
	text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;

	// Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so
	// we force a non-overlapping, non-auto-hiding scrollbar to counteract.
	-ms-overflow-style: scrollbar;

	// Change the default tap highlight to be completely transparent in iOS.
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
	padding: 0;
	margin: 0;
	overflow-x: hidden;
	overflow-y: scroll;
	font-family: theme.$ra-base-font-family;
	font-size: theme.$ra-body-font-size;
	font-weight: theme.$ra-base-font-weight;
	color: theme.$ra-base-color;
	line-height: theme.$ra-base-line-height;
	background: theme.$ra-bg-body;
}

a {
	display: inline-block;
	cursor: pointer;
	text-decoration: theme.$ra-link-decoration;
	color: theme.$ra-link-color;
	transition: color 200ms ease;

	&:hover {
		color: theme.$ra-link-hover-color;
		text-decoration: theme.$ra-link-hover-decoration;
	}

	&:active {
		color: theme.$ra-link-active-color;
	}

	&[disabled] {
		color: theme.$ra-link-disabled-color;
		cursor: not-allowed;
		pointer-events: none;
	}
}

form {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

button {
	padding: 0;
	border: 0;
	border-radius: theme.$ra-base-border-radius;
	outline: none;
	background: none;
	box-shadow: none;
	line-height: theme.$ra-base-line-height;
	cursor: pointer;
	text-align: center;
	color: inherit;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: none;
}

button,
input,
optgroup,
select,
textarea {
	line-height: inherit;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
	outline: none;
}

// remove the clear button of a text input control in IE10+
input::-ms-clear,
input::-ms-reveal {
	display: none;
}

::selection {
	color: theme.$ra-white;
	background-color: color.adjust(theme.$ra-semantic-default-color, $lightness: 5%);
}

:focus-visible {
	outline: unset;
}
