// inject variables to both — external and new mixins
@use "sass:list";
@use "sass:map";
@use "sass:math";
@use "sass:meta";
@use 'theme';

// inject function to both — external and new mixins
@use 'functions';

/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */

@mixin screen($ra-size) {
	$ra-mediaQueries: (
		'mobile': theme.$ra-media-mobile,
		'mobile-portrait': theme.$ra-media-mobile-portrait,
		'mobile-landscape': theme.$ra-media-mobile-landscape,
		'tablet': theme.$ra-media-tablet,
		'tablet-portrait': theme.$ra-media-tablet-portrait,
		'tablet-landscape': theme.$ra-media-tablet-landscape,
		'desktop': theme.$ra-media-desktop,
		'non-mobile': theme.$ra-media-non-mobile,
		'non-desktop': theme.$ra-media-non-desktop,
	);

	// Use predefined media query
	@each $ra-key, $ra-value in $ra-mediaQueries {
		@if $ra-key == $ra-size {
			@media only screen and #{$ra-value} {
				@content;
			}
		}
	}

	// Use custom media query
	@if map.has-key($ra-mediaQueries, $ra-size) != true {
		@media only screen and #{$ra-size} {
			@content;
		}
	}
}

/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($ra-someMap); ==> $ra-someMap: ( font-size: 1px, line-height: 1.5 );
 */
@mixin mapToProps($ra-map) {
	@each $ra-property-name, $ra-property-value in $ra-map {
		@if (functions.is-map($ra-property-value)) {
			// ...
			// @warn "The key "#{$ra-property-name}" value is a map";
		} @else {
			#{$ra-property-name}: $ra-property-value;
		}
	}
}

@mixin retina($ra-x: 3) {
	@media only screen and (-webkit-min-device-pixel-ratio: $ra-x) {
		@content;
	}
}

/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
@mixin position($ra-position, $ra-args) {
	position: $ra-position;

	@each $ra-o in top right bottom left {
		$ra-i: list.index($ra-args, $ra-o);

		@if $ra-i and $ra-i + 1 <= list.length($ra-args) and meta.type-of(list.nth($ra-args, $ra-i + 1)) == number {
			#{$ra-o}: list.nth($ra-args, $ra-i + 1);
		}
	}
}

@mixin absolute($ra-args: '') {
	@include position(absolute, $ra-args);
}

@mixin fixed($ra-args: '') {
	@include position(fixed, $ra-args);
}

@mixin relative($ra-args: '') {
	@include position(relative, $ra-args);
}

@mixin center() {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin centerX() {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

@mixin centerY() {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

@mixin clearfix {
	&::before,
	&::after {
		content: '';
		display: table;
	}

	&::after {
		clear: both;
	}
}

@mixin nl {
	padding: 0;
	margin: 0;
	text-indent: 0;
	list-style: none outside none;
}

@mixin selection {
	& ::selection {
		@content;
	}

	& ::-moz-selection {
		@content;
	}
}

@mixin placeholder-color($ra-color) {
	&.placeholder {
		color: $ra-color;
	}

	&::-moz-placeholder {
		color: $ra-color;
	}

	&::-webkit-input-placeholder {
		color: $ra-color;
	}
}

/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
@mixin row($ra-inline: false) {
	@if $ra-inline {
		font-size: 0;
	} @else {
		@include clearfix();
	}
}

@mixin col(
	$ra-col,
	$ra-sum,
	$ra-gap: 1em,
	$ra-first: false,
	$ra-last: false,
	$ra-inline: false,
	$ra-align: top,
	$ra-fluid: false,
	$ra-float: right
) {
	width: 100%;
	padding-right: if($ra-last, 0, $ra-gap);
	padding-left: if($ra-first, 0, $ra-gap);

	@if $ra-inline {
		display: inline-block;
		font-size: $ra-gap;
	} @else {
		float: $ra-float;
	}

	@if $ra-fluid {
		width: math.percentage(math.div($ra-col, $ra-sum));
	}

	@media only screen and (min-width: 768px) {
		width: math.percentage(math.div($ra-col, $ra-sum));
		vertical-align: if($ra-inline, $ra-align, initial);
	}
}

@mixin textEllipsis() {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin darkModeInvertion($element) {
	:global(.#{$element}) & {
		filter: invert(1);
	}
}
