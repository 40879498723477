@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.member-avatar {
	position: relative;
	display: flex;
	width: 48px;
	height: 48px;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: theme.$ra-success-600;

	@include mixins.screen('non-desktop') {
		width: 40px;
		height: 40px;
	}
}

.img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	border-radius: inherit;
}

.user-name {
	color: white;
	text-transform: uppercase;
}
