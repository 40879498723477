@use 'sass:map';
@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/functions';
@use '../PropField/vars';

.states {
	padding-top: 6px;
	color: theme.$ra-white-dark;
}

.btn {
	@include mixins.mapToProps(
		map.merge(
			functions.map-deep-get(theme.$ra-font-size, label, base),
			functions.map-deep-get(theme.$ra-font-size, label, compact)
		)
	);

	position: relative;
	display: block;
	height: 3.2rem;
	padding-left: 3rem;
	line-height: 1;
	transition: color 200ms ease;

	&:hover {
		color: theme.$ra-white-dark;
	}
}

.btn-icon {
	position: absolute;
	top: 9px;
	left: 3px;
}

.note {
	padding: 8px 0 16px;
	color: theme.$ra-white-mid;
}
