@use '/src/routes/admin/styles/theme';

.title {
	padding: 0 60px 24px 0;
}

.error {
	padding: 10px 0;
	color: theme.$ra-error-color;
}
