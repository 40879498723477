@use '/src/routes/admin/components/pages/Story/CardEditor/Inspector/PropField/vars';

.divider {
	position: relative;
	height: 1px;
	margin: vars.$pf-divider-y -#{vars.$pf-padding-x};
	background-color: vars.$pf-divider-color;
}

:empty + .divider {
	display: none;
}
