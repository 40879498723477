@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.disabled {
	cursor: not-allowed;
	opacity: 0.2;

	a {
		pointer-events: none;
	}
}

.nav {
	flex: 1;

	&-link {
		margin-right: 18px;
		color: theme.$ra-black;
		text-transform: capitalize;

		div {
			line-height: 1.125;
		}

		&:hover {
			opacity: 0.4;
		}
	}

	&-menu {
		padding: 16px 0;
	}

	.nav-link-active {
		color: theme.$ra-brand;

		&:hover {
			opacity: 1;
		}
	}
}

.menu-btn {
	position: relative;
	display: flex;
	width: 48px;
	height: 48px;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.87);
	transition: background-color 0.35s;

	&-icon {
		position: relative;
		width: 40%;

		&::before,
		&::after {
			content: '';
			position: relative;
			display: block;
			height: 2px;
			background-color: white;
			transition: background-color 0.35s;
		}

		&::before {
			margin-bottom: 5px;
		}
	}

	&:hover {
		background-color: theme.$ra-brand;
	}
}
