@use '/src/routes/client/styles/theme';

%login-btn {
	display: flex;
	width: 274px;
	height: 48px;
	padding: 0 15px;
	align-items: center;
	text-align: left;
	outline: none;
	word-break: break-word;
	font-size: 16px;
	box-shadow:
		0 2px 14px 2px theme.$ra-black-light,
		0 2px 14px 2px theme.$ra-black-light;
	font-family: sans-serif;
	font-weight: 500;
	letter-spacing: 0.21px;
	border-radius: 2px;
	transition: background-color 200ms ease;
	user-select: none;
	appearance: none;
	cursor: pointer;
}
