@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

$modal-width: 800px;

@value modalWidth: #{$modal-width};

.modal {
	--ra-modal-bg-color: #333;

	position: absolute;
	top: 0;
	left: 0;
	width: $modal-width;
	height: 100vh;
	padding-bottom: 0;
	overflow: hidden;

	:global {
		.ant-modal-content {
			height: 100%;
			padding: 0;
			border-radius: 0;
			background-color: var(--ra-modal-bg-color);
		}

		.ant-tabs-bar {
			margin-bottom: 32px;
		}

		.ant-modal-body {
			display: flex;
			height: 100%;
			flex-flow: row nowrap;
		}
	}
}

.header {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	display: flex;
	width: 100%;
	flex-flow: row nowrap;
	background-color: #333;

	.col {
		color: #fff;

		.name {
			color: theme.$ra-white-dark;
		}

		&:first-child {
			width: 240px;
			padding: 16px 0 0 24px;
		}

		&:last-child {
			padding: 16px 0 10px 48px;
			flex: 1;
			border-left: 1px solid theme.$ra-white-soft;
		}
	}
}

.menu {
	position: relative;
	z-index: 1;
	width: 240px;
	padding: 0 0 16px 24px;
	margin-top: 46px;

	.list {
		padding: 80px 0 0 0;
	}

	&-item {
		display: block;
		margin: 0 0 4px 0;
		color: theme.$ra-white-dark;
		transition: color 0.2s ease-in-out;

		&:hover,
		&.active {
			color: theme.$ra-brand;
		}
	}
}

.buttons {
	display: flex;
	padding: 8px 0;
	flex-flow: row nowrap;
	background-color: var(--ra-modal-bg-color);
}

.content {
	position: relative;
	z-index: 1;
	height: calc(100% - 45px);
	padding: 80px 48px 16px;
	margin-top: 45px;
	flex: 1;
	overflow-y: auto;
	border-left: 1px solid theme.$ra-white-soft;
	color: theme.$ra-white;
}

.superuser-section {
	display: grid;
	padding: 8px 12px;
	outline: 1px solid var(--ra-color-inherited);
	border-radius: 6px;
	gap: 16px;

	:nth-child(n) {
		margin-bottom: 0;
	}
}
