@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.root {
	padding-bottom: 8px;
}

.toggle {
	width: 260px;
}
