@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.slider {
	position: relative;
	height: 16px;
	border-radius: 8px;
	background-position:
		0 0,
		0 0,
		50% 50%;
	background-size:
		100% 100%,
		auto,
		auto;

	&-inner {
		position: absolute;
		right: 8px;
		left: 8px;
		height: 100%;
	}
}

.slider-handler {
	position: absolute;
	top: 0;
	left: 50%;
	width: 1px;
	height: 16px;
	transform: translate(-50%, 0);
	cursor: grab;

	&:is(:active, :focus) {
		cursor: grabbing;
	}

	&-inner {
		position: absolute;
		top: 50%;
		left: 50%;
		box-sizing: border-box;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		border: 2px solid white;
		transform: translate(-50%, -50%);
	}
}
