@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

$modal-width: 740px;

@value modalWidth: #{$modal-width};

.modal {
	:global {
		.ant-modal-content {
			padding: 40px;
			border-radius: 24px;
		}
	}
}

.symbol-field-prop {
	margin: 0 0 24px 0;
}

.field {
	&-prop {
		label {
			margin-bottom: 8px;
		}

		textarea {
			width: 100%;
			min-height: 200px;
			color: theme.$ra-black;
			resize: none;
		}
	}
}

.hint {
	margin-top: 16px;
	color: theme.$ra-white-mid;
}
