@use '/src/routes/admin/styles/theme';
@use 'PageLayoutVars.scss';

$aside-width: 33vw;
$margin-left: 24px;
$margin-right: 24px;

.page-layout {
	// padding: $top $right $bottom $left;
	position: relative;
	display: grid;
	min-height: 100vh;
	margin-right: $margin-right;
	margin-left: $margin-left;
	grid-template-areas: 'header header' 'aside content';
	grid-auto-flow: row;
	grid-column-gap: 16px;
	grid-row-gap: 0;
	grid-template-columns: minmax(280px, 1fr) 6fr;
	grid-template-rows: 0 1fr;
}

.aside {
	position: sticky;
	top: 0;
	z-index: 3;
	display: grid;
	width: 100%;
	height: 100vh;
	padding-top: PageLayoutVars.$top;
	grid-area: aside;
	grid-template-rows: auto 1fr auto;
	grid-row-gap: 16px;
}

.header {
	position: fixed;
	top: 0;
	right: $margin-right;
	left: $margin-left;
	z-index: 2;
	display: grid;
	padding-top: PageLayoutVars.$top;
	grid-auto-flow: row;
	grid-column-gap: 16px;
	grid-row-gap: 0;
	grid-template-columns: minmax(280px, 1fr) 6fr;
	grid-template-rows: auto;

	&::before {
		content: '';
		position: absolute;
		top: -2px;
		right: -$margin-right;
		bottom: 0;
		left: -$margin-left;
		display: block;
		background-color: white;

		@supports (backdrop-filter: blur(10px)) {
			background-color: rgba(255, 255, 255, 0.5);
			backdrop-filter: blur(10px);
		}
	}

	&::after {
		content: '';
		position: absolute;
		top: -3px;
		right: -$margin-right;
		left: -$margin-left;
		height: 1px;
		background-color: white;
	}

	&.empty {
		display: none;
	}
}

.sticky-header {
	position: sticky;
	top: 0;
	z-index: 2;
	display: grid;
	width: 100%;
	height: PageLayoutVars.$sticky-header-height;
	grid-area: header;
	grid-auto-flow: row;
	grid-column-gap: 16px;
	grid-row-gap: 0;
	grid-template-columns: minmax(280px, 1fr) 6fr;
	grid-template-rows: auto;
	transform: translate(0, -60px);
	transition: transform 0.35s;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: -(PageLayoutVars.$right);
		bottom: 0;
		left: -(PageLayoutVars.$left);
		display: block;
		background-color: rgba(255, 255, 255, 0.85);
		box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.12);
	}

	&-inner {
		position: relative;
		height: PageLayoutVars.$sticky-header-height;
		// padding-top: $top;
	}

	&.open {
		transform: translate(0, 0);
	}
}

.content {
	position: relative;
	grid-area: content;
}
