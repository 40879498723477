@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

$modal-width: 994px;
$texFieldMarginBottom: 24px;
$formViewWidth: 620px;

/* stylelint-disable */
:export {
	modalWidth: #{$modal-width};
}
/* stylelint-enable */

.modal {
	:global {
		.ant-modal-content {
			padding: 50px 35px 35px 65px;
			border-radius: 24px;
		}
	}
}

.title {
	display: flex;
	align-items: center;

	span {
		padding-left: 24px;
	}
}

.current-view {
	max-width: 620px;
}

.head {
	padding: 0 0 40px 0;
}

.search {
	margin: 0 0 26px 0;
}

.action-btn {
	margin: 0 20px 0 0;
}

.google-font-list {
	height: 100%;
	overflow-y: auto;
}

.upload-custom-font {
	margin: 0 0 12px 0;
}

.body {
	display: grid;
	height: 55vh;
	grid-template-rows: auto auto 1fr;

	&.custom-fonts {
		grid-template-rows: 1fr;
	}
}

.footer {
	button {
		margin-right: 16px;
	}
}
