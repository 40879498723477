@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.selector {
	margin-bottom: 8px;
}

.url-params-settings {
	padding: 16px 0 20px;

	.label {
		margin-bottom: 13px;
	}

	.params {
		border: 1px solid theme.$ra-white-soft;
		border-radius: 6px;
	}

	.param-input-wrap {
		display: flex;
		height: 60px;
		padding: 0 18px 0 13px;
		flex-direction: column;
		justify-content: center;

		&:not(:last-child) {
			border-bottom: 1px solid theme.$ra-white-soft;
		}
	}
}

.hint i {
	margin-right: 4px;
	vertical-align: sub;
}
