@use '/src/routes/admin/styles/mixins';

.preview {
	position: relative;
	width: 20px;
	height: 20px;
	overflow: hidden;
	border-radius: 50%;
	border: 1px solid white;

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	&::before {
		@extend %chess-pattern;

		opacity: 0.1;
	}

	&::after {
		border-radius: inherit;
		background-color: inherit;
	}

	&.empty {
		&::before {
			opacity: 0.8;
		}

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 2;
			width: 100%;
			height: 2px;
			transform: translate(-50%, -50%) rotate(-45deg);
			background-color: red;
		}
	}
}
