@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.modal {
	right: 20px;
}

.section {
	padding-bottom: 21px;

	&.top-border {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: -21px;
			left: -40px;
			width: calc(100% + 80px); // padding-left + padding-right = 40 + 40 = 80px
			height: 1px;
			background-color: theme.$ra-white-light;
		}
	}

	&:last-child {
		padding: 11px 0 0;
		text-align: center;

		.info {
			padding-bottom: 13px;
		}
	}
}

.title {
	padding-bottom: 22px;
}

.info {
	position: relative;
	padding-bottom: 16px;

	&:last-child {
		padding-bottom: 40px;
	}

	.name {
		color: theme.$ra-white-dark;
	}

	.tooltip {
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 3px 10px;
		color: theme.$ra-white;
		background-color: theme.$ra-black;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		border-radius: 6px;
		transform: translate(-50%, calc(-50% + 6px));
		transition:
			opacity 0.25s ease,
			visibilty 0.25s ease,
			transform 0.25s ease;

		&.active {
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
			transform: translate(-50%, -50%);
		}
	}

	.val {
		color: theme.$ra-white;

		&-inner {
			position: relative;
			display: flex;
			justify-content: space-between;

			&.embed {
				transition: opacity 0.25s ease;

				&:hover {
					cursor: pointer;
					opacity: 0.8;
				}
			}

			&.val-inner-domain-link {
				margin-top: 10px;
			}
		}

		a {
			color: inherit;
			transition: opacity 0.25s ease;
			word-break: break-all;

			&:hover {
				opacity: 0.8;
			}
		}

		i {
			padding-top: 4px;
			padding-left: 15px;
		}
	}
}

.btn {
	width: 100%;
	margin: 0 0 17px 0;

	&:last-child {
		margin: 0;
	}
}
