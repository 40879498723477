@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.fields {
	margin: 0 0 24px 0;
}

.form {
	padding: 0 0 24px 0;
}

.check-pass {
	padding: 0 0 42px 0;
}

.name-field-input {
	margin: 0 0 24px 0;
}

.info {
	p {
		padding-right: 40px;
		font-size: 11px;
		line-height: 16px;
		color: theme.$ra-black-dark;

		a {
			color: inherit;
			text-decoration: underline;
		}
	}
}

.hero {
	width: 100%;
	max-width: 487px;

	&-title {
		margin-bottom: 24px;
	}

	&-error {
		position: relative;
		padding: 0 0 24px 0;
		color: theme.$ra-error-color;
	}

	.error-icon {
		position: absolute;
		top: 0;
		left: -25px;
		width: 20px;
		height: 20px;
		text-align: center;
		line-height: 19px;
		font-size: 12px;
		font-weight: 600;
		transform: translate(-4px, -2px);
		border-radius: 50%;
		background-color: theme.$ra-error-color;
		color: white;

		@include mixins.screen('non-desktop') {
			display: none;
		}
	}
}

.password-field {
	position: relative;

	.show-password-btn {
		position: absolute;
		top: 44px;
		right: 16px;
		font-size: 13px;
		color: theme.$ra-black-dark;
	}
}
