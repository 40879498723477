@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

$modal-width: 800px;

@value modalWidth: #{$modal-width};

.modal {
	max-width: 85vw;

	:global {
		.ant-modal-content {
			height: 770px;
			max-height: 80vh;
			border-radius: 24px;
		}

		.ant-modal-body {
			height: 100%;
		}
	}
}

form {
	padding-right: 16px;
	padding-left: 16px;
	margin: 0 -16px;
}

.card-type {
	padding: 6px 16px 6px;
	margin-left: 16px;
	border-radius: 50px;
	color: theme.$ra-white-dark;
	box-shadow: 0 0 0 1px theme.$ra-white-soft;
	pointer-events: none;
}

.footer {
	position: absolute;
	right: 10px;
	bottom: 0;
	left: 0;
	padding-top: 10px;
	background-color: #4d4d4d;
}

.button {
	margin: 0 16px 0 0;
}

.field {
	margin-bottom: 24px;
}

.min-max-col {
	display: grid;
	grid-template-columns: repeat(2, 90px);
	grid-column-gap: 16px;
}

.score-all {
	padding-top: 38px;
}

.show-correct,
.show-results {
	.hint {
		max-width: 280px;
	}
}

.share-msg-hint {
	white-space: break-spaces;
}

.count-type {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	grid-gap: 16px;

	:global(.select__control) {
		width: 100%;
	}
}

.slider {
	width: 335px;

	:global {
		.ant-slider-mark-text {
			transform: translateY(8px) !important;
		}

		.ant-slider-mark-text:last-child {
			right: 0;
			left: auto !important;
		}
	}
}
