@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

$modal-width: 800px;

@value modalWidth: #{$modal-width};

.modal {
	max-width: 85vw;

	:global {
		.ant-modal-content {
			height: 770px;
			max-height: 80vh;
			border-radius: 24px;
		}

		.ant-modal-body {
			height: 100%;
		}
	}
}

.inner {
	display: flex;
	height: 100%;
}

.grid {
	width: 100%;
}

.col-1 {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		top: -27px;
		right: 0;
		bottom: -27px;
		width: 1px;
		background-color: theme.$ra-white-light;
	}
}

.col-2 {
	position: relative;
	margin-right: -16px;
	margin-left: 0;
	overflow: hidden;
}

.col-2,
.content {
	padding-right: 16px;
	padding-left: 16px;
	margin: 0 -16px;
}

.header {
	display: flex;
	min-height: 50px;
	padding-bottom: 14px;
	align-items: center;
}

.content {
	height: calc(100% - 50px); // 50px header height
	padding-top: 32px;
	padding-bottom: 90px;
	overflow-x: hidden;
	overflow-y: auto;
}

.menu {
	position: relative;
	padding-top: 80px;

	&-item {
		display: block;
		margin: 0 0 4px 0;
		color: theme.$ra-white-dark;
		transition: color 0.2s ease-in-out;

		&:hover,
		&.active {
			color: theme.$ra-brand;
		}

		&:disabled {
			opacity: 0.7;
		}
	}
}

.footer {
	position: absolute;
	right: 10px;
	bottom: 0;
	left: 0;
	z-index: 10;
	padding-top: 10px;
	background-color: #4d4d4d;
}
