@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.fields {
	margin: 0 0 24px 0;
}

.form {
	padding: 0 0 24px 0;
}

.email-field-input {
	margin: 0 0 24px 0;
}

.link {
	color: theme.$ra-brand;
	opacity: 1;
	transition: opacity 0.2s ease-in;

	&.disabled,
	&:hover {
		color: theme.$ra-brand;
		opacity: 0.8;
	}
}

.buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.success-message-content {
	width: 100%;
	max-width: 400px;

	.title {
		padding-right: 65px;
		margin-bottom: 24px;
		line-height: 1.3;
		color: theme.$ra-black;
	}

	.sub-title {
		padding: 0 30px 40px 0;
		line-height: 1.3;
		color: theme.$ra-black-dark;
	}
}

.hero {
	width: 100%;
	max-width: 427px;

	&-title {
		margin-bottom: 24px;
	}

	&-sub-title {
		padding: 0 30px 24px 0;
		color: theme.$ra-black-dark;
	}

	&-error {
		position: relative;
		padding: 0 20px 24px 0;
		color: theme.$ra-error-color;
	}

	.error-icon {
		position: absolute;
		top: 0;
		left: -25px;
		width: 20px;
		height: 20px;
		text-align: center;
		line-height: 19px;
		font-size: 12px;
		font-weight: 600;
		transform: translate(-4px, -2px);
		border-radius: 50%;
		background-color: theme.$ra-error-color;
		color: white;

		@include mixins.screen('non-desktop') {
			display: none;
		}
	}
}

.password-field {
	position: relative;

	.show-password-btn {
		position: absolute;
		top: 44px;
		right: 16px;
		font-size: 13px;
		color: theme.$ra-black-dark;
	}
}
