@use '/src/routes/admin/styles/theme';

.aside {
	&-label {
		color: theme.$ra-black-mid;
	}
}

.section {
	margin-bottom: 40px;

	&-title {
		margin-bottom: 16px;
	}

	&-field {
		margin-bottom: 24px;
	}
}

.title {
	padding-bottom: 24px;
	color: theme.$ra-white;
}

.domains {
	position: relative;
	padding-top: 18px;
}

.domain {
	position: relative;
	display: flex;
	min-height: 63px;
	padding: 10px 0 10px 30px;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	color: inherit;

	&:hover {
		color: theme.$ra-brand-light;
	}

	&-url {
		margin-bottom: 2px;
	}

	&-type {
		color: theme.$ra-white-mid;
	}

	&-btn {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translate(0, -50%);
	}

	&-lock-icon {
		position: absolute;
		top: 50%;
		right: 15px;
		width: 20px;
		height: 13px;
		transform: translate(0, -50%);
		opacity: 0.75;

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			display: block;
			width: 10px;
			height: 8px;
			border: 1px solid white;
			border-radius: 1.5px;
			transform: translate(-50%, 0);
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			display: block;
			width: 6px;
			height: 5px;
			border: 1px solid white;
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;
			border-bottom: none;
			transform: translate(-50%, 0);
		}
	}

	&-add-icon {
		position: absolute;
		top: 50%;
		left: 0;
		z-index: 2;
		width: 20px;
		height: 20px;
		transform: translate(0, -50%);
		opacity: 0.75;
		transition: opacity 0.2s;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			width: 1px;
			height: 12px;
			background-color: currentColor;
			transform: translate(-50%, -50%);
			pointer-events: none;
		}

		&::after {
			transform: translate(-50%, -50%) rotate(90deg);
		}

		&:hover {
			opacity: 1;
		}
	}

	&:not(:last-of-type) {
		border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
	}

	&.disabled {
		pointer-events: visiblePainted;
		cursor: not-allowed;

		.domain-url,
		.domain-type {
			opacity: 0.5;
		}

		&:hover {
			color: inherit;
		}
	}
}

.tooltip-link {
	margin-left: 5px;
	color: theme.$ra-brand;
}

.disabled {
	pointer-events: none;
}

.lang {
	display: grid;
	padding: 6px 10px;
	align-items: center;
	border-radius: 6px;
	background-color: rgba(255, 255, 255, 0.05);
	grid-template-columns: 1fr 1fr;
	grid-gap: 2px;
}
