.root {
	width: 800px;
	max-width: 100%;
	padding: 20px 30px;
	margin: 0 auto;
	font-family: monospace;
	font-size: 1.4rem;
	letter-spacing: -0.3px;
	direction: ltr;

	summary {
		user-select: none;
	}
}

.hero {
	display: grid;
	grid-template-columns: 1fr auto auto;
	grid-column-gap: 16px;
}

.btn {
	position: relative;
	z-index: 1;
	display: inline-block;
	height: 32px;
	padding: 8px 16px;
	font-size: 1.2rem;
	text-transform: uppercase;
	text-decoration: none;
	color: #fff;
	letter-spacing: 0.1em;
	background: #3e3e3d;
	transition: 0.25s ease-in-out;
	white-space: nowrap;

	&::before,
	&::after {
		content: attr(data-text);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		padding: inherit;
		transform-origin: 0 50%;
		opacity: 0;
	}

	&-report {
		background: #5f5f5d;
	}

	&:hover,
	&:focus {
		color: rgba(255, 255, 255, 0.9);

		&::after {
			animation: jumpAfter 0.3s ease-in-out 0s infinite normal;
			color: blue;
			opacity: 1;
		}

		&::before {
			animation: jumpBefore 0.2s ease-in-out 0s infinite normal;
			color: red;
			opacity: 1;
		}
	}
}

@keyframes jumpAfter {
	0% {
		transform: translate3d(-2px, 0, 0);
	}

	20% {
		transform: translate3d(-2px, 0, 0);
	}

	40% {
		transform: translate3d(-2px, 0, 0);
	}

	50% {
		transform: translate3d(-3px, 0, 0);
	}

	60% {
		transform: translate3d(-2px, 0, 0);
	}

	80% {
		transform: translate3d(-2px, 0, 0);
	}

	100% {
		transform: translate3d(-3px, 0, 0);
	}
}

@keyframes jumpBefore {
	0% {
		transform: translate3d(2px, 0, 0);
	}

	20% {
		transform: translate3d(-2px, -2px, 0);
	}

	40% {
		transform: translate3d(2px, 0, 0);
	}

	50% {
		transform: translate3d(-2px, 0, 0);
	}

	60% {
		transform: translate3d(2px, 0, 0);
	}

	80% {
		transform: translate3d(2px, 0, 0);
	}

	100% {
		transform: translate3d(2px, 0, 0);
	}
}
