@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.google-fonts {
	position: relative;

	.title {
		padding-bottom: 24px;
		color: theme.$ra-white;
	}

	.selected {
		margin: 17px 0 20px;
		border-radius: 6px;
		border: 1px solid #808080;

		.variant {
			position: relative;
			display: flex;
			width: 100%;
			height: 60px;
			padding: 0 12px;
			align-items: center;

			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: theme.$ra-white-soft;
			}

			.indicator {
				position: absolute;
				top: 50%;
				right: 18px;
				width: 16px;
				height: 16px;
				transform: translate(0, -50%);
				border-radius: 50%;
				border: 1px solid theme.$ra-white;
				transition:
					border 0.25s ease,
					background-color 0.25s ease;

				&::after {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					width: 7px;
					height: 4px;
					border-left: 2px solid transparent;
					border-bottom: 2px solid transparent;
					transform: translate(-50%, -50%) rotate(-45deg);
					transition: border-color 0.25s ease;
				}
			}

			&.active {
				.indicator {
					background-color: theme.$ra-brand;
					border: 1px solid theme.$ra-brand-light;

					&::after {
						border-left-color: theme.$ra-brand-light;
						border-bottom-color: theme.$ra-brand-light;
					}
				}
			}

			&:last-child {
				&::after {
					display: none;
				}
			}
		}
	}

	.select-wrap {
		position: relative;

		label {
			display: block;
			padding: 0 0 7px;
		}

		select {
			// Stack above custom arrow
			z-index: 1;
			width: 100%;
			padding: 0 10px 0 0;
			margin: 0;
			// A reset of styles, including removing the default dropdown arrow
			appearance: none;
			background-color: transparent;
			border: none;
			font-family: inherit;
			font-size: inherit;
			color: theme.$ra-white;
			cursor: inherit;
			line-height: inherit;

			// Remove focus outline, will add on alternate element
			outline: none;

			// Remove dropdown arrow in IE10 & IE11
			// @link https://www.filamentgroup.com/lab/select-css.html
			&::-ms-expand {
				display: none;
			}

			option:not(:checked) {
				background-color: rgba(0, 0, 0, 0.4);
			}
		}

		.select {
			position: relative;
			display: grid;
			width: 100%;
			height: 48px;
			padding: 0 18px 0 12px;
			align-items: center;
			grid-template-areas: 'select';
			border: 1px solid #808080;
			border-radius: 6px;
			font-size: 16px;
			cursor: pointer;
			line-height: 1.1;

			// Optional styles
			// remove for transparency
			background-color: transparent;

			// Custom arrow
			&::after {
				content: '';
				width: 8px;
				height: 4px;
				justify-self: end;
				background-color: #808080;
				clip-path: polygon(100% 0%, 0 0%, 50% 100%);
			}

			select,
			&::after {
				grid-area: select;
			}
		}
	}
}
