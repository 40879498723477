@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

$modal-width: 564px;

@value modalWidth: #{$modal-width};

.modal {
	:global {
		.ant-modal-content {
			padding: 40px 64px 40px 40px;
			border-radius: 24px;
		}
	}
}

.head {
	padding: 0 0 44px 0;
}

.body {
	padding: 0 0 48px 0;
}
