// NOTE: Import this file to your application entry point.
// Common
@use 'sass:color';
@use 'sass:map';
@use 'theme';
@use 'mixins';
@use 'helpers';
@use 'fonts';
@use 'base';
@use 'functions';

:root {
	--ra-color-brand: #{theme.$ra-brand};
	--ra-color-brand-light: #{theme.$ra-brand-light};
	--ra-color-brand-dark: #{theme.$ra-brand-dark};
	--ra-color-black: #{theme.$ra-black};
	--ra-color-black-dark: #{theme.$ra-black-dark};
	--ra-color-black-mid: #{theme.$ra-black-mid};
	--ra-color-black-20: #{theme.$ra-black-20};
	--ra-color-black-soft: #{theme.$ra-black-soft};
	--ra-color-black-light: #{theme.$ra-black-light};
	--ra-color-gray-80: #{theme.$ra-gray80};
	--ra-color-white: #{theme.$ra-white};
	--ra-color-white-dark: #{theme.$ra-white-dark};
	--ra-color-white-mid: #{theme.$ra-white-mid};
	--ra-color-white-soft: #{theme.$ra-white-soft};
	--ra-color-white-light: #{theme.$ra-white-light};
	--ra-color-error: #{theme.$ra-error-color};
	--ra-color-error-ds: #{theme.$ra-error-ds};
	--ra-color-primary-400: #{theme.$ra-primary-400};

	// editor
	--ra-color-assigned: #{theme.$ra-brand-light};
	--ra-color-inherited: #{theme.$ra-danger-500};
}

:global {
	html {
		&.no-scroll {
			height: 100%;

			body {
				height: 100%;
				overflow: hidden;
			}
		}
	}

	// ------------------ Ant.design
	.ant {
		$ant-prefix: &;

		&-table {
			&-placeholder {
				padding: 10px 0;
				text-align: center;
				background: none;
				font-size: inherit;
				color: theme.$ra-black-soft;
				border: none;
			}
		}

		&-tooltip {
			z-index: map.get(theme.$z-index, antd-tooltip);
		}

		&-popover {
			z-index: map.get(theme.$z-index, ant-popover);

			.ant-popover-inner {
				padding: 0;
			}
		}

		&-carousel {
			&,
			& * {
				outline: none;
			}
		}

		&-slider {
			&-vertical {
				&::before,
				&::after {
					@include mixins.centerX();

					color: theme.$ra-black-mid;
					font-size: 2.2rem;
					pointer-events: none;
				}

				&::before {
					content: '+';
					top: -33px;
				}

				&::after {
					content: '–';
					bottom: -33px;
				}

				#{$ant-prefix}-slider-handle {
					width: 16px;
					height: 16px;
					margin-left: -6px;
					background-color: theme.$ra-brand;
					box-shadow:
						0 1px 1px 0 rgba(0, 0, 0, 0.15),
						0 2px 5px 0 rgba(0, 0, 0, 0.07);
					border: none;
					transition: background-color 0.3s ease;
				}

				&:hover #{$ant-prefix}-slider-handle {
					background-color: theme.$ra-brand-light;
				}
			}

			&-horizontal {
				height: 14px;

				.ant-slider-rail,
				.ant-slider-track,
				.ant-slider-step {
					height: 6px;
				}

				.ant-slider-rail {
					background-color: rgba(white, 0.18);
				}

				.ant-slider-dot {
					visibility: hidden;
				}

				.ant-slider-handle {
					inset-block-start: 2px;
				}

				.ant-slider-mark-text {
					@include mixins.mapToProps(functions.map-deep-get(theme.$ra-font-size, label, base));

					color: theme.$ra-white-mid;
					white-space: nowrap;
				}

				.ant-slider-mark {
					top: 14px;
				}

				.ant-slider-track {
					background-color: theme.$ra-brand-light;
				}

				&:hover {
					.ant-slider-track {
						background-color: theme.$ra-brand;
					}

					.ant-slider-rail {
						background-color: rgba(white, 0.25);
					}
				}

				.ant-slider-handle::after,
				.ant-slider-handle:focus::after {
					box-shadow:
						inset 0 0 0 0 theme.$ra-brand-light,
						0 0 0 2px theme.$ra-brand-light;
				}

				.ant-slider-handle:hover::after,
				.ant-slider-handle:active::after {
					box-shadow:
						inset 0 0 0 2px theme.$ra-brand,
						0 0 0 2px theme.$ra-brand;
				}
			}
		}

		&-calendar-range-left {
			float: right;
		}

		&-message {
			color: white;

			.ant-message-notice .ant-message-notice-content {
				padding: 0;
				background: transparent;
				box-shadow: none;
				border-radius: 0;

				@include mixins.screen('mobile') {
					padding: 0;
				}
			}

			&-custom-content {
				margin-top: -8px;

				.anticon {
					display: none;
				}

				.anticon + span {
					display: block;
				}
			}
		}

		&-modal {
			$modal-padding-y: 32px;
			$modal-padding-x: 0;

			padding-bottom: $modal-padding-y;

			&,
			&-wrap {
				text-align: inherit;
				direction: inherit;
			}

			&-mask {
				background: color.adjust(#f7f7f7, $alpha: -0.1);
			}

			&-root &-centered {
				display: flex;
			}

			&-root &-centered .ant-modal {
				padding: $modal-padding-y 0;
				margin: auto;
			}

			[tabindex='-1']:has(> .ant-modal-content) {
				height: 100%;
			}
		}

		&-progress {
			margin: 0;

			&-outer {
				width: 100% !important;
			}
		}
	}
}
