@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/functions';

.loader {
	@include mixins.absolute(top 0 left 0 right 0 bottom 0);

	z-index: 2;
	display: grid;
	place-content: center;

	&.story {
		background: var(--ra-color-white-dark)
			linear-gradient(
				to bottom,
				var(--ra-color-white-dark),
				var(--ra-color-white-dark) 60px,
				white 60px,
				white 100%
			);
	}

	&.editor {
		z-index: functions.map-deep-get(theme.$z-index, ant-popover);
		backdrop-filter: blur(4px);
	}
}

.loader-body {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	gap: 12px;

	.editor & {
		gap: 8px;
	}
}

.ai-status {
	-webkit-mask-size: 200%;
	animation: shine 2s linear infinite;

	.story & {
		-webkit-mask-image: linear-gradient(-75deg, black 30%, rgba(0, 0, 0, 0.38) 50%, black 70%);
	}

	.editor & {
		-webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.5) 10%, black 50%, rgba(0, 0, 0, 0.5) 90%);
	}
}

@keyframes shine {
	from {
		-webkit-mask-position: 150%;
	}

	to {
		-webkit-mask-position: -50%;
	}
}
