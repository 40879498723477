@use '/src/routes/client/styles/theme';

.counter {
	--unit-padding-top: 0;

	position: relative;
	display: flex;
	flex-wrap: nowrap;
	direction: ltr;
	line-height: 1;
	font-size: 5vw;
}

.unit {
	text-align: center;
}

.digits {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: flex-start;
}

.digit {
	position: relative;
	overflow: hidden;
	line-height: inherit;

	&-value,
	&-value-next {
		position: absolute;
		top: 0;
		left: 0;
		transform: translate3d(0, 0, 0);
	}

	&-value-bounding-box {
		visibility: hidden;
	}

	&.transition_swipeDown {
		.digit-value-next {
			transform: translate3d(0, -100%, 0);
		}

		&.transition-active {
			.digit-value,
			.digit-value-next {
				transition: transform 0.4s cubic-bezier(0, 0, 0.4, 1.35);
			}

			.digit-value {
				transform: translate3d(0, 100%, 0);
			}

			.digit-value-next {
				transform: translate3d(0, 0, 0);
			}
		}
	}

	&.transition_swipeUp {
		.digit-value-next {
			transform: translate3d(0, 100%, 0);
		}

		&.transition-active {
			.digit-value,
			.digit-value-next {
				transition: transform 0.4s cubic-bezier(0, 0, 0.4, 1.35);
			}

			.digit-value {
				transform: translate3d(0, -100%, 0);
			}

			.digit-value-next {
				transform: translate3d(0, 0, 0);
			}
		}
	}

	&.transition_fade {
		.digit-value-next {
			opacity: 0;
		}

		&.transition-active {
			.digit-value,
			.digit-value-next {
				transition: opacity 0.4s linear;
			}

			.digit-value {
				opacity: 0;
			}

			.digit-value-next {
				opacity: 1;
			}
		}
	}
}

.label {
	font-size: 1.2vw;
}

.divider {
	display: flex;
	width: 1.5vw;
	padding-top: var(--unit-padding-top);
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}
