@use '/src/routes/client/styles/theme';
@use '../Login';

.google-login {
	[data-editor='true'] & {
		cursor: default;
	}
}

// TODO: refactor styles to SCSS style & group html:not([data-editor="true"])
:global {
	.abcRioButtonIconImage {
		width: 20px;
		height: 20px;
	}

	.abcRioButton {
		@extend %login-btn;

		background-color: #fff;
		color: rgba(0, 0, 0, 0.54);
	}

	html:not([data-editor='true']) .abcRioButton:hover {
		background-color: #eee;
	}

	.abcRioButtonBlue {
		border: none;
		color: #fff;
	}

	.abcRioButtonBlue,
	html:not([data-editor='true']) .abcRioButtonBlue:hover {
		background-color: #4285f4;
	}

	html:not([data-editor='true']) .abcRioButtonBlue:active {
		background-color: #3367d6;
	}

	.abcRioButtonLightBlue {
		background-color: #fff;
		color: #757575;
	}

	html:not([data-editor='true']) .abcRioButtonLightBlue:active {
		background-color: #eee;
		color: #6d6d6d;
	}

	.abcRioButtonIcon {
		display: block;
		padding: 15px 0;
		margin-right: 12px;

		html[dir='rtl'] & {
			margin-right: 0;
			margin-left: 12px;
		}
	}

	.abcRioButtonBlue .abcRioButtonIcon {
		background-color: #fff;
		border-radius: 1px;
	}

	.abcRioButtonSvg {
		display: block;
	}

	.abcRioButtonContents {
		font-size: 16px;
		line-height: 48px;
		font-family: sans-serif;
		font-weight: 500;
		letter-spacing: 0.21px;
		vertical-align: top;
	}

	.abcRioButtonContentWrapper {
		display: flex;
		width: 100%;
		height: 100%;
	}

	.abcRioButtonBlue .abcRioButtonContentWrapper {
		border: 1px solid transparent;
	}

	.abcRioButtonErrorWrapper,
	.abcRioButtonWorkingWrapper {
		display: none;
		width: 100%;
		height: 100%;
	}

	.abcRioButtonErrorIcon,
	.abcRioButtonWorkingIcon {
		margin-right: auto;
		margin-left: auto;
	}

	.abcRioButtonErrorState,
	.abcRioButtonWorkingState {
		border: 1px solid rgba(0, 0, 0, 0.17);
		box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
		color: #262626;
	}

	html:not([data-editor='true']) .abcRioButtonErrorState:hover,
	html:not([data-editor='true']) .abcRioButtonWorkingState:hover {
		border: 1px solid rgba(0, 0, 0, 0.25);
		box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
	}

	html:not([data-editor='true']) .abcRioButtonErrorState:active,
	html:not([data-editor='true']) .abcRioButtonWorkingState:active {
		border: 1px solid rgba(0, 0, 0, 0.25);
		box-shadow: inset 0 1px 0 #ddd;
		color: #262626;
	}

	.abcRioButtonWorkingState,
	html:not([data-editor='true']) .abcRioButtonWorkingState:hover {
		background-color: #f5f5f5;
	}

	html:not([data-editor='true']) .abcRioButtonWorkingState:active {
		background-color: #e5e5e5;
	}

	.abcRioButtonErrorState,
	html:not([data-editor='true']) .abcRioButtonErrorState:hover {
		background-color: #fff;
	}

	html:not([data-editor='true']) .abcRioButtonErrorState:active {
		background-color: #e5e5e5;
	}

	.abcRioButtonErrorState .abcRioButtonErrorWrapper,
	.abcRioButtonWorkingState .abcRioButtonWorkingWrapper {
		display: block;
	}

	.abcRioButtonErrorState .abcRioButtonContentWrapper,
	.abcRioButtonErrorState .abcRioButtonWorkingWrapper,
	.abcRioButtonWorkingState .abcRioButtonContentWrapper {
		display: none;
	}
}
