@use '/src/routes/admin/styles/theme';

@value duration: 3s;

.message-content {
	position: relative;
	display: flex;
	width: 900px;
	max-width: calc(100vw - 300px);
	padding: 10px 40px 10px 0;
	justify-content: center;
	align-items: center;
	box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.07);
	border-radius: 0 0 10px 10px;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 4px;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 0;
		height: 4px;
		animation-name: animateWidth;
		animation-timing-function: linear;
		animation-duration: inherit;
		animation-fill-mode: forwards;
	}

	i {
		margin: 0 10px;
	}

	&.info {
		background-color: theme.$ra-primary-color;

		&::before {
			background-color: theme.$ra-white-mid;
		}

		&::after {
			background-color: theme.$ra-primary-A200;
		}
	}

	&.success {
		background-color: theme.$ra-brand-light;

		&::before {
			background-color: theme.$ra-brand-dark;
		}

		&::after {
			background-color: theme.$ra-brand;
		}
	}

	&.warn {
		background-color: theme.$ra-danger-color;

		&::before {
			background-color: theme.$ra-danger-A700;
		}

		&::after {
			background-color: theme.$ra-danger-A200;
		}
	}

	&.error {
		background-color: theme.$ra-error-color;

		&::before {
			background-color: theme.$ra-error-A700;
		}

		&::after {
			background-color: theme.$ra-error-A200;
		}
	}
}

.body {
	display: -webkit-box;
	overflow: hidden;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}

.close {
	position: absolute;
	right: 2px;
}

@keyframes animateWidth {
	to {
		width: 100%;
	}
}
