@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.upload-avatar {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	&.is-busy {
		.avatar-preview {
			&::after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

.avatar-preview {
	position: relative;
	width: 120px;
	height: 120px;
	margin-bottom: 17px;
	border-radius: 50%;
	background-color: theme.$ra-black-light;
	box-shadow: inset 0 0 0 1px theme.$ra-black-soft;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0;
		visibility: hidden;
		background-color: theme.$ra-black-dark;
		pointer-events: none;
		border-radius: inherit;
		transition:
			opacity 1s ease,
			visibilty 1s ease;
	}
}

.preloader {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;
	width: 20px;
	height: 20px;
	transform: translate(-50%, -50%);
	animation-name: preloader-animation;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;

	circle {
		stroke: theme.$ra-brand;
		stroke-width: 4px;
		stroke-dasharray: 45, 45;
		stroke-dashoffset: 0;
	}
}

.input-wrap {
	label {
		display: block;
		cursor: pointer;
		color: theme.$ra-brand;
		transition: opacity 0.25s ease;

		&:hover {
			opacity: 0.8;
		}
	}

	input {
		display: none;
	}
}

@keyframes preloader-animation {
	0% {
		transform: translate3d(-50%, -50%, 0) scale(1.5) rotate(0deg);
	}

	100% {
		transform: translate3d(-50%, -50%, 0) scale(1.5) rotate(360deg);
	}
}
