@use '/src/routes/admin/styles/theme';

.updates {
	ul {
		padding: 0;
		margin-top: 32px;
		margin-bottom: 89px;
		list-style-type: none;
	}

	li:not(:last-child) {
		margin-bottom: 40px;
	}

	p {
		white-space: break-spaces;

		&:first-child {
			margin-bottom: 5px;
			color: white;
		}

		&:last-child {
			color: theme.$ra-white-dark;
		}
	}
}

.actions {
	display: flex;

	button:first-child {
		margin-right: 20px;
	}
}
