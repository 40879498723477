@use '/src/routes/client/styles/theme';

.selection-toolbar {
	position: absolute;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 3px;
	direction: ltr !important;

	&.top {
		bottom: 100%;
		flex-direction: column-reverse;
	}

	&.bottom {
		top: 100%;
	}

	&.top-inset {
		top: 0;
	}
}

.actions {
	display: flex;
	overflow: hidden;
	pointer-events: auto;
	color: theme.$ra-white;
	direction: ltr;
	background-color: theme.$ra-gray;
	backdrop-filter: saturate(200%) blur(6px);

	.top & {
		border-radius: 3px 3px 0 0;
	}

	.bottom & {
		border-radius: 0 0 3px 3px;
	}

	&:empty {
		display: none;
	}

	button {
		display: grid;
		height: 24px;
		min-width: 32px;
		padding: 0 6px;
		color: currentColor;
		place-content: center;
		transition: opacity 200ms ease;
		border-radius: 0;
		font-size: 1.4rem;
		letter-spacing: 0.025rem;
		line-height: 1;

		&:first-child,
		&:last-child {
			width: 32px;
		}

		&:first-child {
			padding-left: 8px;
		}

		&:last-child {
			padding-right: 8px;
		}

		&:hover {
			background-color: theme.$ra-white-light;
		}

		&[data-color='brand'] {
			background-color: theme.$ra-brand;
			color: theme.$ra-white;

			&:hover {
				background-color: theme.$ra-brand-light;
			}
		}

		&:disabled {
			opacity: 0.5;
		}

		svg {
			display: block;
		}
	}
}
