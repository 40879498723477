@use '/src/routes/client/styles/theme';

.btn {
	display: flex;
	width: 40px;
	height: 40px;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	border-radius: 0;
	color: #fff;
	background-color: theme.$ra-black;
	border: 0 solid rgba(0, 0, 0, 0);
	box-shadow: 0 0 0 0 transparent;
	outline: none;
	text-align: center;

	[data-editor='true'] &:empty {
		background: theme.$ra-black-soft;
	}

	&.transparent {
		background-color: transparent;
	}
}

.btn-icon {
	width: 40px;
	height: 40px;
	max-width: 100%;
	max-height: 100%;

	svg {
		display: block;
		width: 100%;
		height: 100%;
	}

	path {
		fill: currentColor;
	}

	& + .btn-content {
		margin-left: 8px;
	}
}

.btn-content {
	&:empty {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		color: transparent;
	}
}

.editable,
.editable .btn-content {
	&,
	&:focus {
		outline: none;
	}
}
