@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

.uploaded-fonts-list {
	display: grid;
	height: 100%;
	overflow: hidden;
	grid-template-rows: 1fr auto;
}

.list {
	height: 100%;
	overflow-y: auto;

	.errors {
		margin-bottom: 8px;
	}
}

.footer {
	padding-top: 10px;
}

.action-btn {
	margin-left: 20px;
}

.item {
	padding: 13px 16px 14px 28px;
	margin-bottom: 10px;
	border: 1px solid theme.$ra-black-soft;

	&-header {
		display: flex;
		margin-bottom: 5px;
		align-items: center;
	}
}

.field {
	position: relative;
	display: flex;
	margin-left: 16px;
	flex-flow: row-reverse;

	&,
	input {
		font-weight: 600;
		font-size: 22px;
	}

	input {
		width: 400px;
		padding: 0;
		background: none;
		border: none;
		outline: none;
		color: inherit;

		&:focus + span {
			display: none;
		}
	}

	& > span {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		width: calc(100% + 22px);
		flex-wrap: nowrap;
		pointer-events: none;
		color: transparent;

		span {
			overflow: hidden;
			text-overflow: ellipsis;
		}

		i {
			position: relative;
			top: 3px;
			left: 6px;
		}
	}
}
