@use '/src/routes/client/styles/theme';
@use '/src/routes/client/styles/mixins';

$timer-track-fill: theme.$ra-black-soft;
$timer-progress-fill: theme.$ra-brand;

.timer {
	display: flex;
	overflow: visible;
	white-space: nowrap;
	font-size: 1.3vw;
	line-height: 1 !important;
	font-weight: normal;
	text-align: center;
	color: $timer-progress-fill;

	&-num {
		width: auto !important;
		height: auto !important;
	}

	&-num,
	&-circ {
		justify-content: center;
		align-items: center;
	}

	&-bar {
		width: 50vw;
		border-radius: 2vw;
	}

	&-circ {
		width: 4vw;
		height: 4vw;

		circle {
			stroke-width: inherit;
		}
	}
}

.num-text,
.bar-text {
	display: flex;
	direction: ltr;
}

.bar {
	display: flex;
	width: 100%;
	height: 100%;
	flex-flow: row nowrap;

	&,
	&-track {
		border-radius: inherit;
	}

	&-track {
		flex: 1;
		overflow: hidden;
		background-color: $timer-track-fill;
	}

	&-progress {
		width: 100%;
		height: 100%;
		background-color: currentColor;
		transform-origin: left;
	}

	&-text {
		width: 3.5em;
		justify-content: center;
		align-items: center;
		direction: ltr;
		text-align: center;
	}
}

.circ {
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	grid-auto-rows: 100%;

	&,
	svg {
		width: 100%;
		height: 100%;
	}

	svg {
		transform: rotate(-90deg);
	}

	svg,
	&-text {
		align-self: center;
		grid-row: 1/2;
		grid-column: 1/2;
		justify-self: center;
	}

	&-track,
	&-progress {
		stroke-width: 6;
	}

	&-track {
		fill: none;
		stroke: $timer-track-fill;
	}

	&-progress {
		fill: none;
		stroke: $timer-progress-fill;
		stroke-dasharray: 101 101;
		stroke-dashoffset: 101;
		transition: stroke-dashoffset 10ms linear;
	}
}

.animate {
	.circ,
	.bar-progress,
	.num-text {
		animation-duration: 1000ms;
		animation-iteration-count: infinite;
		animation-timing-function: theme.$ra-slow-in-fast-out;
	}

	.circ {
		animation-name: pulse;
	}

	.bar-progress,
	.num-text {
		animation-name: flash;
	}
}

@keyframes pulse {
	50%,
	80% {
		transform: scale3d(1, 1, 1);
	}

	65% {
		transform: scale3d(0.97, 0.97, 0.97);
	}
}

@keyframes flash {
	50%,
	100% {
		opacity: 1;
	}

	75% {
		opacity: 0.4;
	}
}
