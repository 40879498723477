@use '/src/routes/admin/styles/theme';

.alert {
	font-size: 12px;
	color: theme.$ra-black-dark;

	&.success {
		background-color: theme.$ra-success-100;
		border-color: theme.$ra-success-400;
	}

	&.primary {
		background-color: theme.$ra-primary-100;
		border-color: theme.$ra-primary-400;
	}

	&.danger {
		background-color: theme.$ra-danger-100;
		border-color: theme.$ra-danger-400;
	}

	&.error {
		background-color: theme.$ra-error-100;
		border-color: theme.$ra-error-400;
	}
}
