@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/functions';

.template-group {
	padding-bottom: 20px;

	&-title {
		padding-bottom: 22px;
	}

	&:last-child {
		padding-bottom: 31.5vh;
	}
}

.template-list {
	display: grid;
	grid-auto-columns: 1fr;
	grid-column-gap: 1.5vw;
	grid-row-gap: 0;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: auto;
}

.card-thumbnails {
	display: flex;
	height: 100%;
	flex-direction: column;

	& > :first-child {
		margin-bottom: 40px;
	}
}

.similar-templates {
	padding-right: 6px;
	margin-right: -12px;
	flex: 1;
	overflow: scroll;

	& > :first-child {
		margin-bottom: 16px;
	}
}

.loader {
	@include mixins.absolute(top 0 left 0 right 0 bottom 0);

	z-index: 2;
	display: grid;
	place-content: center;
	background-color: theme.$ra-white-dark;
}

.filters {
	margin-bottom: 16px;
	flex: 1;
	overflow: auto;
}

.filters-group {
	&.active {
		.filters-group-title {
			color: theme.$ra-brand;

			&::after {
				transform: translate(0, -50%) rotate(45deg);
				border-right: 1px solid theme.$ra-brand;
				border-bottom: 1px solid theme.$ra-brand;
			}
		}
	}

	&.always-active {
		.filters-group-title {
			color: theme.$ra-black;
			pointer-events: none;

			&::after {
				display: none;
			}
		}

		.filters-group-item:hover {
			color: theme.$ra-black;
		}

		.filters-group-item.active {
			color: theme.$ra-brand;
		}
	}
}

.filters-group-title {
	position: relative;
	cursor: pointer;
	text-transform: capitalize;
	transition: color 0.25s ease;

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		right: 2px;
		width: 6px;
		height: 6px;
		border-right: 1px solid theme.$ra-black;
		border-bottom: 1px solid theme.$ra-black;
		transform: translate(0, -50%) rotate(-45deg);
		transition: transform 0.25s ease;
	}

	&:hover {
		color: theme.$ra-brand;
	}
}

.filters-group-item {
	position: relative;
	display: block;
	width: 100%;
	margin-top: 8px;
	font-size: functions.map-deep-get(theme.$ra-font-size, label, base, font-size);
	color: theme.$ra-black-mid;
	transition: color 0.25s ease;
	text-align: left;

	&:disabled {
		pointer-events: none;
		opacity: 0.45;
		cursor: not-allowed;

		&.active,
		&:hover {
			color: theme.$ra-black-mid;
		}
	}

	&:first-child {
		margin-top: 10px;
	}

	&:last-child {
		margin-bottom: 10px;
	}

	&.active,
	&:hover {
		color: theme.$ra-black;
	}
}
