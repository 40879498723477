.pf-slider {
	position: relative;
	width: 100%;
}

:global(.ant-slider) {
	:where(.pf-slider) & {
		margin: 0;
	}

	.with-mark & {
		margin: 0;
	}
}
