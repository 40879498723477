@use '../../../pages/Story/Story';

.fade {
	&-base {
		> * {
			opacity: 0;
			visibility: hidden;
		}
	}

	&-in {
		transition:
			opacity 0.5s,
			visibility 0.5s;

		> * {
			opacity: 1;
			visibility: visible;
			transition: inherit;
		}
	}

	&-out {
		transition:
			opacity 0.5s,
			visibility 0.5s;

		> * {
			opacity: 0;
			visibility: hidden;
			transition: inherit;
		}
	}
}

.zoom {
	&-base {
		> * {
			opacity: 0;
			visibility: hidden;
			transform: scale(1.1);
		}
	}

	&-in {
		transition:
			opacity 0.5s,
			visibility 0.5s,
			transform 0.5s;

		> * {
			opacity: 1;
			visibility: visible;
			transform: scale(1);
			transition: inherit;
		}
	}

	&-out {
		transition:
			opacity 0.5s,
			visibility 0.5s,
			transform 0.5s;

		> * {
			opacity: 0;
			visibility: hidden;
			transform: scale(1.1);
			transition: inherit;
		}
	}
}

.float-above {
	composes: stretch;
	height: 100%;

	:global(#story) & {
		min-height: unset;
	}
}
