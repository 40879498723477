// inject variables to both — external and local helpers
@use 'theme';

// inject mixins to both — external and local helpers
@use 'mixins';

:global(.clearfix) {
	@include mixins.clearfix();
}

:global(.pull-left) {
	float: left;
}

:global(.pull-right) {
	float: right;
}

:global(.justify-end) {
	justify-content: flex-end;
}

:global(.row-reverse) {
	flex-direction: row-reverse;
}

:global(.invisible) {
	opacity: 0;
	visibility: hidden;
}

:global(.text-ellipsis) {
	@include mixins.textEllipsis();
}
