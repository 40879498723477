@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.integration-item {
	position: relative;
	display: grid;
	width: 100%;
	height: 84px;
	padding: 0 16px 0 26px;
	margin-bottom: 14px;
	align-items: center;
	box-shadow: inset 0 0 0 1px theme.$ra-black-soft;
	border-radius: 6px;
	grid-template-columns: 32px 1fr 32px;
	grid-column-gap: 20px;
	text-align: left;
	transition: box-shadow 0.2s ease-in;

	&.with-menu {
		grid-template-columns: 32px 1fr 48px;

		&:hover {
			box-shadow: inset 0 0 0 1px theme.$ra-black-soft;
		}
	}

	&.active {
		pointer-events: none;
	}

	.status {
		svg {
			path,
			polygon {
				fill: theme.$ra-black;
				transition: fill 0.2s ease-in;
			}
		}
	}

	&:hover {
		box-shadow: inset 0 0 0 1px theme.$ra-brand;

		.status {
			svg {
				path,
				polygon {
					fill: theme.$ra-brand;
				}
			}
		}
	}
}
