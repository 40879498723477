@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.video-upload-progress {
	position: relative;
	display: flex;
	width: 100%;
	height: 209px;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	border-radius: 6px;
	box-shadow: inset 0 0 0 1px gray;
	background-color: theme.$ra-white-extra-light;

	&.light {
		box-shadow: inset 0 0 0 1px #ccc;
		background-color: theme.$ra-black-light;

		.percentage,
		.progress-title {
			color: theme.$ra-black-dark;
		}

		.progress-bar-bg {
			background-color: theme.$ra-black-soft;
		}
	}
}

.progress-title,
.round-progress {
	margin-bottom: 8px;
}

.line-progress {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 6px;
}

.progress-bar-bg {
	width: 100%;
	height: 100%;
	background-color: theme.$ra-white-soft;
}

.progress-bar {
	height: 100%;
	transform-origin: 0 0;
	background-color: theme.$ra-brand;
	transition: transform 0.2s ease-in;
}

.percentage {
	position: absolute;
	right: 11px;
	bottom: calc(100% + 11px);
}
