@use '/src/routes/client/styles/theme';

[data-editor='true'] .story {
	user-select: none;
}

.stretch,
.stretch[data-bb] {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	min-height: 100vh;
}

.loader {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	display: grid;
	width: 100%;
	height: 100%;
	background: white;
	place-content: center;
}

.not-found-btn {
	padding: 6px 22px;
	background-color: theme.$ra-brand;
	border-radius: 20px;
	transition: 200ms ease;

	&,
	&:hover {
		color: white;
	}

	&:hover {
		background-color: theme.$ra-brand-light;
	}
}
