@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

$integration-item-height: 58px;

.intro {
	position: relative;
}

.add-integration {
	margin-bottom: 54px;
	border-radius: 6px;
	border: 1px solid #808080;
}

.no-items {
	padding-top: 20px;
	text-align: center;
	color: theme.$ra-white-mid;
}

.list {
	height: calc(#{$integration-item-height} * 4.5);
	overflow-y: auto;

	&-item {
		position: relative;
		display: flex;
		width: 100%;
		height: 58px;
		padding: 0 12px;
		align-items: center;
		border-bottom: 1px solid rgb(60, 60, 60);
		border-radius: 0;
		text-align: left;
		transition: background-color 0.2s ease-in;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 24px;
			width: 8px;
			height: 8px;
			transform: translate(0, -50%) rotate(-45deg);
			border-bottom: 2px solid theme.$ra-white;
			border-right: 2px solid theme.$ra-white;
		}

		& > i {
			display: flex;
			width: 24px;
			justify-content: center;
			align-items: center;
		}

		.text {
			padding-left: 10px;
		}

		&:hover {
			background-color: rgba(255, 255, 255, 0.06);
		}

		&:last-child {
			border-bottom: none;
		}
	}
}

.my-integrations {
	.title {
		padding: 20px 0;
	}

	.edit-btn {
		position: absolute;
		top: 50%;
		right: 24px;
		transform: translate(0, -50%);
	}

	.list {
		&-item {
			&::after {
				display: none;
			}

			&:hover {
				background-color: initial;
			}

			&:last-child {
				border-bottom: 1px solid rgb(60, 60, 60);
			}
		}
	}
}

.search {
	position: relative;
	height: 44px;
	padding: 0 12px;
	border-bottom: 1px solid rgb(60, 60, 60);

	input {
		width: 100%;
		height: 100%;
		background-color: transparent;
		border: none;
		outline: none;
		color: theme.$ra-white;
		font-size: 1.6rem;
	}

	.search-icon {
		position: absolute;
		top: 50%;
		right: 12px;
		transform: translate(0, -50%);
	}
}
