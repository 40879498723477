@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/functions';

@each $key1, $value1 in theme.$ra-font-size {
	// key1 = hero
	// value1 = map(base, compact, bold)

	@each $key2, $value2 in $value1 {
		// key2 = base
		// value2 = map of css props

		$combined: $key1 + '.' + $key2;
		$selector: if($key2 == 'base', $key1, $combined);

		.#{$selector} {
			$map: functions.map-deep-get(theme.$ra-font-size, $key1, $key2);

			@include mixins.mapToProps($map);
		}
	}
}

$font-weight: lighter, normal, bold, bolder;

@each $prop in $font-weight {
	.#{$prop} {
		font-weight: $prop;
	}
}

.semibold {
	font-weight: 600;
}

.medium {
	font-weight: 500;
}

$font-style: italic, normal;

@each $prop in $font-style {
	.#{$prop} {
		font-style: $prop;
	}
}

$text-transform: normal, uppercase, lowercase, capitalize;

@each $prop in $text-transform {
	.#{$prop} {
		text-transform: $prop;
	}
}

.color {
	&-white {
		color: theme.$ra-white;
	}

	&-white-dark {
		color: theme.$ra-white-dark;
	}

	&-white-mid {
		color: theme.$ra-white-mid;
	}

	&-white-soft {
		color: theme.$ra-white-soft;
	}

	&-white-light {
		color: theme.$ra-white-light;
	}

	&-black {
		color: theme.$ra-black;
	}

	&-black-dark {
		color: theme.$ra-black-dark;
	}

	&-black-mid {
		color: theme.$ra-black-mid;
	}

	&-black-soft {
		color: theme.$ra-black-soft;
	}

	&-black-light {
		color: theme.$ra-black-light;
	}

	&-brand {
		color: theme.$ra-brand;
	}

	&-brand-dark {
		color: theme.$ra-brand-dark;
	}

	&-brand-light {
		color: theme.$ra-brand-light;
	}
}
