$scrollbar-radius: 6px;
$scrollbar-track-color: transparent;
$scrollbar-handle-color: hsla(0, 0%, 100%, 0.15);
$scrollbar-handle-color--dark-mode: hsla(0, 0%, 0%, 0.15);

.scrollBar {
	position: absolute;
	top: 10px;
	right: 5px;
	bottom: 10px;
	z-index: 1;
	width: 8px;
	opacity: 1;
	visibility: visible;
	transition: opacity 0.35s, visibility 0.35s;
	user-select: none;

	&.hide {
		opacity: 0;
	}
}

.scrollTrack {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	border-radius: $scrollbar-radius;
	background-color: $scrollbar-track-color;
}

.scrollHandle {
	position: absolute;
	top: 0;
	right: 0;
	width: calc(100% - 2px);
	height: 100%;
	border-radius: $scrollbar-radius;
	background-color: $scrollbar-handle-color;
	border: 1px solid $scrollbar-track-color;
	cursor: pointer;

	.dark-mode & {
		background-color: $scrollbar-handle-color--dark-mode;
	}
}
