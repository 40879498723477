@use 'sass:map';
@use '/src/routes/admin/styles/theme.scss';
@use '/src/routes/admin/styles/mixins.scss';
@use '/src/routes/admin/components/pages/Story/Flow/vars';

.connector-svg {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: map.get(theme.$z-index, connector-svg);
	margin: 0 auto;
	background-color: rgba(255, 255, 255, 0);
	pointer-events: none;

	svg {
		display: block;
		width: 100%;
		height: 100%;
	}

	&.active {
		z-index: map.get(theme.$z-index, connector-svg--active);
		pointer-events: auto;
	}

	&.active-card {
		z-index: map.get(theme.$z-index, connector-svg--active);
		pointer-events: none;
	}
}

.connection {
	position: relative;

	path {
		cursor: pointer;
		transition:
			stroke-width 0.1s,
			opacity 0.1s ease;
		opacity: 0;

		&:hover {
			stroke-width: 6;
		}
	}

	// HIDE POINTS
	.g-p1,
	.g-p2 {
		opacity: 0;
		transition: opacity 0.1s ease;
	}

	&.highlight,
	&.connecting {
		.g-p1,
		.g-p2 {
			opacity: 1;
		}

		path {
			opacity: 1;
		}
	}

	.card-dragging & {
		.g-p1,
		.g-p2,
		path {
			opacity: 0;
			transition: none;
		}
	}

	// END HIDE POINTS

	&.show-start {
		path {
			stroke: green;
		}

		.p2 {
			visibility: hidden;
		}
	}

	&.show-end {
		path {
			stroke: tomato;
		}

		.p1 {
			visibility: hidden;
		}
	}

	&.show-start.show-end {
		.p1,
		.p2,
		path {
			visibility: hidden;
		}
	}

	circle {
		position: relative;
		z-index: 1;

		&:hover {
			cursor: move;
		}
	}

	line {
		stroke-width: 1;
		stroke: silver;
		stroke-linecap: round;
		stroke-dasharray: 5, 3;
	}

	text {
		display: none;
		pointer-events: none;
	}

	&.connected,
	&.connecting {
		.g-p1 circle {
			fill: theme.$ra-success-500;
		}

		.g-p2 circle {
			fill: theme.$ra-success-500;
		}
	}

	.curve-debug {
		display: none;
	}
}
