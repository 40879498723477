:global {
	.gsi-material-button {
		position: relative;
		width: auto;
		height: 40px;
		max-width: 400px;
		min-width: min-content;
		padding: 0 12px;
		overflow: hidden;
		background-color: WHITE;
		background-image: none;
		border: 1px solid #747775;
		border-radius: 20px;
		color: #1f1f1f;
		cursor: pointer;
		font-family: sans-serif;
		font-size: 14px;
		letter-spacing: 0.25px;
		outline: none;
		text-align: center;
		transition:
			background-color 0.218s,
			border-color 0.218s,
			box-shadow 0.218s;
		vertical-align: middle;
		white-space: nowrap;
	}

	.gsi-material-button .gsi-material-button-icon {
		width: 20px;
		height: 20px;
		min-width: 20px;
		margin-right: 12px;
	}

	.gsi-material-button .gsi-material-button-content-wrapper {
		position: relative;
		display: flex;
		width: 100%;
		height: 100%;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
	}

	.gsi-material-button .gsi-material-button-contents {
		flex-grow: 1;
		overflow: hidden;
		font-family: sans-serif;
		font-weight: 500;
		text-overflow: ellipsis;
		vertical-align: top;
	}

	.gsi-material-button .gsi-material-button-state {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: opacity 0.218s;
		opacity: 0;
	}

	.gsi-material-button:disabled {
		cursor: default;
		background-color: #ffffff61;
		border-color: #1f1f1f1f;
	}

	.gsi-material-button:disabled .gsi-material-button-contents {
		opacity: 38%;
	}

	.gsi-material-button:disabled .gsi-material-button-icon {
		opacity: 38%;
	}

	.gsi-material-button:not(:disabled):active .gsi-material-button-state,
	.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
		background-color: #303030;
		opacity: 12%;
	}

	.gsi-material-button:not(:disabled):hover {
		box-shadow:
			0 1px 2px 0 rgba(60, 64, 67, 0.3),
			0 1px 3px 1px rgba(60, 64, 67, 0.15);
	}

	.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
		background-color: #303030;
		opacity: 8%;
	}
}
