@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

.body {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.body-grid {
	width: 100%;
}
