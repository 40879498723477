@use '/src/routes/admin/styles/theme';

.back-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	transition: opacity 0.35s;

	span {
		display: block;
		width: 8px;
		height: 8px;
		margin: 0 16px 0 0;
		border-bottom: 2px solid theme.$ra-white-dark;
		border-left: 2px solid theme.$ra-white-dark;
		transform: rotate(45deg);
	}

	&:hover {
		opacity: 0.7;
	}
}
