@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

.step-title {
	margin-bottom: 20px;
	color: theme.$ra-black-dark;
}

.qr-code {
	width: 150px;
	height: 150px;
	margin: 0 0 20px 15px;

	svg {
		width: 100%;
		height: 100%;
	}
}

.aside {
	padding-left: 25px;
	border-left: 1px solid theme.$ra-black-soft;

	.title {
		margin-bottom: 15px;
	}

	.text {
		color: theme.$ra-black-dark;
	}

	.links {
		position: relative;
		margin-top: 20px;

		.link {
			position: relative;
			display: block;
			padding-left: 25px;

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 5px;
				width: 6px;
				height: 6px;
				transform: translateY(-50%) rotate(45deg);
				border-top: 1px solid theme.$ra-black-mid;
				border-right: 1px solid theme.$ra-black-mid;
			}

			&:not(:last-child) {
				margin-bottom: 5px;
			}
		}
	}
}
