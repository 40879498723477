.video-player {
	width: 100%;
	height: 100%;
}

.video-player,
.video-player video,
.video-player :has(video) {
	object-fit: inherit;
	object-position: inherit;
}
