@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

$modal-width: 564px;

@value modalWidth: #{$modal-width};

.modal :global(.ant-modal-content) {
	padding: 40px;
	border-radius: 24px;
}

.head {
	padding: 0 0 17px 0;
}

.action-btn {
	margin: 0 20px 0 0;
}

.body {
	padding: 0 0 48px 0;
}
