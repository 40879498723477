@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

$versionItemOffset: 15px;

.activity {
	position: relative;
}

.title {
	padding: 0 16px 24px;
	color: inherit;
}

.version-item {
	position: relative;
	display: flex;
	height: 48px;
	padding: 0 $versionItemOffset;
	flex-flow: row nowrap;
	align-items: center;
	border-radius: 6px;
	transition:
		background-color 0.35s,
		box-shadow 0.35s;

	&:not(:first-child):hover {
		background-color: theme.$ra-gray70;
		box-shadow: 2px 13px 14px -7px theme.$ra-black-mid;

		.button-wrap {
			opacity: 1;
			pointer-events: auto;
		}

		.badge {
			opacity: 0;
			visibility: hidden;
		}
	}

	.col {
		&:first-child {
			flex: 1;
		}
	}

	.badge {
		padding: 0 16px;
		opacity: 1;
		visibility: visible;
		transition:
			opacity 0.35s,
			visiblity 0.35s;
		border-radius: 20px;
		border: 1px solid #808080;

		&.published {
			border: 1px solid theme.$ra-error-ds;
			background-color: theme.$ra-error-ds;
		}
	}
}

.versions {
	color: inherit;
}

.button-wrap {
	position: absolute;
	top: 50%;
	right: $versionItemOffset;
	opacity: 0;
	pointer-events: none;
	transform: translate(0, -50%);
	transition: opacity 0.35s;
}
