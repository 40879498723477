@use '/src/routes/client/styles/mixins';
@use '/src/routes/client/styles/theme';

.shape {
	margin-right: 0.78vw;
	margin-left: 0.78vw;
	overflow: hidden;
	color: black;
	border-style: solid;
	border-width: 0;
	border-color: unset;

	svg {
		display: block;
		width: 100%;
		height: 100%;
	}

	&.hidden {
		display: none !important;
	}

	&-rect {
		border-radius: 0.94vw;
	}

	&-rect,
	&-circle {
		width: 5vw;
		height: 5vw;
		background-color: currentColor;
	}

	&-circle.shape {
		border-radius: 100%;
	}

	&-line-svg {
		display: flex;
		width: 10vw;
		height: 5vw;
		min-height: 20px;

		svg {
			width: 1px;
			height: 1px;
			margin: auto;
			overflow: visible;
		}
	}

	&-line {
		width: 10vw;
		height: 5vw;
		min-height: 20px;
		justify-content: center;
		align-items: center;
	}
}

.rect {
	fill: currentColor;
	stroke: none;
	stroke-width: 0;
}

.circle {
	fill: currentColor;
	stroke: none;
	stroke-width: 0;
}

.line {
	fill: none;
	stroke: currentColor;
	stroke-width: 1;
}

.line-block {
	display: block;
	width: 100%;
	min-height: 1px;
	background-color: currentColor;

	&-v {
		width: 100vw;
		transform: rotate(90deg) scaleX(3);
		justify-self: center;
	}
}
