@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.input-param {
	display: grid;
	padding-top: 24px;
	padding-bottom: 36px;
	grid-template-columns: 1fr 1fr 46px;
	grid-gap: 20px;
}
