@use '/src/routes/admin/styles/theme';

.template-item {
	--padding-x: 16px;

	position: relative;
	display: flex;
	margin-bottom: 44px;
	flex-flow: column nowrap;
	overflow: hidden;
	box-shadow: 0 2px 14px 0 theme.$ra-black-soft;
	border-radius: 0.75vw;

	&-preview {
		position: relative;
		height: 0;
		padding-top: 62.4%; // desktop platform ratio 800/1200
		overflow: hidden;
		cursor: pointer;
		border: 1px solid theme.$ra-black-soft;
		border-top-left-radius: inherit;
		border-top-right-radius: inherit;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	&-screenshot {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	&-actions {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: theme.$ra-black-dark;
		transition: 150ms ease-out;

		.template-item:not(:hover) & {
			opacity: 0;
		}

		button:nth-child(1) {
			z-index: 2;
		}

		button:nth-child(2) {
			margin-top: 12px;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 1;
			}
		}

		button i {
			margin-right: 0.6em;
		}
	}

	&-info {
		height: 84px;
		padding: 12px var(--padding-x) 0;

		& > :nth-child(2):not(:empty) {
			padding-top: 4px;
		}
	}

	&-footer {
		height: 44px;
		padding: 0 var(--padding-x);
		border-top: 1px solid theme.$ra-black-light;
		color: theme.$ra-black-dark;

		& > i {
			margin-right: 8px;
		}
	}
}
