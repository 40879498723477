@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/functions';

.chars-count {
	@include mixins.mapToProps(functions.map-deep-get(theme.$ra-font-size, description, base));

	color: theme.$ra-black-mid;
	text-align: right;
}

.dark {
	color: theme.$ra-white-mid;
}
