@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.floating-box {
	position: absolute;
	max-height: 80vh;
	overflow: auto;
	background-color: theme.$ra-white;
	border-radius: 8px;
	border: 1px solid theme.$ra-black-light;
	box-shadow: 0 24px 40px -12px theme.$ra-black-20;

	@include mixins.screen('non-desktop') {
		width: 150px;
	}
}
