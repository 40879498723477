@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.modal {
	height: 95vh;

	:global {
		.ant-modal-header {
			color: theme.$ra-black;
		}

		.ant-modal-content {
			display: grid;
			padding: 20px 24px 0;
			grid-template-rows: auto 1fr;
		}

		.ant-modal-content,
		.ant-modal-body,
		form {
			height: 100%;
		}
	}
}

.body {
	display: flex;
	height: 90%;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}

.submit {
	margin-top: 30px;
}
