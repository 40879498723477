@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.search {
	position: relative;
}

.search-input-wrap {
	position: relative;
	height: 48px;

	input {
		width: 100%;
		height: 100%;
		padding: 0 40px 0 72px;
		background-color: theme.$ra-black-light;
		border-radius: 48px;
		border: none;
		font-size: 1.6rem;
		box-shadow: none;
		outline: none;
	}

	.clear-btn,
	.search-btn {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
	}

	.clear-btn {
		right: 18px;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background-color: theme.$ra-black-mid;

		&::after,
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 10px;
			height: 2px;
			transform: translate(-50%, -50%);
			background-color: rgb(242, 242, 242);
		}

		&::after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		&::before {
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}

	.search-btn {
		left: 30px;
	}

	.search-icon {
		width: 19px;
		height: 19px;

		path {
			fill: theme.$ra-black-mid;
			transition: fill;
			transition-duration: 0.35s;
		}
	}

	&:hover {
		.search-icon {
			path {
				fill: theme.$ra-black;
			}
		}
	}
}
