.select {
	width: 100%;

	& + button {
		height: 46px;
	}
}

.selected-list {
	padding: 8px 0 8px 10px;
	margin-top: 14px;
	background: rgba(255, 255, 255, 0.04);
	border-radius: 4px;
}

.selected-item {
	padding: 8px 8px 8px 6px;

	&:not(:hover) button {
		display: none;
	}
}
