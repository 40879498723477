@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/functions';
@use '../vars';

$select-option-padding-y: 8px;
$select-divider-color: rgba(white, 0.04);

.option {
	@include mixins.mapToProps(functions.map-deep-get(theme.$ra-font-size, input, base));
	@include mixins.mapToProps(functions.map-deep-get(theme.$ra-font-size, input, compact));

	padding: 8px vars.$pf-padding-x;
	cursor: pointer;
	transition: 0.2s ease;

	&::first-letter {
		text-transform: uppercase;
	}

	&:not(:last-child) {
		box-shadow: 0 1px 0 0 $select-divider-color;
	}

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		padding-bottom: 0;
	}

	&:hover,
	&.selected {
		color: theme.$ra-brand-light;
	}
}

.control {
	@include mixins.mapToProps(functions.map-deep-get(theme.$ra-font-size, input, base));

	position: relative;
	display: grid;
	height: vars.$pf-height;
	padding-right: 14px;
	cursor: pointer;

	&::after {
		@include mixins.centerY();

		content: '';
		top: 55%;
		right: 0;
		border: 4px solid rgba(0, 0, 0, 0);
		border-bottom-width: 0;
		border-top-color: theme.$ra-white-mid;
		opacity: 0.7;
	}
}

.options {
	padding: 0;

	&:empty::after {
		content: 'No options';
		padding: $select-option-padding-y vars.$pf-padding-x;
		color: var(--ra-color-white-mid);
		font-size: functions.map-deep-get(theme.$ra-font-size, input, base, font-size);
	}
}

.has-search {
	padding-top: 32px;
}

.label {
	max-width: 200px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.placeholder {
	color: theme.$ra-white-mid;
}

.search {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	display: flex;
	width: 100%;
	background: theme.$ra-gray80;
	box-shadow: 0 1px 0 0 $select-divider-color;

	input {
		height: 38px;
		padding: 0 18px;
		margin: 0;
		color: theme.$ra-white-dark;
		font-size: 1.3rem;
		background: none;
		border: none;

		&,
		&:focus {
			box-shadow: none;
		}

		&::placeholder {
			color: theme.$ra-white-mid;
		}
	}

	i {
		display: flex;
		width: 40px;
		align-items: center;
	}
}

.after-options {
	padding-top: var(--popover-padding-y);
	margin-top: $select-option-padding-y;
	box-shadow: 0 -1px 0 0 $select-divider-color;
}
