@use 'sass:math';

@use 'sass:color';
@use 'sass:map';
@use 'sass:string';
// Colors
// -------------------------------------------------------------------------------------------------
//$ra-primary-color                   : #2962ff;
$ra-primary-100: #bbdefb;
$ra-primary-200: #90caf9;
$ra-primary-300: #64b5f6;
$ra-primary-400: #42a5f5;
$ra-primary-500: #2196f3;
$ra-primary-600: #1e88e5;
$ra-primary-700: #1976d2;
$ra-primary-800: #1565c0;
$ra-primary-900: #0d47a1;
$ra-primary-A100: #82b1ff;
$ra-primary-A200: #448aff;
$ra-primary-A400: #2979ff;
$ra-primary-A700: #2962ff;
$ra-primary-color: $ra-primary-500;

$ra-secondary-100: #c8e6c9;
$ra-secondary-200: #a5d6a7;
$ra-secondary-300: #81c784;
$ra-secondary-400: #66bb6a;
$ra-secondary-500: #4caf50;
$ra-secondary-600: #43a047;
$ra-secondary-700: #388e3c;
$ra-secondary-800: #2e7d32;
$ra-secondary-900: #1b5e20;
$ra-secondary-A100: #b9f6ca;
$ra-secondary-A200: #69f0ae;
$ra-secondary-A400: #00e676;
$ra-secondary-A700: #00c853;
$ra-secondary-color: $ra-secondary-500;

//$ra-success-color                   : #00ab80;
$ra-success-100: #c8e6c9;
$ra-success-200: #a5d6a7;
$ra-success-300: #81c784;
$ra-success-400: #66bb6a;
$ra-success-500: #4caf50;
$ra-success-600: #43a047;
$ra-success-700: #388e3c;
$ra-success-800: #2e7d32;
$ra-success-900: #1b5e20;
$ra-success-A100: #b9f6ca;
$ra-success-A200: #69f0ae;
$ra-success-A400: #00e676;
$ra-success-A700: #00c853;
$ra-success-color: $ra-success-500;

//$ra-danger-color                    : #f56923;
$ra-danger-100: #ffecb3;
$ra-danger-200: #ffe082;
$ra-danger-300: #ffd54f;
$ra-danger-400: #ffca28;
$ra-danger-500: #ffc107;
$ra-danger-600: #ffb300;
$ra-danger-700: #e69a39;
$ra-danger-800: #ff8f00;
$ra-danger-900: #ff6f00;
$ra-danger-A100: #ffe57f;
$ra-danger-A200: #ffd740;
$ra-danger-A400: #ffc400;
$ra-danger-A700: #ffab00;
$ra-danger-color: $ra-danger-500;

//$ra-error-color                     : #e51728;
$ra-error-100: #ffccbc;
$ra-error-200: #ffab91;
$ra-error-300: #ff8a65;
$ra-error-400: #ff7043;
$ra-error-500: #ff5722;
$ra-error-600: #f4511e;
$ra-error-700: #e64a19;
$ra-error-800: #d84315;
$ra-error-900: #bf360c;
$ra-error-A100: #ff9e80;
$ra-error-A200: #ff6e40;
$ra-error-A400: #ff3d00;
$ra-error-A700: #dd2c00;
$ra-error-color: $ra-error-500;

$ra-black: rgba(0, 0, 0, 0.87);
$ra-black-dark: rgba(0, 0, 0, 0.6);
$ra-black-mid: rgba(0, 0, 0, 0.38);
$ra-black-soft: rgba(0, 0, 0, 0.12);
$ra-black-light: rgba(0, 0, 0, 0.04);

$ra-white: rgba(255, 255, 255, 1);
$ra-white-dark: rgba(255, 255, 255, 0.7);
$ra-white-mid: rgba(255, 255, 255, 0.45);
$ra-white-soft: rgba(255, 255, 255, 0.18);
$ra-white-light: rgba(255, 255, 255, 0.1);

$ra-brand: rgb(0, 168, 126);
$ra-brand-light: rgb(0, 196, 148);
$ra-brand-dark: rgb(0, 121, 93);
$ra-semantic-fill: rgba(77, 156, 255, 1);
$ra-shadow-color: rgba(189, 204, 212, 0.15);
$ra-brand-white: hsl(0, 0%, 96%);
$ra-gray: rgba(38, 38, 38, 0.85);
$ra-gray15: rgb(38, 38, 38);
$ra-bg-body: $ra-brand-white;

// Typography
// -------------------------------------------------------------------------------------------------
$ra-body-font-size: 1.6rem;

$ra-font-size: (
	hero: (
		base: (
			font-size: 6.4rem,
			line-height: math.div(64, 64),
			letter-spacing: -0.05rem,
		),
		compact: (
			line-height: math.div(54, 64),
		),
		bold: (),
	),
	title: (
		base: (
			font-size: 4.2rem,
			line-height: math.div(48, 42),
		),
		compact: (
			line-height: math.div(44, 42),
		),
		bold: (),
	),
	heading: (
		base: (
			font-size: 3.2rem,
			line-height: math.div(40, 32),
		),
		compact: (),
		bold: (),
	),
	subtitle: (
		base: (
			font-size: 2.8rem,
			line-height: math.div(36, 28),
		),
		compact: (),
		bold: (),
	),
	subheading: (
		base: (
			font-size: 2.1rem,
			line-height: math.div(32, 21),
		),
		compact: (),
		bold: (),
	),
	article: (
		base: (
			font-size: 1.8rem,
			line-height: math.div(24, 18),
		),
		compact: (),
		bold: (),
	),
	paragraph: (
		base: (
			font-size: 1.6rem,
			line-height: math.div(24, 16),
		),
		compact: (),
		bold: (),
	),
	body: (
		base: (
			font-size: 1.4rem,
			line-height: math.div(20, 14),
		),
		compact: (),
		bold: (),
	),
	label: (
		base: (
			font-size: 1.3rem,
			line-height: math.div(20, 13),
		),
		compact: (
			line-height: normal,
		),
		bold: (),
	),
	description: (
		base: (
			font-size: 1.2rem,
			line-height: math.div(16, 12),
		),
		compact: (),
		bold: (),
	),
	footnote: (
		base: (
			font-size: 1.1rem,
			line-height: math.div(16, 11),
		),
		compact: (),
		bold: (),
	),
);
$ra-font-family: (
	'mono': string.unquote('monospace'),
	'sans': string.unquote('Assistant, sans-serif'),
	'serif': string.unquote('serif'),
	'sans-title': string.unquote('Assistant, sans-serif'),
);

// Media queries
// -------------------------------------------------------------------------------------------------
$ra-media-mobile-max: 1024;
$ra-media-tablet-min: 768;
$ra-media-tablet-max: 1024;
$ra-media-desktop-min: $ra-media-tablet-max + 1;

$ra-media-mobile: '(max-width: #{$ra-media-mobile-max}px)';
$ra-media-mobile-landscape: '(max-width: #{$ra-media-mobile-max}px) and (orientation: landscape)';
$ra-media-mobile-portrait: '(max-width: #{$ra-media-mobile-max}px) and (orientation: portrait)';

$ra-media-tablet: '(min-width: #{$ra-media-tablet-min}px) and (max-width: #{$ra-media-tablet-max}px)';
$ra-media-tablet-landscape: '(min-width: #{$ra-media-tablet-min}px) and (max-width: #{$ra-media-tablet-max}px) and (orientation: landscape)';
$ra-media-tablet-portrait: '(min-width: #{$ra-media-tablet-min}px) and (max-width: #{$ra-media-tablet-max}px) and (orientation: portrait)';

$ra-media-desktop: '(min-width: #{$ra-media-desktop-min}px)';

$ra-media-non-mobile: '(min-width: #{$ra-media-tablet-min}px)';
$ra-media-non-desktop: '(max-width: #{$ra-media-tablet-max}px)';

// Easing
// -------------------------------------------------------------------------------------------------
$ra-ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
$ra-ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ra-ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$ra-ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46);
$ra-ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6);
$ra-ease-in-out-back: cubic-bezier(0.71, -0.46, 0.29, 1.46);
$ra-ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
$ra-ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34);
$ra-ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
$ra-ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ra-ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ra-ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$ra-ease-in-sine: cubic-bezier(0.47, 0, 0.75, 0.72);
$ra-slow-in-fast-out: cubic-bezier(0.7, 0.01, 0.7, 1);

// Link
// -------------------------------------------------------------------------------------------------
$ra-link-color: color.adjust($ra-primary-color, $lightness: -10%);
$ra-link-hover-color: color.adjust($ra-link-color, $lightness: 10%);
$ra-link-active-color: color.adjust($ra-link-color, $lightness: -10%);
$ra-link-disabled-color: silver;
$ra-link-decoration: none;
$ra-link-hover-decoration: none;

// Base
// -------------------------------------------------------------------------------------------------
$ra-base-line-height: 1.4;
$ra-base-font-family: map.get($ra-font-family, 'sans');
$ra-base-font-size: 62.5%; // => 10px => 1rem
$ra-base-font-weight: 400;
$ra-base-color: black;
$ra-base-border-radius: 4px;

// Darkmode
// -------------------------------------------------------------------------------------------------
$ra-darkMode: 'ra-darkmode';
$ra-darkMode-bg: #{$ra-darkMode}_bg;
$ra-darkMode-text: #{$ra-darkMode}_text;
$ra-darkMode-image: #{$ra-darkMode}_image;

// Other
// -------------------------------------------------------------------------------------------------
$z-index: (
	modal: 300,
	floatAbove: 201,
	content: 200,
	floatBelow: 100,
	darkMode: 1000,
	editor-ui: 10000,
);
