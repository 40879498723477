@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.fields {
	margin: 0 0 24px 0;
}

.form {
	padding: 0 0 24px 0;
}

.email-field-input {
	margin: 0 0 24px 0;
}

.hero {
	&-title {
		margin-bottom: 24px;
	}

	&-subtitle {
		margin-top: 10px;
	}

	&-error {
		position: relative;
		padding: 0 20px 24px 0;
		color: theme.$ra-error-color;
	}

	.error-icon {
		position: absolute;
		top: 0;
		left: -25px;
		width: 20px;
		height: 20px;
		text-align: center;
		line-height: 19px;
		font-size: 12px;
		font-weight: 600;
		transform: translate(-4px, -2px);
		border-radius: 50%;
		background-color: theme.$ra-error-color;
		color: white;

		@include mixins.screen('non-desktop') {
			display: none;
		}
	}
}

.buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.link {
		color: theme.$ra-brand;
		opacity: 1;
		transition: opacity 0.2s ease-in;

		&.disabled,
		&:hover {
			opacity: 0.8;
		}
	}
}

.password-field {
	position: relative;

	.show-password-btn {
		position: absolute;
		top: 44px;
		right: 16px;
		font-size: 13px;
		color: theme.$ra-black-dark;
	}
}
