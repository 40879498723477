@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

.text {
	margin-bottom: 20px;
	color: theme.$ra-black-dark;
}

.codes {
	display: flex;
	padding: 40px 0;
	flex-wrap: wrap;
	row-gap: 20px;
	border: 1px dashed theme.$ra-brand;

	.code {
		display: flex;
		width: 50%;
		justify-content: center;
		align-items: center;
	}

	.no-codes-text {
		width: 100%;
		padding: 0 50px;
		text-align: center;
	}
}

.actions {
	position: relative;
	display: flex;
	margin-top: 15px;
	gap: 20px;

	.action {
		color: theme.$ra-brand;
		text-decoration: underline;
	}
}
