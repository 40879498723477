@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.template-type {
	display: flex;
	margin-bottom: 8px;
	flex-flow: row nowrap;
	justify-content: center;
}

.template-type-btn {
	display: flex;
	width: 164px;
	justify-content: center;
	align-items: flex-start;
	border: none !important;
	outline: none !important;

	&::before,
	&::after {
		display: none !important;
	}

	svg {
		max-width: 104px;
		max-height: 86px;
		margin-bottom: 8px;
	}

	&[class*='ant-radio-button-wrapper'] {
		height: auto;
		color: theme.$ra-black;

		&:hover {
			color: theme.$ra-black;
		}
	}

	&[class*='ant-radio-button'] {
		svg {
			opacity: 0.12;
			transition: opacity !important;
			transition-duration: 0.35s !important;
		}

		&:hover {
			svg {
				opacity: 0.5;
			}
		}
	}

	&[class*='ant-radio-button-wrapper-checked'] {
		svg {
			opacity: 1;
		}

		&:hover {
			svg {
				opacity: 1;
			}
		}
	}

	&[class*='ant-radio-button-wrapper-checked'],
	&[class*='ant-radio-button-checked'],
	input {
		background-color: white;
		border-color: transparent !important;
		border: none !important;
		outline: none !important;
		box-shadow: none !important;
	}

	&[class*='.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)'] {
		border-color: transparent !important;
	}
}

.gallery {
	display: grid;
	grid-auto-columns: 1fr;
	grid-column-gap: 1.5vw;
	grid-row-gap: 0;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: auto;
}

.filters {
	margin-bottom: 16px;
	flex: 1;
	overflow: auto;

	section {
		margin-bottom: 35px;
	}

	h3 {
		margin-bottom: 20px;
	}
}

.filter-option {
	display: grid;
	height: 32px;
	align-items: center;
	grid-template-columns: 16px 1fr;
	grid-column-gap: 14px;
	transition: 200ms ease opacity;
	cursor: pointer;
	user-select: none;

	&:hover {
		opacity: 0.7;
	}

	&:not(.checked) > i path:nth-child(2) {
		fill: theme.$ra-black-mid;
	}
}
