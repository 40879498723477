@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/components/common/PageLayout/PageLayoutVars.scss';

.profile {
	position: relative;
}

.workspaces {
	padding: 0 0 80px;
}

.upload-avatar {
	padding: 0 0 40px;
}

.content-layout {
	width: 535px;
	padding-top: 0;
}

.content-header-wrap {
	display: flex;
	min-height: calc(#{PageLayoutVars.$content-margin-top} - #{PageLayoutVars.$top});
	flex-direction: column;
	justify-content: flex-end;
}

.content-header {
	display: flex;
	padding-top: 133px;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;

	&-text {
		margin-bottom: 35px;
		letter-spacing: -3px;
		line-height: 1;
	}
}

.info {
	display: flex;
	margin-bottom: 36px;
	flex-direction: column;
}

.info-row {
	display: flex;
	color: theme.$ra-black-dark;

	&:first-child {
		margin-bottom: 7px;
		color: theme.$ra-brand;
	}

	p:first-child {
		position: relative;

		&::after {
			content: '';
			display: inline-block;
			width: 0.25em;
			height: 0.25em;
			margin: 0 6px;
			background-color: currentColor;
			border-radius: 50%;
			vertical-align: middle;
		}
	}
}

.account-actions,
.auth-options {
	display: flex;
	padding-top: 34px;

	.title {
		padding-bottom: 24px;
	}
}

.actions-list,
.auth-options-list {
	display: flex;
	justify-content: space-between;

	&-item {
		display: flex;
		width: 157px;
		height: 120px;
		padding: 16px;
		margin-right: 32px;
		flex-flow: column;
		box-shadow: inset 0 0 0 1px #ccc;
		border-radius: 6px;
		text-align: left;
		transition: box-shadow 0.25s ease;

		&:hover {
			box-shadow: inset 0 0 0 2px theme.$ra-brand;
		}

		.name {
			display: flex;
			width: 65%;
			align-items: flex-end;
			flex-grow: 1;
			line-height: 1.3;
		}

		.tag {
			margin: -6px -6px 0 0;
			align-self: self-end;
			text-transform: capitalize;
		}

		&-disabled {
			pointer-events: none;
		}
	}
}

.auth-alert {
	margin-top: 24px;
}
