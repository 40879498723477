$non-desktop: 'max-width: 1024px';

.not-found {
	display: grid;
	min-height: 100vh;
	place-items: center;
	grid-template-rows: 1fr auto;
}

.content {
	display: grid;
	place-items: center;

	@media only screen and (#{$non-desktop}) {
		padding: 0 20px;
	}
}

.img-wrap {
	position: relative;
	transform: translate(-100px, 0);

	@media only screen and (#{$non-desktop}) {
		width: 100%;
		height: 100%;
		max-width: 600px;
		max-height: 243px;
		transform: translate(-10%, 0);
	}

	svg {
		width: 62vw;
		max-width: 880px;

		@media only screen and (#{$non-desktop}) {
			width: 100%;
			height: 100%;
		}
	}
}

.title {
	font-weight: 600;
	font-size: 6.4rem;
	line-height: normal;

	@media only screen and (#{$non-desktop}) {
		font-size: 3.2rem;
		line-height: 1.25;
	}
}

.actions {
	position: relative;
	padding-bottom: 60px;

	@media only screen and (#{$non-desktop}) {
		padding: 20px 0;
	}
}
