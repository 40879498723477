@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.root {
	padding-bottom: 8px;

	:global {
		.ant-collapse,
		.ant-collapse .ant-collapse-content,
		.ant-collapse > .ant-collapse-item > .ant-collapse-header {
			color: inherit;
		}

		.ant-collapse-ghost .ant-collapse-item {
			margin: 8px 0 0 16px;
			border-radius: 6px;
		}

		.ant-collapse-item-active {
			background-color: rgba(255, 255, 255, 0.04);
		}

		.ant-collapse-header-text {
			user-select: none;
		}

		.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
			padding-block-start: 0;
		}
	}
}

.group {
	margin-bottom: 24px;

	input[type='number'] {
		padding-right: 12px;
	}
}

.toggle {
	width: 260px;
}
