@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.two-fa-enforcement {
	position: relative;
}

.hero {
	width: 100%;
	max-width: 487px;

	&-title {
		margin-bottom: 24px;
	}
}

.text {
	margin-bottom: 24px;
	color: theme.$ra-black-dark;
}
