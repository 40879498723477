@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/functions';

.input-label {
	@include mixins.mapToProps(functions.map-deep-get(theme.$ra-font-size, label, base));

	display: inline-block;
	color: theme.$ra-label-base-color;
	cursor: pointer;
	user-select: none;

	&.dark {
		color: theme.$ra-white;
	}
}

.weight {
	&-semibold {
		font-weight: 600;
	}

	&-normal {
		font-weight: 400;
	}
}
