@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

$modal-width: 950px;

@value modalWidth: #{$modal-width};

%footer-buttons {
	position: absolute;
	right: 10px;
	bottom: 0;
	left: 0;
	z-index: 100;
	padding-top: 10px;
	background-color: #4d4d4d;
}

.modal {
	max-width: 85vw;

	:global {
		.ant-modal-content {
			height: 770px;
			max-height: 80vh;
			border-radius: 24px;
		}

		.ant-modal-body {
			height: 100%;
		}
	}
}

.content {
	padding-top: 18px;
}

.upload-content {
	padding-bottom: 90px;
}

.footer {
	@extend %footer-buttons;
}

.button {
	margin: 0 16px 0 0;
}

.title {
	display: flex;
	margin-bottom: 24px;
	justify-content: space-between;
}

/******************************************** From Url ***********************************************/

.notes {
	margin: 0 0 24px 0;
	color: theme.$ra-white-mid;

	ul {
		padding: 0 0 0 20px;
		margin: 10px 0;
	}
}

/******************************************** Upload File ***********************************************/

.title-input {
	margin-bottom: 24px;
}

.prev-upload-info {
	margin-bottom: 24px;
}

.upload-view-btns {
	@extend %footer-buttons;
}

/******************************************** Video Gallery *********************************************/

.no-results {
	color: theme.$ra-white-dark;
}

.videos-list {
	display: flex;
	flex-flow: row wrap;
	gap: 10px;
}

.loading-wrap {
	position: relative;
	display: flex;
	width: 100%;
	margin-top: 10px;
	justify-content: center;
}

.badge {
	position: absolute;
	top: 5px;
	right: 5px;
	padding: 2px 6px;
	border-radius: 6px;
	background-color: theme.$ra-black-20;
	color: theme.$ra-white-dark;
}

.video-item {
	position: relative;
	width: 207px;
	height: 174px;
	overflow: hidden;
	border-radius: 8px;
	background-color: theme.$ra-white-light;
	text-align: left;
	cursor: pointer;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		width: 100%;
		height: 100%;
		border-radius: 8px;
		pointer-events: none;
		transition: box-shadow 0.2s ease-in;
	}

	&.disabled {
		cursor: default;
	}

	&.active {
		&::after {
			box-shadow: inset 0 0 0 3px theme.$ra-brand;
		}
	}

	.play-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 26px;
		height: 26px;
		transform: translate(-50%, -50%);
		background-color: theme.$ra-black-dark;
		border-radius: 50%;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 54%;
			width: 0;
			height: 0;
			transform: translate(-50%, -50%) rotate(0deg);
			border-style: solid;
			border-width: 5px 0 5px 8.7px;
			border-color: transparent transparent transparent theme.$ra-white-dark;
		}
	}

	.duration {
		position: absolute;
		right: 5px;
		bottom: 4px;
		padding: 1.5px 5.5px;
		border-radius: 2px;
		background-color: theme.$ra-black-dark;
	}

	.poster {
		position: relative;
		height: 66%;
		overflow: hidden;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
	}

	.img-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 24.5%;
		height: 43.8%;
		transform: translate(-50%, -50%);
	}

	.img-icon path {
		fill: theme.$ra-white-light;
	}

	.info {
		padding: 8px 9px 6px 9px;
	}

	.info-link {
		display: block;
		padding-bottom: 2px;
		color: theme.$ra-white;

		&:hover {
			text-decoration: underline;
		}

		span {
			display: block;
			width: 100%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.info-date {
		color: theme.$ra-white-dark;
	}
}
