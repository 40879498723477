.full-frame,
.full-screen {
	top: 0;
	left: 0;
	z-index: 9999;
	display: grid;
	width: 100%;
	height: 100%;
	background: white;
	place-content: center;
	transition: backdrop-filter 700ms;
}

.full-frame {
	position: absolute;
}

.full-screen {
	position: fixed;
	backdrop-filter: blur(3px);
}

.loader {
	position: relative;
	font-size: 10px;
	text-indent: -9999em;
	border-top: 2px solid rgba(249, 255, 254, 0.2);
	border-right: 2px solid rgba(249, 255, 254, 0.2);
	border-bottom: 2px solid rgba(249, 255, 254, 0.2);
	border-left-width: 2px;
	border-left-style: solid;
	transform: translateZ(0);
	animation: load8 1.1s infinite linear;
	border-radius: 50%;

	&::after {
		width: inherit;
		height: inherit;
	}

	&.tiny {
		width: 16px;
		height: 16px;
		border-width: 1px;
	}

	&.small {
		width: 20px;
		height: 20px;
	}

	&.medium {
		width: 30px;
		height: 30px;
	}

	&.large {
		width: 50px;
		height: 50px;
	}
}

@keyframes load8 {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
