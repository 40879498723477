@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.checkbox {
	display: flex;
	cursor: pointer;
	gap: 4px 12px;

	&-inner {
		position: relative;

		.light & {
			color: var(--ra-color-black-mid);
		}

		.dark & {
			color: var(--ra-color-white-soft);
		}

		.checked & {
			color: var(--ra-color-brand-light);
		}
	}

	&-box {
		position: relative;
	}

	input {
		display: none;
	}

	&.disabled {
		opacity: 0.45;
		cursor: not-allowed;

		.label {
			cursor: not-allowed;
		}
	}
}

.align-top {
	align-items: flex-start;
}

.align-center {
	align-items: center;
}

.label-right {
	flex-direction: row-reverse;
	justify-content: flex-end;
}

.label-top {
	flex-direction: column;
	align-items: flex-start;
}

.stretch {
	width: 100%;
	justify-content: space-between;
}

.field-checkbox {
	&.disabled {
		.checkbox,
		.label {
			opacity: 0.45;
			cursor: not-allowed;
		}
	}
}
