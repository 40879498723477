@use '/src/routes/client/styles/theme';
@use '/src/routes/client/styles/helpers';
@use '/src/routes/client/styles/mixins';

.img {
	position: relative;
	width: 20vw;
	height: 20vw;
	object-fit: cover;

	&.cover {
		object-fit: cover;
	}

	&.contain {
		object-fit: contain;
	}

	&.link {
		cursor: pointer;
	}

	&:where(.default-cover) {
		object-fit: cover;
	}

	span {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: block;
		pointer-events: none;
		transition: inherit;
	}

	img {
		@include mixins.darkModeInvertion(theme.$ra-darkMode-image);

		width: 100%;
		height: 100%;
		object-fit: inherit;
		transform-origin: top left;
	}
}

.empty {
	@extend %empty-placeholder;
}
