.field {
	width: 100%;

	// field error
	ul {
		position: absolute;
	}
}

.options {
	margin: 16px 0;
	overflow-y: scroll;
}

.options-row {
	padding: 8px 0;
	border-bottom: 1px solid var(--ra-color-white-light);

	// add glass effect on drag
	&:active {
		background-color: rgba(#4d4d4d, 0.1);
		backdrop-filter: blur(3px);
	}
}

.col {
	height: 2em;

	&:nth-child(2) {
		margin-left: 16px;
	}

	&:last-child {
		padding-left: 10px;
	}
}

.drag-handle {
	padding-right: 2px;
	margin-left: -2px;
}

.drag-handle,
.col:last-child {
	.options-row:not(:hover) & {
		visibility: hidden;
	}
}
