@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.field-toggle {
	position: relative;
}

.field-toggle-inner {
	position: relative;

	:global {
		.ant-switch-checked {
			background-color: theme.$ra-brand-light;

			&:focus {
				box-shadow: none;
			}

			&:focus:active {
				background-color: theme.$ra-brand;
			}
		}
	}
}

.toggle {
	display: flex;
	align-items: center;
	gap: 4px 12px;
	cursor: pointer;
}

.align-top {
	align-items: flex-start;
}

.align-center {
	align-items: center;
}

.label-right {
	flex-direction: row-reverse;
	justify-content: flex-end;
}

.label-top {
	flex-direction: column;
	align-items: flex-start;
}

.stretch {
	width: 100%;
	justify-content: space-between;
}

.disabled {
	.toggle,
	.label {
		opacity: 0.45;
		cursor: not-allowed;
	}

	:global(.ant-switch-disabled) {
		opacity: 1;
	}
}
