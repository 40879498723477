@use '/src/routes/client/styles/theme';

.arrow {
	top: auto;
	right: auto;
	bottom: auto;
	left: auto;
	width: 3.87vw;
	height: 3.87vw;
	align-items: center;
	align-self: center;
	text-align: center;
	background: theme.$ra-black-mid;
	border-radius: 20vw;
	color: theme.$ra-white;
	transition:
		background-color 300ms ease,
		opacity 300ms ease;
	user-select: none;

	:where([data-platform='mobile']) & {
		width: 13.33vw;
		height: 13.33vw;
	}

	:where(html:not([data-editor='true'])) & {
		cursor: pointer;
	}

	&:where(html:not([data-editor='true']) :global(.swiper-button-disabled)) {
		opacity: 0.35;
		cursor: auto;
		pointer-events: none;
	}
}

.prev {
	justify-self: start;
}

.next {
	justify-self: end;
}
