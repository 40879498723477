@use '/src/routes/client/styles/theme';
@use '/src/routes/client/styles/mixins';

.text-area {
	display: block;
	width: 30vw;
	height: 12vw;
	overflow: visible;
	color: theme.$ra-black;
	font-size: 2vw;
}

.area {
	width: 100%;
	height: 100%;
	padding: 0 1.5vw;
	border: none;
	box-shadow: none;
	outline: none;
	resize: none;
	white-space: pre-wrap;
	color: inherit;
	font: inherit;
	text-align: inherit;
	background: theme.$ra-black-light;

	&-wrap {
		width: 100%;
		height: 100%;
		overflow: hidden;
		border-radius: inherit;
	}

	&[readonly] {
		cursor: default;
		user-select: none;
	}

	.editable & {
		display: flex;
		align-items: flex-start;
	}
}
