@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.custom-fonts {
	color: theme.$ra-white;

	&.disabled {
		.upload-btn,
		.title,
		.description,
		.label {
			color: theme.$ra-white-mid !important;
			border-color: theme.$ra-white-mid !important;
		}
	}

	label {
		display: block;
		cursor: pointer;

		&:hover {
			.upload-btn:not(:disabled) {
				opacity: 0.7;
			}
		}
	}

	&.disabled label {
		cursor: default;
	}
}

.upgrade-btn {
	position: absolute;
	top: -10px;
	right: 0;
	display: block;
}

.lock-icon {
	padding-bottom: 2px;
	padding-left: 7px;
}

.title {
	padding-bottom: 24px;
	color: theme.$ra-white;
}

.label {
	padding-bottom: 6px;
}

.description {
	padding-bottom: 16px;
	color: theme.$ra-white-mid;
}

.upload-btn {
	pointer-events: none;
}

.remove-btn {
	position: absolute;
	top: 3px;
	right: 3px;
	width: 30px;
	height: 30px;
	padding: 5px;

	&::after,
	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 12px;
		height: 1px;
		transform: translate(-50%, -50%);
		background-color: theme.$ra-white;
	}

	&::after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}

	&::before {
		transform: translate(-50%, -50%) rotate(45deg);
	}
}

.uploaded-item {
	padding-bottom: 30px;

	&.is-busy {
		pointer-events: none;
	}

	.inner {
		position: relative;
		padding: 7px 13px;
		margin: 0 0 20px 0;
		border: 1px solid #808080;
		border-radius: 6px;
	}

	.input-wrap {
		display: flex;
		height: 18px;
		padding-bottom: 5px;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		color: theme.$ra-white-mid;

		&.is-error {
			color: theme.$ra-error-color;
		}

		> div {
			white-space: nowrap;
		}

		label {
			white-space: nowrap;
		}

		input {
			width: 100%;
			padding: 0 6px;
			margin: 0 16px 0 6px;
			background-color: transparent;
			color: theme.$ra-white-mid;
			font-size: 1.2rem;
			outline: none;
			border: 1px solid var(--ra-color-white-mid);
			border-radius: 2px;

			&:focus,
			&:active {
				border-color: var(--ra-color-white-dark);
			}
		}
	}

	.font-error {
		padding-bottom: 5px;
		color: theme.$ra-error-color;
	}

	.name {
		padding-bottom: 10px;
	}

	.metadata {
		padding-bottom: 5px;
		color: theme.$ra-white-mid;
	}
}
