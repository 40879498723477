@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.media-field {
	display: flex;
	flex-flow: row nowrap;

	&.light {
		.input-wrap {
			border: 1px solid theme.$ra-black-20;
		}

		.info {
			color: theme.$ra-black-mid;
		}

		.preview {
			&::before {
				background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
			}
		}
	}

	&.error {
		.label {
			color: theme.$ra-error-color;
		}

		.input-wrap {
			border: 1px solid theme.$ra-error-color;

			&:hover {
				border: 1px solid theme.$ra-error-color;
			}
		}
	}

	&.large {
		flex-flow: column-reverse nowrap;

		.info {
			position: absolute;
			bottom: 20px;
			left: 50%;
			padding: 0;
			transform: translate(-50%, 0);
			text-align: center;
		}

		.cancel-icon-btn {
			right: 20px;
		}

		.input-wrap {
			width: 100%;
			height: 258px;
			margin: 6px 0 0 0;
		}

		&.busy {
			.info {
				display: none;
			}
		}
	}

	&.busy {
		.input-wrap {
			.preloader {
				display: block;
				animation-name: preloader-animation;
				animation-duration: 1s;
				animation-iteration-count: infinite;
				animation-timing-function: linear;
			}

			.preview,
			.upload-image-icon {
				display: none;
			}
		}
	}

	&.has-preview {
		&:hover {
			.cancel-icon-btn {
				opacity: 1;
				visibility: visible;
			}

			.change-icon-btn {
				opacity: 1;
				visibility: visible;
				pointer-events: auto;
			}

			.preview {
				&::before {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}

.label {
	color: theme.$ra-white;
	transition: color 0.35s;
}

.error {
	color: theme.$ra-error-color;
}

.input-wrap {
	--transition-duration: 300ms;

	position: relative;
	display: flex;
	width: 80px;
	height: 80px;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	border-radius: 6px;
	border: 1px solid theme.$ra-gray50;
	transition: border var(--transition-duration);

	path {
		transition: fill var(--transition-duration);
	}

	.preloader {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 20;
		display: none;
		width: 32px;
		height: 32px;
		transform: translate(-50%, -50%);

		circle {
			stroke: white;
		}

		@keyframes preloader-animation {
			0% {
				transform: translate(-50%, -50%) rotate(0deg);
			}

			100% {
				transform: translate(-50%, -50%) rotate(360deg);
			}
		}
	}

	.change-icon-btn {
		position: absolute;
		top: 50%;
		left: 50%;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transition:
			opacity 0.35s,
			visibility 0.35s;
		transform: translate(-50%, -50%);
	}

	.preview {
		position: relative;
		width: 100%;
		height: 100%;
		background-position: 50% 50%;
		background-repeat: no-repeat;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			transition:
				opacity 0.35s,
				visibility 0.35s;
			background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
		}
	}

	label {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	input {
		position: absolute;
		z-index: -1;
		width: 0.1px;
		height: 0.1px;
		overflow: hidden;
		opacity: 0;
	}

	&:hover {
		border: 1px solid theme.$ra-brand;

		.change-icon-btn path,
		.upload-image-icon path {
			fill: theme.$ra-brand;
		}
	}
}

.info {
	position: relative;
	padding: 0 20px 0 16px;
	flex: 0 0 260px;
	color: theme.$ra-white-dark;
}

.cancel-icon-btn {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(0, -50%);
	opacity: 0;
	visibility: hidden;
	transition:
		opacity 0.35s,
		visibility 0.35s;

	&:hover path {
		fill: theme.$ra-brand;
	}

	&:disabled {
		display: none;
	}
}
