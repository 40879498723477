.fixed {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	align-self: unset;
	justify-self: unset;
	pointer-events: none !important;

	& > :not([data-bb]) {
		pointer-events: none !important;
	}

	> [data-bb] {
		pointer-events: auto;
	}
}
