.preview {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;

	&:not(.cover-mode) {
		--margin-y: 35px;

		height: calc(100% - var(--margin-y) - var(--margin-y));
		margin: var(--margin-y) auto;
	}

	:global {
		.animate {
			&-enter {
				opacity: 0;
				visibility: hidden;
			}

			&-enter-active {
				opacity: 1;
				visibility: visible;
				transition-delay: 200ms;
				transition-duration: 100ms;
			}

			&-exit {
				opacity: 1;
				visibility: visible;
			}

			&-exit-active {
				opacity: 0;
				visibility: hidden;
				transition-duration: 50ms;
			}
		}
	}
}

.iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 12px;
	transform-origin: 0 0;
	border: 1px solid rgba(0, 0, 0, 0.12);

	.cover-mode & {
		border: none;
		border-radius: unset;
	}
}
