@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.check-pass-field {
	display: flex;

	&.vertical {
		display: block;

		.check-pass-field-col {
			&:first-child {
				.check-pass-field-item {
					&:last-child {
						margin: 0 0 12px 0;
					}
				}
			}
		}

		.check-pass-field-item {
			margin: 0 0 12px 0;

			&:last-child {
				margin: 0;
			}
		}
	}

	&-col {
		flex-basis: 50%;
	}

	&-item {
		position: relative;
		padding: 0 0 0 28px;
		margin: 0 0 12px 0;
		font-size: 13px;
		line-height: 1;
		font-weight: 400;
		color: theme.$ra-black-dark;
		transition: color 0.35s ease-in;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 8px;
			z-index: 10;
			width: 5px;
			height: 5px;
			transform: translate(0, -50%) rotate(-45deg);
			border-bottom: 1px solid theme.$ra-black-dark;
			border-right: 1px solid theme.$ra-black-dark;
			transition:
				transform 0.2s ease-in,
				height 0.2s ease-in,
				border-color 0.35s ease-in;
		}

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 10px;
			display: block;
			width: 17px;
			height: 17px;
			transform: translate(-50%, -50%) scale(0);
			border-radius: 50%;
			background-color: theme.$ra-brand;
			opacity: 0;
			visibility: hidden;
			transition: opacity, visibility, transform;
			transition-duration: 0.2s;
		}

		&:last-child {
			margin: 0;
		}

		&.active {
			color: theme.$ra-black-dark;

			&::before {
				width: 5px;
				height: 9px;
				transform: translate(0, calc(-50% - 2px)) rotate(45deg);
				border-color: theme.$ra-white;
			}

			&::after {
				opacity: 1;
				visibility: visible;
				transform: translate(-50%, -50%) scale(1);
			}
		}
	}
}
