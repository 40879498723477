@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.root {
	padding-bottom: 8px;

	input[type='text'] {
		padding-right: 8px;
	}

	& > section {
		padding: 16px 0 0;
	}
}

.hint {
	width: 400px;
	margin: -10px 0 20px;
}

.t-head {
	padding-left: 8px;
}

.root input[type='text'],
.root .sheet {
	padding-left: 16px;
}

.section-title {
	padding: 12px 8px;
}

.sheet-list {
	border: 1px solid var(--ra-color-white-light);
	border-radius: 6px;
}

.sheet {
	@extend .section-title;

	&:not(:first-child) {
		border-top: inherit;
	}

	&:not(:hover) .sheet-actions {
		display: none;
	}
}

.sheet-name {
	cursor: pointer;
	transition: 200ms ease;

	em {
		color: var(--ra-color-white-mid);
		font-size: 0.8em;
		margin-inline-start: 8px;
	}

	&:hover {
		color: var(--ra-color-brand);
	}
}

.sheet-actions {
	height: 20px;
}

section.variables {
	padding: 12px 12px 8px;
	margin: 16px 0;
	background: rgba(white, 0.04);
	border-radius: 6px;

	&.is-export {
		display: grid;
		padding: 0;
		margin: 12px 0 0;
		align-items: center;
		background: none;
		grid-template-columns: 148px auto;
	}
}

.range-actions {
	display: none;
	height: var(--range-height);
}

.range:focus-within .range-actions,
.range:hover .range-actions {
	display: flex;
}

.range {
	--range-height: 55px;

	height: var(--range-height);
}
