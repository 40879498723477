@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/functions';
@use '../vars';

.pf {
	&-number {
		position: relative;
		display: flex;
		align-items: center;

		&.disabled {
			cursor: not-allowed;
		}

		.icon {
			position: relative;
			display: flex;
			width: 24px;
			height: 24px;
			min-width: 24px;
			margin-left: -2px;
			opacity: 0.7;
			transform: translate(0, -1px);
		}
	}

	&-input {
		@include mixins.mapToProps(functions.map-deep-get(theme.$ra-font-size, input, base));
		@include mixins.placeholder-color(theme.$ra-white-mid);

		position: relative;
		display: block;
		width: 100%;
		height: vars.$pf-height;
		max-width: 40px;
		padding: 0;
		margin: 0;
		align-self: center;
		vertical-align: middle;
		border: 0 none #000;
		background-color: transparent;
		background-image: none;
		color: theme.$ra-white;

		&:focus,
		&:active {
			outline: none;
		}

		&[readonly] {
			pointer-events: none;
		}

		.view-highlight & {
			padding: 0 4px;
			background: theme.$ra-white-light;
			border-radius: 2px;
		}
	}
}

.overlay {
	@include mixins.fixed(top 0 left 0 right 0 bottom 0);

	z-index: 9999;
	cursor: ns-resize;
}
