@use '/src/routes/admin/styles/theme';

.item {
	padding: 3px 0 5px;
	transition: color 0.2s ease;
	text-transform: lowercase;
	color: theme.$ra-base-color;

	&:hover {
		color: theme.$ra-brand;
	}

	&-error:hover {
		color: theme.$ra-error-A100;
	}
}

.capitalize::first-letter {
	text-transform: uppercase;
}
