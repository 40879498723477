@use '/src/routes/admin/styles/theme';

.content {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	color: theme.$ra-white;
}

.text {
	margin-right: 26px;
	margin-left: 12px;

	b {
		font-weight: 600;
	}
}
