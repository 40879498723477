@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.icons {
	display: flex;
	gap: 16px;
}

.icon-btn {
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
	transition: 200ms ease;

	.icons &[data-selector='inactive-icon'] {
		color: var(--ra-color-white-dark);

		&:not(:hover) {
			opacity: 0.7;
		}
	}

	path,
	rect,
	polygon {
		fill: currentColor;
	}
}

.icon {
	position: relative;
	display: flex;
	width: 24px;
	height: 24px;
}

[data-selector='active-icon'].no-icon .label {
	color: inherit;
}

.fill {
	border-radius: 50%;
}

.icon-btn[data-selector='active-icon'] {
	&.fill {
		background-color: currentColor;

		.icon,
		.label {
			color: var(--ra-color-white-dark);
		}
	}

	&.color {
		color: currentColor;

		[data-inheritance='default'] & {
			color: var(--ra-color-white-dark);
		}
	}
}
