@use '/src/routes/admin/styles/theme';

.embed-code-wrap {
	position: relative;

	.tooltip {
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 3px 10px;
		color: theme.$ra-white;
		background-color: theme.$ra-black;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		border-radius: 6px;
		transform: translate(-50%, calc(-50% + 6px));
		transition:
			opacity 0.25s ease,
			visibilty 0.25s ease,
			transform 0.25s ease;

		&.active {
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
			transform: translate(-50%, -50%);
		}
	}

	.label {
		display: flex;
	}

	.copy-btn {
		display: flex;
		margin: 0 0 0 8px;
		align-items: center;
		transition: opacity 0.35s ease;

		&:hover {
			opacity: 0.7;
		}
	}
}

.embed-code {
	position: relative;
	display: block;
	padding-top: 5px;
	color: theme.$ra-white;
	word-break: break-all;
}

.adv-link {
	margin-left: 12px;
	vertical-align: middle;

	.label {
		align-items: center;
	}

	i {
		position: relative;
		top: 1px;
		margin-right: 1px;
	}

	path {
		fill: currentColor;
	}
}
