@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.login-layout {
	display: grid;
	min-height: 100vh;
	align-items: center;
	grid-template-columns: 1fr 1fr;
	justify-items: center;

	@include mixins.screen('non-desktop') {
		grid-template-columns: auto;
	}
}

.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 18px 32px 0;

	@include mixins.screen('non-desktop') {
		padding: 18px 20px 0;
		background-color: theme.$ra-white;
	}
}

.decor-panel {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	background-color: theme.$ra-brand-light;
}

.content {
	width: 100%;
	max-width: 467px;
	padding: 0 20px;
}
