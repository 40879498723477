@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '../vars';

.pf-toggle {
	position: relative;
	display: flex;
	height: vars.$pf-height;
	align-items: center;

	:global {
		.ant-switch {
			height: 18px;
			min-width: 32px;
			background-color: theme.$ra-white-mid;

			&::after {
				width: 20px;
				height: 20px;
				background-color: theme.$ra-white-mid;
				box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
				border-radius: 50%;
			}

			&:hover {
				background-color: theme.$ra-white-dark;
			}

			&.ant-switch-checked {
				background-color: theme.$ra-brand-light;

				&::after {
					background: theme.$ra-brand-white;
				}

				.ant-switch-handle {
					left: calc(100% - 14px - 2px);
				}
			}

			.ant-switch-inner {
				color: rgba(255, 255, 255, 0.75);
			}
		}

		.ant-switch-handle {
			width: 14px;
			height: 14px;
		}
	}
}

.view-roundCheck,
.view-roundAlert {
	&,
	.icon,
	svg {
		width: 18px;
		height: 18px;
	}

	.icon {
		position: absolute;
	}

	:global {
		.ant-switch,
		.ant-switch.ant-switch-checked {
			z-index: 1;
			height: 100%;
			min-width: 100%;
			background: none;

			&:hover {
				background-color: unset;
			}
		}

		.ant-switch-handle {
			display: none;
		}
	}
}

.pf-toggle.view-roundCheck :global(.ant-switch) {
	color: theme.$ra-brand-light;
}

.pf-toggle.view-roundAlert :global(.ant-switch) {
	color: theme.$ra-error-ds;
}

.pf-input:focus {
	box-shadow: none;
}
