.title {
	margin-bottom: 20px;
}

.description {
	margin-bottom: 20px;
	opacity: 0.45;
}

.hint {
	margin-right: 60px;

	&-with-margin {
		margin-top: 10px;
	}
}

.field {
	margin-bottom: 20px;
}
