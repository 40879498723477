@use '/src/routes/admin/styles/theme';

.layout {
	--aside-width: 256px;
	--offset-left: 24px;

	display: grid;
	height: 100%;
	grid-template-rows: 60px 90px 1fr;
	grid-template-columns: #{var(--aside-width)} 1fr;
}

.header {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid theme.$ra-black-light;
}

.panel {
	display: flex;
	justify-content: flex-end;
	border-bottom: 1px solid theme.$ra-black-light;
}

.aside {
	display: flex;
	padding-right: 12px;
	padding-bottom: 24px;
	padding-left: var(--offset-left);
	flex-flow: column;
	overflow: scroll;

	& > button:last-child {
		margin-top: auto;
		flex-shrink: 0;
	}
}

.header,
.panel {
	padding-right: var(--offset-left);
	padding-left: var(--offset-left);
	align-items: center;
	grid-column: 2 span;
}

.content {
	overflow: hidden;

	&:not(.cover-mode) {
		padding-right: 16px;
		padding-left: 16px;
		overflow: scroll;
	}
}

.c-content {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	grid-row: 2 span;
	grid-column: 2 span;
}

.pt-35 {
	padding-top: 35px;
}

.col-two-thirds {
	width: calc(100% - #{var(--aside-width)});
}
