@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.fonts {
	position: relative;
}

.custom,
.google {
	padding-bottom: 64px;
}

.no-fonts-title {
	padding-bottom: 30px;
	color: theme.$ra-white-mid;
}

.story-font-item {
	position: relative;
	padding: 7px 12px 11px;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: theme.$ra-white-soft;
	}

	&:last-child {
		&::after {
			display: none;
		}
	}

	.font-title {
		padding-bottom: 6px;
	}

	.remove-btn {
		position: absolute;
		top: 3px;
		right: 3px;
		width: 30px;
		height: 30px;
		padding: 5px;

		&::after,
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 12px;
			height: 1px;
			transform: translate(-50%, -50%);
			background-color: theme.$ra-white;
		}

		&::after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		&::before {
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}
}

.story-fonts {
	.title {
		padding-bottom: 24px;
	}
}

.story-fonts-list {
	margin: 0 0 40px 0;
	border: 1px solid #808080;
	border-radius: 6px;

	.title {
		color: theme.$ra-white;
	}

	.font-info {
		color: theme.$ra-white-mid;
	}
}
