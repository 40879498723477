@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.root {
	min-height: 100vh;

	@include mixins.screen('non-mobile') {
		display: grid;
		padding: 20px;
		place-content: center;
	}
}

.body {
	width: 510px;
	max-width: 100%;
	margin: 0 auto;

	@include mixins.screen('desktop') {
		padding: 40px;
		border: 1px solid theme.$ra-black-soft;
		border-radius: 12px;
	}

	@include mixins.screen('mobile') {
		padding: 14px 16px;
	}
}

.logo {
	width: 168px;
	margin-bottom: 54px;

	@include mixins.screen('mobile') {
		width: 46px;
		margin-bottom: 16px;
		margin-left: -6px;
	}
}

.consent-title {
	& + & {
		margin-bottom: 40px;
	}
}

.consent-content {
	& + div {
		margin-bottom: 8px;
	}
}

.consent-scope {
	padding: 24px 0 0;
	margin: 0 0 40px 0;

	li {
		position: relative;
		padding-right: 40px;
		list-style-type: none;
	}

	li:not(:last-child) {
		margin-bottom: 24px;
	}

	i {
		position: absolute;
		top: 5px;
		right: 0;
		display: flex;
		width: 13px;
		height: 13px;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: theme.$ra-black-mid;
		color: white;
		font-style: normal;
		font-size: 8px;
		font-weight: 700;
	}
}

.consent-client {
	margin-bottom: 24px;

	a {
		color: theme.$ra-brand-light;
	}
}

.consent-footnote {
	margin-bottom: 40px;
	color: theme.$ra-black-dark;

	a {
		text-decoration: underline;

		&,
		&:hover,
		&:focus {
			color: inherit;
		}

		&:hover {
			opacity: 0.7;
		}
	}
}

// Login.scss

.hero-title {
	margin-bottom: 24px;
}

.fields {
	margin: 0 0 24px 0;
}

.email-field-input {
	margin: 0 0 24px 0;
}

.password-field {
	position: relative;

	.show-password-btn {
		position: absolute;
		top: 44px;
		right: 16px;
		font-size: 13px;
		color: theme.$ra-black-dark;
	}
}

.buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include mixins.screen('mobile') {
		flex-flow: column wrap;
		align-items: flex-start;
		row-gap: 20px;

		.login & {
			flex-direction: column-reverse;
			row-gap: 40px;
		}

		button {
			width: 100%;
		}
	}

	.link {
		color: theme.$ra-brand;
		opacity: 1;
		transition: opacity 0.2s ease-in;

		&:hover {
			opacity: 0.8;
		}
	}

	div {
		font-weight: normal;
	}
}

.userBtn {
	display: grid;
	width: 100%;
	height: 60px;
	padding: 0 8px;
	align-items: center;
	grid-template-columns: 50px 1fr;
	text-align: left;
	border-radius: 0;
	transition: 200ms ease;

	&:not(:last-of-type) {
		border-bottom: 1px solid rgba(black, 0.05);
	}

	&:hover {
		color: theme.$ra-brand;
	}

	span {
		display: grid;
		width: 36px;
		height: 36px;
		padding-top: 2px;
		place-content: center;
		font-size: 1.2rem;
		font-weight: 600;
		border-radius: 50%;
		color: white;
		background-color: theme.$ra-brand;

		&:empty {
			background-color: rgba(black, 0.15);
		}
	}
}
