@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.user {
	position: relative;
	margin-bottom: 24px;

	[show-global-notification] & {
		margin-bottom: 90px;
	}

	&.top-left {
		.user-menu {
			bottom: 100%;
			left: 0;
			transform: translate(0, -16px);
		}
	}

	&.top-right {
		.user-menu {
			right: 0;
			bottom: 100%;
			transform: translate(0, -16px);
		}
	}

	&.bottom-left {
		.user-menu {
			top: 100%;
			left: 0;
			transform: translate(0, 16px);
		}
	}

	&.bottom-right {
		.user-menu {
			top: 100%;
			right: 0;
			transform: translate(0, 16px);
		}
	}

	.section {
		padding: 12px 16px;
		color: theme.$ra-black;
		border-bottom: 1px solid theme.$ra-black-light;

		&:last-child {
			border-bottom: none;
		}

		.title {
			padding-bottom: 12px;
			text-transform: uppercase;
			color: theme.$ra-black-dark;
		}

		.item {
			padding-bottom: 10px;

			&:last-child {
				padding-bottom: 0;
			}
		}

		button,
		a {
			color: inherit;
			opacity: 1;
			transition: opacity 0.25s ease;

			&:hover {
				opacity: 0.7;
			}
		}
	}

	&-menu {
		width: 224px;
	}

	&-name {
		color: theme.$ra-black;
	}

	&-organization {
		display: flex;
		width: 100%;
		margin: 0 0 6px 0;
		align-items: center;
		color: theme.$ra-black;
		text-align: left;

		&.pending {
			.user-organization-name {
				opacity: 0.4;
			}
		}

		&:last-child {
			margin: 0;
		}

		.org-avatar {
			display: flex;
			width: 24px;
			height: 24px;
			margin: 0 5px 0 0;
			justify-content: center;
			align-items: center;
			color: theme.$ra-white;
			background-color: #ff6e1d;
			border-radius: 50%;
		}

		&-name {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&-avatar {
		cursor: pointer;
	}

	.logout-btn {
		display: flex;
	}
}

.user-menu,
.section:nth-child(2) {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.section:nth-child(2) {
	padding-bottom: 0;

	> :last-child {
		padding-bottom: 12px;
	}
}

.alert-icon {
	margin-left: 8px;

	path {
		fill: theme.$ra-danger-color;
	}
}
