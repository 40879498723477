@use '/src/routes/admin/styles/theme';
@use '../vars';

.pf-media-root {
	position: relative;

	// field label (see PropField/index.tsx)
	& > label {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: auto;
	}
}

.pf-media {
	position: relative;
	padding: 8px 0 0;
	margin: 0 -#{vars.$pf-padding-x};

	& > input[type='file'] {
		display: none;
	}

	&-img-shading,
	&-img-tint {
		position: absolute;
		top: -8px;
		right: 0;
		bottom: -8px;
		left: 0;
		z-index: 0;
	}

	&-img-shading {
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), transparent);

		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			display: block;
			height: 48px;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.4), transparent);
		}
	}

	&-img-tint {
		display: none;
	}

	&-img-preview,
	&-video-preview,
	:global(.lf-player-container) {
		position: absolute;
		top: -8px;
		right: 0;
		bottom: -8px;
		left: 0;
		z-index: 0;
		display: none;
		background-color: theme.$ra-gray;
	}

	&-img-preview {
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}

	&-video-preview {
		video,
		iframe {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&-img-info {
		position: absolute;
		right: 0;
		bottom: 4px;
		left: 0;
		z-index: 5;
		padding: 0 20px;
		text-align: center;
		color: white;

		.file-size {
			display: flex;
			justify-content: center;
			align-items: center;
			opacity: 0.7;

			&-x {
				margin: 0 2px;
				font-size: 11px;
				transform: translateY(-1px) rotate(45deg);
			}
		}
	}

	&.has-media {
		height: 150px;

		.pf-media-img-preview,
		.pf-media-video-preview,
		.pf-media-img-tint,
		:global(.lf-player-container) {
			display: block;
		}
	}

	&.busy {
		& > *:not(.preloader) {
			visibility: hidden;
		}
	}

	&:not(.busy):hover {
		.change-image-btn,
		.clear-btn {
			opacity: 1;
			visibility: visible;
		}
	}

	&.minimized {
		width: 20px;
		height: 20px;
		padding: 0;
		margin: 0;
		overflow: hidden;
		border: 1px solid white;

		& > *:not(.pf-media-img-preview):not(.pf-video-preview):not(.upload-image-btn):not(.change-image-btn) {
			&:not(.clear-btn):not(.preloader) {
				display: none;
			}
		}

		label {
			cursor: pointer;
		}

		&,
		.pf-media-img-preview,
		.pf-video-preview {
			border-radius: 50%;
		}

		.upload-image-btn,
		.change-image-btn {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			margin: 0;
			opacity: 0 !important;
			transform: none;
		}

		.clear-btn {
			position: absolute;
			top: 50%;
			display: flex;
			width: 16px;
			height: 16px;
			margin: 0;
			align-items: center;
			transform: translate(-10px, -45%);
		}
	}

	:global(.lf-player-container) {
		// extra space for a download button
		padding-bottom: 32px;
	}
}

.content {
	padding: 0 vars.$pf-padding-x;

	.busy & {
		display: none;
	}
}

.change-image-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 10;
	display: flex;
	width: 140px;
	margin-top: -4px;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	transform: translate(-50%, -50%);
	color: rgba(255, 255, 255, 0.7);
	opacity: 0;
	visibility: hidden;
	transition:
		color 0.35s,
		opacity 0.35s,
		visibility 0.35s;

	svg {
		margin-bottom: 5px;
	}
}

.upload-image-btn {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;

	.label {
		padding: 4px 0;
	}

	.sub-label {
		padding: 0 vars.$pf-padding-x;
		color: theme.$ra-white-mid;
		word-spacing: -1px;
		text-align: center;
	}
}

.clear-btn {
	position: absolute;
	top: 50%;
	right: 18px;
	z-index: 2;
	margin-top: -6px;
	transform: translate(0, -50%);
	opacity: 0;
	visibility: hidden;
	transition:
		opacity 0.35s,
		visibility 0.35s;
}

.img-link-input-wrap {
	display: flex;
	height: 24px;
	margin-bottom: 10px;
	align-items: center;

	input {
		display: block;
		width: calc(100% - 24px);
		padding: 0 15px 0 0;
		background: transparent;
		border: none;
		outline: none;
		font-size: 13px;
	}

	button {
		width: 24px;
		height: 24px;
		min-width: 24px;
	}
}

.change-image-btn,
.upload-image-btn,
.clear-btn,
.img-link-input-wrap {
	path {
		fill: theme.$ra-white-dark;
		transition: fill 200ms;
	}

	&:hover path {
		fill: theme.$ra-brand;
	}
}

.copyright-label {
	color: theme.$ra-white-mid;
}

.errors {
	position: relative;
	z-index: 2; // over img-shading and tint
	display: flex;
	margin-bottom: 8px;
	justify-content: center;
	font-size: 13px;
	text-align: center;
}
