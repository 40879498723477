@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.select-integration {
	display: grid;
	height: 100%;
	grid-template-rows: auto 1fr;
}

.search {
	margin-bottom: 24px;
}

.integrations-list {
	height: 100%;
	overflow-y: auto;
}

.no-items-label {
	text-align: center;
	color: theme.$ra-black-mid;
}
