.field {
	position: relative;

	&-radio {
		position: relative;

		&-inner {
			position: relative;
		}

		&-box {
			position: relative;
		}
	}

	input {
		position: relative;
		display: block;
		padding: 10px;
		-webkit-appearance: none;
		outline: none;
		background-color: transparent;
		border: none;
		box-shadow: none;
		border-radius: 50%;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 12px;
			height: 12px;
			visibility: hidden;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			background: rgba(253, 223, 195, 0);
			box-shadow: none;
			text-shadow: none;
			transition: 250ms ease 0ms;
		}

		&:checked {
			&::after {
				visibility: visible;
				background: rgba(253, 223, 195, 1);
				transition: 50ms ease 100ms;
			}
		}
	}
}
