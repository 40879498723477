.default {
	// &-base {
	// }

	&-in {
		visibility: visible;
	}

	&-out {
		visibility: hidden;
	}
}

.fade {
	&-base {
		opacity: 0;
		visibility: hidden;
	}

	&-in {
		opacity: 1;
		visibility: visible;
		transition: opacity 0.5s, visibility 0.5s;
	}

	&-out {
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.5s, visibility 0.5s;
	}
}

.zoom {
	&-base {
		opacity: 0;
		visibility: hidden;
		transform: scale(1.1);
	}

	&-in {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
		transition: opacity 0.5s, visibility 0.5s, transform 0.5s;
	}

	&-out {
		opacity: 0;
		visibility: hidden;
		transform: scale(1.1);
		transition: opacity 0.5s, visibility 0.5s, transform 0.5s;
	}
}
