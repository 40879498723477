@use '/src/routes/admin/styles/theme';

.pf-date {
	position: relative;
	width: 100%;

	:global(.anticon) {
		color: var(--ra-color-white-mid);
		opacity: 0.7;

		&:hover {
			opacity: 1;
		}
	}
}

:global {
	.ant-picker {
		width: 100%;
		padding: 0 !important;

		input {
			&::placeholder {
				color: var(--ra-color-white-mid) !important;
			}
		}
	}

	.ant-picker-dropdown {
		// container background
		.ant-picker-panel-container {
			background-color: var(--ra-color-gray-80);
			border-radius: 12px;

			.ant-picker-panel-layout {
				background-color: var(--ra-color-white-light);
			}
		}

		// prev/next month days
		.ant-picker-cell,
		// year, month arrows
		.ant-picker-header button {
			color: var(--ra-color-white-mid);
		}

		// year, month
		.ant-picker-header-view button,
		// week days
		.ant-picker-content th,
		// this month days
		.ant-picker-cell-in-view,
		// picked time full format
		.ant-picker-header,
		// time
		.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
			color: var(--ra-color-white);
		}

		// picked time cells
		.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
			background-color: var(--ra-color-black-soft);

			&:hover {
				background-color: var(--ra-color-black-mid);
			}
		}

		// day hover
		.ant-picker-cell:not(.ant-picker-cell-selected) .ant-picker-cell-inner:hover,
		// time hover
		.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
			background-color: var(--ra-color-black-mid) !important;
		}

		// year, month arrows hover
		.ant-picker-header > button:hover {
			color: var(--ra-color-white);
		}

		.ant-picker-footer,
		.ant-picker-header,
		.ant-picker-datetime-panel .ant-picker-time-panel,
		.ant-picker-time-panel-column:not(:first-child) {
			border-color: var(--ra-color-white-light);
		}
	}

	.ant-picker-ok .ant-btn-primary:disabled {
		color: var(--ra-color-white-mid);
		border-color: currentColor;
	}
}
