@use '/src/routes/client/styles/theme';

.cms-repeater {
	width: 20vw;
	height: 20vw;
	cursor: pointer;

	[data-editor='true'] {
		cursor: auto;
	}

	[data-editor='true'] &:empty {
		background: theme.$ra-black-soft;
	}
}
